import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { withNetworkManager } from 'NetworkManagerContext';
import { KeycloakManager, NotificationManager, SettingsManager } from 'services';
import { compose, getAnnotationVersion, buildNotification, Actions } from 'utils';
import {
  countActions,
  getMatchingAnnotations,
  getServerErrorMessage,
  setAnnotationDefaults,
  handleCommandQueueUpdate,
  updateUserTeamInformation
} from 'CopContext/utils';
import { _t } from 'utils/i18n';
import withSnackNotifications from './withSnackNotifications';
import { NotificationContext } from 'services/NotificationManager';
import { withStyles } from '@mui/styles';
import { filterRemovedAnnotation } from './utils/utils';

const commandMethodMap = {
  asset: 'sendAsset',
  binaryAsset: 'sendBinaryAsset',
  add: 'addEntity',
  modify: 'updateEntity',
  delete: 'removeEntity'
};

const CopContext = React.createContext({
  annotations: {},
  filteredAnnotations: {},
  team: null,
  teammates: [],
  currentUserTeamInfo: {
    isInTeam: false,
    isLeader: false
  },
  session: '',
  ready: false,
  online: true,
  autoShare: true,
  groupsVisibility: [],
  commandQueue: [],
  willRetry: false,
  activeMission: false,
  /**
   * 'INACTIVE' when no support closure is active
   * 'CONFIGURATION' when a support closure is being configured but hasn't started
   * 'STARTED' when it has started
   * @type {('INACTIVE' | 'CONFIGURATION' | 'STARTED')}
   */
  supportClosureStatus: '',
  addAnnotation: () => {},
  updateAnnotation: () => {},
  removeAnnotation: () => {},
  sendAsset: () => {},
  setActiveMission: () => {},
  setSupportClosureStatus: () => {},
  toggleAutoShare: () => {},
  setGroupsVisibility: () => {},
  deleteCommandQueue: () => {},
  manualShare: () => {}
});

// For devtools
CopContext.displayName = 'CopContext';

const { Provider, Consumer } = CopContext;

function withHooks(Component) {
  return function WrappedComponent(props) {
    const { notifications, setNotifications } = useContext(NotificationContext);

    return <Component {...props} notifications={notifications} setNotifications={setNotifications} />;
  };
}

class CopProvider extends React.Component {
  constructor(props) {
    super(props);

    this.localAnnotationPath = 0;

    /**
     * The date of the last entity retrieved.
     * @type {Date}
     */
    this.lastEntityDate = 0;

    /**
     * The application state that will be passed to all cop Consumers
     */
    this.state = {
      annotations: {},
      filteredAnnotations: {},
      unsyncedAnnotations: {},
      team: null,
      teammates: [],
      currentUserTeamInfo: {
        isInTeam: false,
        isLeader: false
      },
      session: props.session,
      ready: false,
      addAnnotation: this.addAnnotation,
      updateAnnotation: this.updateAnnotation,
      removeAnnotation: this.removeAnnotation,
      sendAsset: this.sendAsset,
      toggleAutoShare: this.toggleAutoShare,
      setGroupsVisibility: this.setGroupsVisibility,
      deleteCommandQueue: this.deleteCommandQueue,
      manualShare: this.manualShare,
      online: true,
      autoShare: true,
      groupsVisibility: KeycloakManager.getUserGroups(),
      pendingCommand: false,
      willRetry: false,
      activeMission: false,
      setActiveMission: this.setActiveMission,
      supportClosureStatus: this.supportClosureStatus,
      setSupportClosureStatus: this.setSupportClosureStatus,
      commandQueue: [],
      processManually: false
    };
  }

  /**
   * Initialize the CopContext Provider on mount. This is done by the application, after
   * logging into a session. This method retrieves the last event known for the session
   * and stores the data in its state. The consumer will have access to the data via context.
   */
  componentDidMount() {
    // Retreive the last event
    this.props.NetworkManager.getEntities({groupsVisibility: this.state.groupsVisibility})
      .then((data) => {
        const [entities, lastEntityDate] = data;
        // Process the entities
        this.handleEntities({ add: entities }, lastEntityDate);
        const refreshInterval = SettingsManager.crimsonServer.refreshInterval || 3000;
        this.mIntervalId = setInterval(() => {
          this.retrieveEntities();
        }, refreshInterval);

        // We want to periodically check team update time and mark teams as
        // disconnected if they haven't updated their position for some time
        this.mTeamRefreshId = setInterval(() => {
          this.updateTeamStatus();
        }, 30000);

        this.lastEntityDate = new Date(lastEntityDate);
        this.entities = entities;
        this.setState({filteredAnnotations: this.state.annotations})
      })
      .catch((e) => {
        console.error('Could not retrieve last event:', e);
      });
  }

  componentWillUnmount() {
    clearInterval(this.mIntervalId);
    clearInterval(this.mTeamRefreshId);
  }

  componentDidUpdate(_, prevState) {
    if ((prevState.commandQueue !== this.state.commandQueue || this.state.commandQueue.length > 0) && !this.state.pendingCommand && (this.state.autoShare || this.state.processManually)) {
      // An item was just added to the queue or removed from it, process it if no command is in progress
      this.processQueue();
    } else if (!this.state.online && !this.state.pendingCommand && (this.state.autoShare)) {
      // Last command has failed, retry after a delay
      this.processQueue(3000);
    }

    if (
      (!this.state.online && !this.state.autoShare) &&
      this.state.commandQueue.length > 0 &&
      countActions(prevState.commandQueue) !== countActions(this.state.commandQueue)
    ) {
      NotificationManager.notify({
        title: _t('Pending actions title'),
        body: _t('Pending actions body', {
          count: countActions(this.state.commandQueue)
        }),
        tag: 99999,
        sticky: true
      });
    }

    if (this.state.commandQueue.length === 0) {
      NotificationManager.clear(99999);
    }

    // Filter and update the annotations by group
    if (prevState.groupsVisibility !== this.state.groupsVisibility || prevState.annotations !== this.state.annotations) {
      const { annotations } = this.state;
      const filteredAnnotations = {};

      // Build filteredAnnotations object
      Object.keys(annotations).map((typeKey) => {
        filteredAnnotations[typeKey] = {};
        return Object.keys(annotations[typeKey]).map((uuidKey) => {
          if ( 
            !annotations[typeKey][uuidKey].annotation.groupsVisibility ||
            annotations[typeKey][uuidKey].annotation.groupsVisibility.length === 0 || 
            annotations[typeKey][uuidKey].annotation.groupsVisibility.some(element => {
              return this.state.groupsVisibility.includes(element)
            })
          ) {
            filteredAnnotations[typeKey][uuidKey] = annotations[typeKey][uuidKey]
          }
          return filteredAnnotations
        })
      })

      // Update annotations
      this.setState({
        filteredAnnotations: filteredAnnotations
      })
    }
  }

  /**
   * Update the state with one annotation recevied from the server
   * @param {*} annotation
   */
  updateStateWithOneAnnotation(annotation) {
    const { annotations, unsyncedAnnotations } = this.state;
    const user = annotation.emmitter;
    const priority = annotation.notif_priority;
    const annotationId = annotation.Uuid;
    const newAnnotations = {
      ...annotations,
      [annotation.type]: {
        ...annotations[annotation.type],
        [annotationId]: {
          annotation: {
            ...annotation,
            synced: true
          },
          user,
          priority
        }
      }
    };

    const newUnsyncedAnnotations = { ...unsyncedAnnotations };
    if (unsyncedAnnotations[annotation.type] && unsyncedAnnotations[annotation.type].hasOwnProperty(annotationId)) {
      delete unsyncedAnnotations[annotation.type][annotationId];
    }

    const { currentUserTeamInfo, team, teammates } = updateUserTeamInformation(newAnnotations);

    this.setState({
      annotations: newAnnotations,
      unsyncedAnnotations: newUnsyncedAnnotations,
      team,
      teammates,
      currentUserTeamInfo
    });
  }

  /**
   * Process a command to send to the server
   *
   * @param {Object} command
   * @param {Array<Object>} newCommandQueue
   *
   * @returns {Promise<*>}
   */
  processCommand = async (command, newCommandQueue) => {
    try {
      const { NetworkManager, snackNotify } = this.props;
      const { priority, successHandler, conflictHandler, errorHandler, action, url } = command;
      let { annotation } = command;

      let response;
      if (action === 'add' || action === 'modify') {
        response = await NetworkManager[commandMethodMap[action]](annotation,priority);
      } else if (action === 'delete') {
        let entitypath = annotation.path;

        // If using crimson server v6 we cannot use the annotaton path directly
        if (SettingsManager?.crimsonServer?.version && SettingsManager.crimsonServer.version >= 6) {
          // Get the entity URL on the server
          const apiRoutes = SettingsManager.getServerRoutes();
          entitypath = `${apiRoutes.session}/${this.state.session.name}/${apiRoutes.entity}/${annotation.Uuid}`;
        }
        response = await NetworkManager[commandMethodMap[action]](entitypath);
      } else {
        response = await NetworkManager[commandMethodMap[action]](url);
      }

      if (response.ok) {
        this.setState({
          commandQueue: newCommandQueue,
          processManually: newCommandQueue.length === 0 ? false : this.state.processManually,
          pendingCommand: false,
          willRetry: false,
          online: true
        });

        if (action === 'add' || action === 'modify') {
          const responseJson = await response.json();
          annotation = responseJson[0].entity;
          this.updateStateWithOneAnnotation(annotation);
        }

        if (typeof successHandler === 'function') {
          successHandler(response, annotation);
        }
      } else {
        // The server rejected our request for a specific reason. We don't want to retry this command.
        // Thus we will remove it from the queue and call the provided errorHandler if applicable
        // and a default message based off response status otherwise.
        if (typeof errorHandler === 'function') {
          errorHandler(response, annotation);
        } else if (response.status !== 409 || typeof conflictHandler === 'undefined') {
          snackNotify(
            getServerErrorMessage({
              status: response.status,
              translator: this.props.t
            }),
            {
              priority: 100
            }
          );
        }

        // Update the command queue
        this.setState({
          pendingCommand: false,
          willRetry: false,
          commandQueue: newCommandQueue
        });

        // Manage conflict if an handler has been set
        if (response.status === 409 && typeof conflictHandler === 'function') {
          const pathWithoutVersion = annotation.path.substr(0, annotation.path.lastIndexOf('/'));
          const entity = await NetworkManager.getEntity(pathWithoutVersion);
          this.updateStateWithOneAnnotation(entity);
          conflictHandler(entity);
        }
      }

      return response;
    } catch (err) {
      console.error('Could not publish event, retrying in a few seconds', {
        event: command,
        err
      });

      this.setState({
        online: false,
        pendingCommand: false,
        willRetry: true
      });
    }
  };

  /**
   * Process the queue of commands to send to the server
   *
   * @param {number=} delay
   */
  processQueue = (delay = 0) => {
    const { commandQueue } = this.state;
    // console.log(new Date().toUTCString() + ' process queue. ' + delay + ' ' + commandQueue.length);

    if (commandQueue.length > 0) {
      this.setState(
        {
          pendingCommand: true
        },
        () => {
          if (delay > 0) {
            setTimeout(() => this.processCommand(commandQueue[0], commandQueue.slice(1)), delay);
          } else {
            this.processCommand(commandQueue[0], commandQueue.slice(1));
          }
        }
      );
    }
  };

  /**
   * Retrieve the new entities and handle them, if needed.
   */
  retrieveEntities = () => {
    if (this.retrievingEntities) {
      return;
    }
    this.retrievingEntities = true;
    this.props.NetworkManager.getEntities({ diffDate: new Date(this.lastEntityDate) })
      .then((data) => {
        // Set online only if changed
        this.setState((state) => (!state.online ? { online: true } : null));
        const [entities, lastEntityDate] = data;
        if (entities.add || entities.remove || entities.modify) {
          this.handleEntities(entities, lastEntityDate);
        }
        this.retrievingEntities = false;
      })
      .catch((e) => {
        this.retrievingEntities = false;

        // Set online only if changed
        this.setState((state) => (state.online ? { online: false } : null));

        console.error(e);
      });
  };

  /**
   * Update an annotation on the server.
   * Add a command in the queue
   *
   * @param {Object} pAnnotation
   * @param {Object=} pOptions
   */
  updateAnnotation = (pAnnotation, pOptions = {}) => {
    const priority = pOptions.priority || 50;

    const annotation = setAnnotationDefaults(pAnnotation, false);

    this.setState(
      handleCommandQueueUpdate({
        action: 'modify',
        annotation,
        ...pOptions,
        priority
      })
    );
  };

  /**
   * Add a new annotation on the server.
   * Add a command in the queue
   *
   * @param {Object} pAnnotation
   * @param {Object=} pOptions
   */
  addAnnotation = (pAnnotation, pOptions = {}) => {
    const priority = pOptions.priority || 50;

    // If the Uuid already exists, update instead
    if (this.state.annotations[pAnnotation.type] && this.state.annotations[pAnnotation.type][pAnnotation.Uuid]) {
      this.updateAnnotation(pAnnotation, pOptions);
      return;
    }

    const annotation = setAnnotationDefaults(
      {
        ...pAnnotation,
        path: `'#${this.localAnnotationPath++}`
      },
      true
    );

    // Add the groups
    if (!pAnnotation.groupsVisibility) {
      annotation.groupsVisibility = KeycloakManager.getUserGroups();
    }

    this.setState(
      handleCommandQueueUpdate({
        action: 'add',
        annotation,
        ...pOptions,
        priority
      })
    );

    return annotation;
  };

  /**
   * Remove entity from server and annotation from state
   *
   * @param {object} annotation
   */
  removeAnnotation = (annotation) => {
    if (annotation) {

      this.setState(
        handleCommandQueueUpdate({
          action: 'delete',
          annotation
        })
      );

      const newAnnotations = filterRemovedAnnotation(annotation, this.state.annotations)
      const newUnsyncedAnnotations = filterRemovedAnnotation(annotation, this.state.unsyncedAnnotations)

      this.setState({ annotations: Object.fromEntries(newAnnotations) });
      this.setState({ unsyncedAnnotations: Object.fromEntries(newUnsyncedAnnotations) });
    } else {
      console.error('Error: annotation is null or undefined.');
    }
  };

  /**
   * Send an asset on the server.
   * Add a command in the queue
   *
   * @param {string | Blob} assetUrl
   * @param {Function} successHandler
   * @param {Function} errorHandler
   * @param {"base64" | "blob"=}
   */
  sendAsset = (assetUrl, successHandler, errorHandler, type = 'base64') => {
    // Add the command to the queue
    this.setState((state) => ({
      commandQueue: [
        ...state.commandQueue,
        {
          url: assetUrl,
          successHandler,
          errorHandler,
          action: type === 'blob' ? 'binaryAsset' : 'asset'
        }
      ],
      willRetry: false
    }));
  };

  /**
   * Changes active mission state when an user begins or ends a mission
   *
   * @param {boolean} isActive
   */
  setActiveMission = (isActive) => {
    this.setState({ activeMission: isActive });
  };

  /**
   * Toggles auto-share state 
   *
   * @param {boolean} autoShare
   */
  toggleAutoShare = (autoShare) => {
    this.setState({ autoShare: !autoShare });
  };

  /**
   * Defines the groups visibility
   *
   * @param {array} groups
   */
  setGroupsVisibility = (groups) => {
    this.setState({ groupsVisibility: groups });
  };

  /**
   * Remove the last command in the queue
   */
  deleteCommandQueue = () => {
    const localAnnotations = this.state.commandQueue.map((command) => {
      if (command.action === 'add') {
        return command.annotation
      } else {
        return null
      }
    })

    let newAnnotations = this.state.annotations
    let newUnsyncedAnnotations = this.state.unsyncedAnnotations

    localAnnotations.map((annotation) => {
      newAnnotations = Object.fromEntries(filterRemovedAnnotation(annotation, newAnnotations))
      newUnsyncedAnnotations = Object.fromEntries(filterRemovedAnnotation(annotation, newUnsyncedAnnotations))
      return (newAnnotations, newUnsyncedAnnotations)
    })

    this.setState({ 
      annotations: newAnnotations,
      unsyncedAnnotations: newUnsyncedAnnotations,
      commandQueue:  []
     });
  };

  /**
   * Share the changes manually
   */
  manualShare = () => {
    this.setState({
      processManually: true,
    });
    this.processQueue();
  };

  /**
   * Changes support / closure status state
   *
   * @param {string} supportClosureStatus
   */
  setSupportClosureStatus = (supportClosureStatus) => {
    this.setState({ supportClosureStatus });
  };

  /**
   * Update the team status "tooOld" attribute
   */
  updateTeamStatus = () => {
    this.setState((state) => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60 * 1000;
      return {
        annotations: {
          ...state.annotations,
          ...(state.annotations.hasOwnProperty('Team')
            ? {
                Team: Object.values(state.annotations.Team).reduce(
                  (teams, team) => ({
                    ...teams,
                    [team.annotation.Uuid]: {
                      ...team,
                      annotation: {
                        ...team.annotation,
                        TooOld: (now - (new Date(team.annotation.UpdateDate).getTime() - offset)) / 1000 > 60
                      }
                    }
                  }),
                  {}
                )
              }
            : {})
        }
      };
    });
  };

  /**
   * Handle an entities. This method dispatches the cop changes to the cop elements which
   * are interested in those changes.
   *
   * @param {Object} Entities The list of entities to handle.
   *
   * @returns {Promise<void>}
   */
  handleEntities = ({ add, remove, modify }, lastEntityDate) => {
    try {
      const { notifications, setNotifications } = this.props;
      const { annotations, unsyncedAnnotations, commandQueue } = this.state;

      // Copy annotations (spread operator only copy the properties, no deep copy)
      const newAnnotations = { ...annotations };
      const newUnsyncedAnnotations = { ...unsyncedAnnotations };
      let newCommandQueue = commandQueue;

      const newNotifications = notifications.slice();

      // First remove the annotions
      remove?.forEach((annotation) => {
        // Iterate on annoations by type to find the removed annotations
        Object.keys(annotations).forEach((type) => {
          const annotionsByType = annotations[type];
          if (annotionsByType.hasOwnProperty(annotation.Uuid)) {
            // Optim : copy the annotations by type only if needed, if no modiciations on some type the array is not modified
            if (annotionsByType === newAnnotations[type]) {
              newAnnotations[type] = { ...newAnnotations[type] };
            }

            if (this.state.ready) {
              const newNotification = buildNotification(
                newAnnotations[type][annotation.Uuid].annotation,
                Actions.Remove
              );
              newNotifications.push(newNotification);
            }

            // Delete local changes
            newCommandQueue = newCommandQueue.filter((command) => {
              if (command.annotation.Uuid === annotation.Uuid) {
                return false
              } else {
                return true
              }
            })
            

            delete newAnnotations[type][annotation.Uuid];
          }
        });
      });

      // Then add new annotations
      add?.forEach((annotation) => {
        if (this.state.ready) {
          const newNotification = buildNotification(annotation, Actions.Add);
          newNotifications.push(newNotification);
        }

        if (annotation.type !== 'Reminder') {
          const annotionsByType = newAnnotations[annotation.type];

          // Optim : copy the annotations by type only if needed, if no modiciations on some type the array is not modified
          if (!annotionsByType) {
            newAnnotations[annotation.type] = {};
          } else if (annotionsByType === newAnnotations[annotation.type]) {
            newAnnotations[annotation.type] = { ...newAnnotations[annotation.type] };
          }

          annotation.synced = true;

          if (
            newUnsyncedAnnotations[annotation.type] &&
            newUnsyncedAnnotations[annotation.type].hasOwnProperty(annotation.Uuid)
          ) {
            delete newUnsyncedAnnotations[annotation.type][annotation.Uuid];
          }

          newAnnotations[annotation.type][annotation.Uuid] = {
            annotation,
            user: annotation.emmitter,
            priority: annotation.priority
          };
        }
      });

      // And finally modify annotations
      modify?.forEach((annotation) => {
        if (this.state.ready) {
          const newNotification = buildNotification(annotation, Actions.Modify);
          newNotifications.push(newNotification);
        }

        if (
          annotation.type !== 'Reminder' &&
          newAnnotations[annotation.type] &&
          newAnnotations[annotation.type].hasOwnProperty(annotation.Uuid)
        ) {
          const currentAnnotation = newAnnotations[annotation.type][annotation.Uuid].annotation;
          // Do not update the annotation has a newer version, can happen if an update is done and retrieved before the refresh
          if (getAnnotationVersion(currentAnnotation) < getAnnotationVersion(annotation)) {
            // Optim : copy the annotations by type only if needed, if no modiciations on some type the array is not modified
            if (annotations[annotation.type] === newAnnotations[annotation.type]) {
              newAnnotations[annotation.type] = { ...newAnnotations[annotation.type] };
            }

            annotation.synced = true;

            if (
              newUnsyncedAnnotations[annotation.type] &&
              newUnsyncedAnnotations[annotation.type].hasOwnProperty(annotation.Uuid)
            ) {
              delete newUnsyncedAnnotations[annotation.type][annotation.Uuid];
            }

            // Overwrite local changes
            newCommandQueue = newCommandQueue.filter((command) => {
              if (command.annotation.Uuid === annotation.Uuid && command.action === 'modify') {
                return false
              } else {
                return true
              }
            })
            

            newAnnotations[annotation.type][annotation.Uuid] = {
              annotation,
              user: annotation.emmitter,
              priority: annotation.priority
            };
          }
        } else {
          console.error('Problem when retreiving entities : no type found for the updated entities');
        }
      });

      setNotifications(newNotifications.slice(-50));

      const { currentUserTeamInfo, team, teammates } = updateUserTeamInformation(newAnnotations);

      // When all promises have resolved, we update the Cop
      // state with the new data and flag the app as 'ready'
      this.setState({
        annotations: newAnnotations,
        unsyncedAnnotations: newUnsyncedAnnotations,
        commandQueue: newCommandQueue,
        ready: true,
        currentUserTeamInfo,
        team,
        teammates
      });

      // Store the last entity date for the next diff call
      this.lastEntityDate = new Date(lastEntityDate);
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const withAnnotations =
  ({ types, shouldBeVisible, mapAnnotationsToProps }) =>
  (BaseComponent) => {
    return class extends React.Component {
      render() {
        return (
          <Consumer>
            {({
              filteredAnnotations,
              unsyncedAnnotations,
              team,
              teammates,
              session,
              ready,
              addAnnotation,
              updateAnnotation,
              removeAnnotation,
              sendAsset,
              toggleAutoShare,
              setGroupsVisibility,
              deleteCommandQueue,
              manualShare,
              online,
              autoShare,
              groupsVisibility,
              commandQueue,
              willRetry,
              currentUserTeamInfo
            }) => {
              const matchingAnnotations = getMatchingAnnotations(
                types,
                shouldBeVisible,
                filteredAnnotations,
                unsyncedAnnotations,
                team,
                teammates,
                this.props
              );

              const mappedAnnotations =
                typeof mapAnnotationsToProps === 'function'
                  ? mapAnnotationsToProps(matchingAnnotations)
                  : { filteredAnnotations: matchingAnnotations };

              return (
                <BaseComponent
                  {...mappedAnnotations}
                  team={team}
                  teammates={teammates}
                  session={session}
                  ready={ready}
                  addAnnotation={addAnnotation}
                  toggleAutoShare={toggleAutoShare}
                  setGroupsVisibility={setGroupsVisibility}
                  deleteCommandQueue={deleteCommandQueue}
                  manualShare={manualShare}
                  updateAnnotation={updateAnnotation}
                  removeAnnotation={removeAnnotation}
                  sendAsset={sendAsset}
                  online={online}
                  autoShare={autoShare}
                  groupsVisibility={groupsVisibility}
                  commandQueue={commandQueue}
                  willRetry={willRetry}
                  currentUserTeamInfo={currentUserTeamInfo}
                  {...this.props}
                />
              );
            }}
          </Consumer>
        );
      }
    };
  };

const withCop = (BaseComponent) => {
  return class extends React.Component {


    render() {
      return (
        <Consumer>
          {({
            activeMission,
            setActiveMission,
            team,
            teammates,
            session,
            ready,
            addAnnotation,
            updateAnnotation,
            removeAnnotation,
            sendAsset,
            toggleAutoShare,
            setGroupsVisibility,
            deleteCommandQueue,
            manualShare,
            online,
            autoShare,
            groupsVisibility,
            commandQueue,
            willRetry,
            supportClosureStatus,
            setSupportClosureStatus,
            currentUserTeamInfo
          }) => {
            return (
              <BaseComponent
                team={team}
                teammates={teammates}
                session={session}
                ready={ready}
                addAnnotation={addAnnotation}
                updateAnnotation={updateAnnotation}
                removeAnnotation={removeAnnotation}
                sendAsset={sendAsset}
                toggleAutoShare={toggleAutoShare}
                setGroupsVisibility={setGroupsVisibility}
                deleteCommandQueue={deleteCommandQueue}
                manualShare={manualShare}
                online={online}
                autoShare={autoShare}
                groupsVisibility={groupsVisibility}
                commandQueue={commandQueue}
                willRetry={willRetry}
                activeMission={activeMission}
                setActiveMission={setActiveMission}
                supportClosureStatus={supportClosureStatus}
                setSupportClosureStatus={setSupportClosureStatus}
                currentUserTeamInfo={currentUserTeamInfo}
                {...this.props}
              />
            );
          }}
        </Consumer>
      );
    }
  };
};

const EnhancedCopProvider = compose(
  withNetworkManager,
  withStyles(({}) ,{withTheme: true}),
  withSnackNotifications,
  withTranslation('common'),
  withHooks
)(CopProvider);

export { EnhancedCopProvider as CopProvider, Consumer as CopConsumer, withAnnotations, withCop, CopContext };
