import { _t } from 'utils/i18n';

/**
 * Promise wrapper for navigator.geolocation.getCurrentPosition
 */
export const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {timeout: 2000});
  });
};

/**
 * Shows a prompt detailing the geolocation error to the user
 *
 * @param {number} geoLocationErrorCode
 */
export const showUserGeoLocationError = (geoLocationErrorCode) => {
  let showUserErrorFunc = window.alert;

  // If on mobile, use the dialog plugin
  if (window.cordova) {
    showUserErrorFunc = (msg) => navigator.notification.alert(msg, null, _t('GeoLocationErrorTitle'));
  }

  switch (geoLocationErrorCode) {
    case 1:
      // Permission denied
      showUserErrorFunc(_t('GeoLocationErrorPermission'));
      break;
    case 2:
      // Position unvabailable : the device is not connected to a network or can't get a satellite fix.
      showUserErrorFunc(_t('GeoLocationErrorUnavailable'));
      break;
    case 3:
      // Returned when the device is unable to retrieve a position within the time specified by the timeout included in geolocationOptions
      showUserErrorFunc(_t('GeoLocationErrorTimeout'));

      break;
    default:
      console.error('unknown error code');
  }
};
