import { cleanupFileUrl, getAsColor, getAsColorWithAlpha, setColorOpacity, strArrToNumberArr } from 'utils';
import rulesSettings from '../Resources/rules-settings.json';

class StyleFactoryHelper {
  /**
   * Deserialize an icon style.
   * @param  {Object} pStyle The style data.
   * @return {Object}        The parsed style.
   */
  static buildIconStyle(pData) {
    const lIcon = {};
    if (pData.IconURL) lIcon.iconURL = cleanupFileUrl(pData.IconURL);
    if (pData.Color) lIcon.color = getAsColor(pData.Color);
    if (pData.Offset) lIcon.offset = strArrToNumberArr(pData.Offset);
    if (pData.Scale) lIcon.scale = strArrToNumberArr(pData.Scale);
    if (pData.Size) lIcon.size = strArrToNumberArr(pData.Size);
    if (pData.UseHeading) lIcon.useHeading = pData.UseHeading;
    if (pData.DepthOffset) lIcon.depthOffset = parseFloat(pData.DepthOffset);
    return lIcon;
  }
  /**
   * Deserialize a primitive style.
   * @param  {Object} pStyle The style data.
   * @return {Object}        The parsed style.
   */
  static buildPrimitiveStyle(pData) {
    const lPrimitive = {};
    if (pData.Color) lPrimitive.color = getAsColor(pData.Color);
    if (pData.Opacity) lPrimitive.opacity = parseFloat(pData.Opacity);
    if (pData.OutlineWidth) lPrimitive.outlineWidth = parseFloat(pData.OutlineWidth);
    if (pData.OutlineSteps) lPrimitive.outlineSteps = parseFloat(pData.OutlineSteps);
    if (pData.OutlineColor) lPrimitive.outlineColor = getAsColor(pData.OutlineColor);
    return lPrimitive;
  }

  /**
   * Deserialize a complex curve style.
   * @param  {Object} pStyle The style data.
   * @return {Object}        The parsed style.
   */
  static buildComplexStyle(pData) {
    const lComplex = {};
    if (pData.Width) lComplex.width = parseFloat(pData.Width);
    if (pData.ModulateColor) lComplex.modulateColor = getAsColor(pData.ModulateColor);
    if (pData.Smoothed) lComplex.smoothed = pData.Smoothed;
    if (Array.isArray(pData.Patterns)) {
      lComplex.pattern = [];
      // Workaround for complex patterns, load them in base64 
			// until VrGIS can load them in web
      for (const pattern of pData.Patterns)
      {
        lComplex.push_bash({
          URL: cleanupFileUrl(pattern.URL),
          exclusive: pattern.Exclusive,
          repeat: pattern.Repeat,
          position: parseFloat(pattern.Position),
          interval: parseFloat(pattern.Interval)
        })
      }
    }
    return lComplex;
  }

  /**
   * Deserialize a line style.
   * @param  {Object} pStyle The style data.
   * @return {Object}        The parsed style.
   */
  static buildLineStyle(pData) {
    const lLine = {};
    if (pData.Width) lLine.width = parseFloat(pData.Width);
    if (pData.Color) lLine.color = getAsColor(pData.Color);
    if (pData.Color && pData.Color.length === 4) lLine.opacity = parseFloat(pData.Color[3]);
    if (pData.GeoWidthAsPixel) {
        lLine.widthUnit = 'Geo';
        if (pData.OutlineWidth) lLine.outlineWidth = parseFloat(pData.OutlineWidth);
        if (pData.OutlineColor) lLine.outlineColor = getAsColor(pData.OutlineColor);
        // FIXME : VGEO : VGeoWeb isnt able to render outlineWidth 
        // (and WebGL limitations make the evolution complicated)
        // We are going to use a base64 texture to mimic this behaviour
        if (pData.OutlineWidth) {
          lLine.textureURL = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAABZCAYAAADsHw7nAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAABNSURBVEhLY/wPBAwEABOUxgtGFQ1zRaCkkgVl4wQgRaFQNk4wZINgVNGoolFFQDCqaFTRqCIgGFVERUWgxsMrKBsnGG2xjioiShEDAwCdwhNGTvEwEQAAAABJRU5ErkJggg==";
          // Also make the feature a little less transparent
          lLine.color = getAsColor([pData.Color[0],pData.Color[1],pData.Color[2],(pData.Color[3] || 0.25) * pData.OutlineWidth]);
        }
        if (pData.HeadTip && pData.type && pData.HeadTip.Points) lLine.headTip = {
          type: pData.HeadTip.type,
          points: pData.HeadTip.Points.map((pVal) => strArrToNumberArr(pVal)),
          color: getAsColor(pData.HeadTip.color || pData.OutlineColor || pData.Color)
        };
    }
    if (pData.TextureURL) lLine.textureURL = cleanupFileUrl(pData.TextureURL);
    if (pData.TextureMetrics) lLine.textureMetrics = strArrToNumberArr(pData.TextureMetrics);
    if (pData.Smoothed) lLine.smoothed = pData.Smoothed;
    return lLine;
  }

  /**
   * Deserialize a surface style.
   * @param  {Object} pStyle The style data.
   * @return {Object}        The parsed style.
   */
  static buildSurfaceStyle(pData) {
    const lSurface = {};
    if (pData.Color) lSurface.color = getAsColorWithAlpha(pData.Color);
    if (pData.Extrusion) lSurface.extrusion = parseFloat(pData.Extrusion);
    if (pData.SideColor) lSurface.sideColor = parseFloat(pData.SideColor);
    if (pData.TextureURL) lSurface.textureURL = cleanupFileUrl(pData.TextureURL);
    // Convert to number and replace 0 by 1 component that will disable the metrics
    if (pData.TextureMetrics && pData.TextureMetrics.length === 2) {
      lSurface.textureMetrics = [
        parseFloat(pData.TextureMetrics[0] || 1), 
        parseFloat(pData.TextureMetrics[1] || 1)
      ];
    }
    if (pData.OutlineWidth) lSurface.outlineWidth = parseFloat(pData.OutlineWidth);
    if (pData.OutlineColor) lSurface.outlineColor = getAsColor(pData.OutlineColor);
    return lSurface;
  }

  /**
   * Deserialize a text style.
   * @param  {Object} pStyle The style data.
   * @return {Object}        The parsed style.
   */
  static buildTextStyle(pData) {
    const lText = {};
    lText.textAttribute = 'text';
    //if (pData.TextAttribute) lText.textAttribute = pData.TextAttribute;
    if (pData.Color) lText.color = getAsColor(pData.Color);
    if (pData.Offset) lText.offset = strArrToNumberArr(pData.Offset);
    if (pData.BackgroundEnabled) lText.backgroundEnabled = pData.BackgroundEnabled;
    if (pData.BackgroundColor) lText.backgroundColor = getAsColor(pData.BackgroundColor);
    if (pData.BorderEnabled) lText.borderEnabled = pData.BorderEnabled;
    if (pData.BorderColor) lText.borderColor = getAsColor(pData.BorderColor);
    if (pData.FramePadding) lText.framePadding = parseFloat(pData.FramePadding);
    if (pData.Alignment) lText.alignment = pData.Alignment;
    if (pData.OutlineSize) lText.outlineSize = parseFloat(pData.OutlineSize);
    if (pData.OutlineColor) lText.outlineColor = getAsColor(pData.OutlineColor);
    if (pData.Size) lText.size = parseFloat(pData.Size);
    if (pData.DepthOffset) lText.depthOffset = parseFloat(pData.DepthOffset);
    return lText;
  }

  /**
   * Parse an XML to a DOM object and calls a function on it.
   * @param {String} 		pFile		The file to fetch and parse.
   * @param {Function} 	pHandler 	The handler function. Takes the DOM as parameter.
   * @param {Boolean}		pAsync 		Whether the fetching should be done asynchronously.
   * 									Defaults to true.
   */
  static getXml(pFile, pHandler) {
    const lDomParser = new DOMParser();

    // Use XMLHttpRequest, because fetch do not support loading from file:// (cordova)
    const pRequest = new XMLHttpRequest();

    pRequest.open('GET', pFile);
    pRequest.send(null);
    pRequest.onreadystatechange = () => {
      if (pRequest.readyState === 4) {
        if (pRequest.status === 200) {
          pHandler(lDomParser.parseFromString(pRequest.responseText, 'text/xml'));
        } else {
          console.error(`Cannot load ${pFile}`);
        }
      }
    };
  }

  /**
   * Parse an XML DOM representing a style. Restricts to 'Maps' view name.
   * @param  {Object} pStyleNode The style's dom root node.
   * @return {Object} The created style
   */
  static parseXmlStyle(pStyleNode) {
    const lResult = {};
    if (pStyleNode.nodeName === 'VrCompositeStyle') {
      for (const lNode of pStyleNode.children) {
        if (lNode.nodeName === 'ViewName' && lNode.innerHTML !== 'Maps') {
          return {};
        }

        if (lNode.nodeName === 'Styles') {
          for (const lStyleNode of lNode.children) {
            const lNodeName = lStyleNode.nodeName;
            if (!lResult[lNodeName]) {
              lResult[lNodeName] = [];
            }

            lResult[lNodeName].push(this.parseXmlStyle(lStyleNode)[lNodeName]);
          }
        }
      }
    } else {
      const lStyle = {};
      for (const lStyleProperty of pStyleNode.children) {
        let lNodeData = lStyleProperty.textContent.split(' ');
        if (lNodeData.length === 1) {
          lNodeData = lNodeData[0];
        }
        lStyle[lStyleProperty.nodeName] = lNodeData;
      }
      lResult[pStyleNode.nodeName] = lStyle;
    }

    return lResult;
  }
}

export const StyleHelper = StyleFactoryHelper;

class Node {
  // Search something like "${attributeName}"
  static substitutionRegExp = /\$\{([^}]+)\}/;
  static substitutionEscapeRegExp = /(?<=[^\\]|^)\\+/;
  static escapeRemovalRegExp = /\\\\/g;
  children = [];

  constructor(pParent) {
    if (pParent) {
      pParent.children.push(this);
    }
  }

  apply(pAnnotation, pStyle, pProperties) {
    for (const child of this.children) {
      child.apply(pAnnotation, pStyle, pProperties);
    }
  }

  getAttributeValue(pObject, pName) {
    const lAttrs = pName.split('.');
    if (lAttrs.length <= 1) {
      return pObject[pName];
    }
    const lAttr = lAttrs[0];
    lAttrs.splice(0, 1);
    if (lAttr in pObject === false) {
      return pObject[pName];
    }
    return this.getAttributeValue(pObject[lAttr], lAttrs.join('.'));
  }

  /**
   * Parse a string and substitute attributes notation
   * @param  {Object} pObject The object containing the attribute.
   * @param  {String} pAnnotedString The annoted string to parse
   * @return {String} pAnnotedString with substitution done
   */
  substituteAttributes(pObject, pAnnotedString) {
    let lResult = '';
    let lMatch = null;
    let lSearch = pAnnotedString;
    while ((lMatch = Node.substitutionRegExp.exec(lSearch))) {
      const lPrefix = lSearch.substr(0, lMatch.index);
      const lTestBackslashes = Node.substitutionEscapeRegExp.exec(lSearch.substr(0, lMatch.index));
      if (lTestBackslashes && lTestBackslashes[0].length % 2 === 1) {
        lResult +=
          lPrefix.substr(0, lPrefix.length - 1).replace(Node.escapeRemovalRegExp, '\\') + lSearch[lMatch.index];
        lSearch = lSearch.substr(lMatch.index + 1);
      } else {
        lResult += lPrefix.replace(Node.escapeRemovalRegExp, '\\') + this.getAttributeValue(pObject, lMatch[1]);
        lSearch = lSearch.substr(lMatch.index + lMatch[0].length);
      }
    }
    return lResult + lSearch.replace(Node.escapeRemovalRegExp, '\\');
  }
}

/**
 * Type mapping for style in rules.xml
 */
const cTypeMap = rulesSettings.typeMap;

class IfTypeNode extends Node {
  constructor(pElement, pParent, registerSelfStyledObject) {
    super(pParent);
    const lTypes = pElement.getAttribute('name').split(',');

    this.types = [];
    for (const lT of lTypes) {
      const lType = cTypeMap[lT] || lT;
      this.types.push(lType);
      registerSelfStyledObject(lType);
    }
  }

  apply(pAnnotation, pStyle, pProperties) {
    if (this.types.indexOf(pAnnotation.type) !== -1) {
      super.apply(pAnnotation, pStyle, pProperties);
    }
  }
}

class SetTemplateNode extends Node {
  constructor(pElement, pParent) {
    super(pParent);
    this.url = cleanupFileUrl(pElement.getAttribute('url'));
    this.style = null;
    this.buildStyle();
  }

  buildStyle() {
    StyleHelper.getXml(this.url, (pDocument) => {
      if (pDocument.doctype && pDocument.doctype.name === 'VrGIS') {
        const lStyles = pDocument.getElementsByTagName('VrGIS')[0].children;

        for (const lStyle of lStyles) {
          const lParsedStyle = StyleHelper.parseXmlStyle(lStyle);
          if (Object.keys(lParsedStyle).length > 0) {
            this.style = lParsedStyle;
          }
        }
      }
    });
  }

  apply(pAnnotation, pStyle, pProperties) {
    if (this.style) {
      for (const [k, v] of Object.entries(this.style)) {
        // Do not overwrite the style with the template
        if (!pStyle[k]) {
          pStyle[k] = v;
        }
      }
    }

    super.apply(pAnnotation, pStyle, pProperties);
  }
}

/**
 * Check if value is defined or null
 * @returns {boolean} true if defined and not null
 */
function defined(value) {
  return value !== undefined && value !== null;
}

class IfAttrNode extends Node {
  constructor(pElement, pParent) {
    super(pParent);
    this.name = pElement.getAttribute('name');
    this.value = pElement.getAttribute('value');
    this.size = pElement.getAttribute('size');
    this.operator = pElement.getAttribute('operator');
    this.contains = pElement.getAttribute('contains');
  }

  apply(pAnnotation, pStyle, pProperties) {
    let lValue = String();
    if (this.name in pAnnotation === false && this.name.includes('.')) {
      lValue = this.getAttributeValue(pAnnotation, this.name);
    } else {
      lValue = pAnnotation[this.name];
    }

    let containsMatches = false;
    if (this.contains) {
      containsMatches = Array.isArray(lValue) && lValue.indexOf(this.contains) >= 0;
    }

    if (String(this.value) === '' && defined(lValue) === false) {
      lValue = '';
    }

    lValue = String(lValue);

    let valueMatches;
    if (this.operator === '!=') {
      valueMatches = lValue !== this.substituteAttributes(pAnnotation, String(this.value));
    } else {
      valueMatches = lValue === this.substituteAttributes(pAnnotation, String(this.value));
    }

    // Handle annotation state for devices
    let statusMatches = false;

    if (
      this.name === 'State' &&
      pAnnotation?.State !== undefined &&
      pAnnotation?.type &&
      // Currently only checks for the listed annotation types
      [cTypeMap.IdCamera, cTypeMap.IdDevice, cTypeMap.IdCoverageDevice, cTypeMap.IdDrone].includes(pAnnotation.type)
    ) {
      switch (this.value.toLowerCase()) {
        case 'ok':
          if (pAnnotation.State === 0) {
            statusMatches = true;
          }
          break;

        case 'warning':
          if (pAnnotation.State === 1) {
            statusMatches = true;
          }
          break;

        case 'critical':
          if (pAnnotation.State === 2) {
            statusMatches = true;
          }
          break;
        default:
          break;
      }
    }

    const sizeMatches = Array.isArray(lValue) && lValue.length === Number(this.size);

    if (defined(this.name) && defined(lValue) && (valueMatches || sizeMatches || containsMatches || statusMatches)) {
      super.apply(pAnnotation, pStyle, pProperties);
    }
  }
}

class SetStyleAttrNode extends Node {
  constructor(pElement, pParent) {
    super(pParent);
    this.name = pElement.getAttribute('name');
    this.style = pElement.getAttribute('style');
    this.value = pElement.getAttribute('value');
    // To handle color value
    if (this.value) {
      const arr = this.value.split(' ');
      if (arr.length > 1) {
        this.value = arr;
      }
    }
    this.objectAttributeValue = pElement.getAttribute('objectAttributeValue');
  }

  apply(pAnnotation, pStyle, pProperties) {
    let lValue = null;
    if (this.value) {
      if (typeof this.value === 'string') {
        lValue = this.substituteAttributes(pAnnotation, this.value);
      } else {
        lValue = this.value;
      }
    } else if (this.objectAttributeValue) {
      lValue = pAnnotation[this.objectAttributeValue];
    }

    for (const lStyles of Object.entries(pStyle)) {
      if (Array.isArray(lStyles[1])) {
        for (const lStyle of lStyles[1]) {
          if (!this.style || lStyle.Name === this.style) {
            lStyle[this.name] = lValue;
          }
        }
      } else if (!this.style || lStyles[1].Name === this.style) {
        lStyles[1][this.name] = lValue;
      }
    }

    super.apply(pAnnotation, pStyle, pProperties);
  }
}

class SetFeatureAttrNode extends Node {
  constructor(pElement, pParent) {
    super(pParent);
    this.name = pElement.getAttribute('name');
    this.style = pElement.getAttribute('style');
    this.value = pElement.getAttribute('value');
    this.objectAttributeValue = pElement.getAttribute('objectAttributeValue');
    this.append = pElement.getAttribute('append');
  }

  apply(pAnnotation, pStyle, pProperties) {
    let lValue = this.append ? '' : null;
    if (this.value) {
      lValue = this.substituteAttributes(pAnnotation, this.value);
    } else if (this.objectAttributeValue && pAnnotation.hasOwnProperty(this.objectAttributeValue)) {
      lValue = pAnnotation[this.objectAttributeValue];
    }

    if (pProperties[this.name] && this.append) {
      pProperties[this.name] = pProperties[this.name] + lValue;
    } else {
      pProperties[this.name] = lValue;
    }

    super.apply(pAnnotation, pStyle, pProperties);
  }
}

function getNode(pElement, pParent, registerSelfStyledObject) {
  if (!pElement) {
    return new Node(null);
  }

  switch (pElement.tagName) {
    case 'ifType':
      return new IfTypeNode(pElement, pParent, registerSelfStyledObject);
    case 'ifAttr':
      return new IfAttrNode(pElement, pParent);
    case 'setTemplate':
      return new SetTemplateNode(pElement, pParent);
    case 'setFeatureAttr':
      return new SetFeatureAttrNode(pElement, pParent);
    case 'setStyleAttr':
      return new SetStyleAttrNode(pElement, pParent);
    default:
      return new Node(pParent);
  }
}

function buildRuleTree(pElement, pParent, registerSelfStyledObject) {
  const lNode = getNode(pElement, pParent, registerSelfStyledObject);
  for (const lChild of pElement.children) {
    buildRuleTree(lChild, lNode, registerSelfStyledObject);
  }

  return lNode;
}

/**
 * Factory for self-styled object.
 */
class StyleFactory {
  /**
   * Default constructor
   */
  constructor() {
    /**
     * Mapping between an annotation type and a rule file.
     * @type {Object}
     */
    this.rules = {};

    /**
     * Mapping between an annotation type and a style generator.
     * @type {Object}
     */
    this.styles = [];
  }

  /**
   * Register rendering rules.
   * @param  {String} rules The path to the rules.
   */
  registerRules(rules, registerSelfStyledObject) {
    const self = this;

    // Get the rules file's content, and transform it from an xml file to a javascript object.
    StyleHelper.getXml(
      `${process.env.PUBLIC_URL}/Resources/Symbols/${rules}/Rules.xml`,
      (pDocument) => {
        // Effectively parse the rules.
        // TODO(CMA): 	Check that everything is working properly,
        // 				and handle types other than IdIncident.
        self.styles.push(buildRuleTree(pDocument, null, registerSelfStyledObject));
      },
      false
    );
  }

  /**
   * Get the style associated to an annotation, given its type and its attributes.
   * @param  {Object} annotation The annotation.
   * @return {Object}            The annotation style.
   */
  getStyle(annotation) {
    const lStyle = {};
    lStyle.name = annotation.Uuid;

    const lRawStyle = {};
    const lProperties = {};

    for (const lStyleFactory of this.styles) {
      lStyleFactory.apply(annotation, lRawStyle, lProperties);
    }

    const insertStyle = (composite, key, newStyle) => {
      const oldItem = composite[key];
      let dest = null;

      if (Array.isArray(oldItem)) {
        dest = oldItem;
      } else if (oldItem) {
        dest = [];
        dest.push(oldItem);
      }

      if (dest) {
        dest.push(newStyle);
      } else {
        dest = newStyle;
      }

      return dest;
    };

    const handleStyle = (pType, pStyle) => {
      switch (pType) {
        case 'VrPrimitiveStyle':
          lStyle.primitive = insertStyle(lStyle, 'primitive', StyleHelper.buildPrimitiveStyle(pStyle));
          break;
        case 'VrIconStyle':
          lStyle.icon = insertStyle(lStyle, 'icon', StyleHelper.buildIconStyle(pStyle));
          break;
        case 'VrCurveStyle':
          lStyle.line = insertStyle(lStyle, 'line', StyleHelper.buildLineStyle(pStyle));
          break;
        case 'VrSurfaceStyle':
          lStyle.surface = insertStyle(lStyle, 'surface', StyleHelper.buildSurfaceStyle(pStyle));
          break;
        case 'VrTextStyle':
          lStyle.text = insertStyle(lStyle, 'text', StyleHelper.buildTextStyle(pStyle));
          break;
        default:
      }
    };

    for (const lEntry of Object.entries(lRawStyle)) {
      if (Array.isArray(lEntry[1])) {
        for (const lFinalStyle of lEntry[1]) {
          handleStyle(lEntry[0], lFinalStyle);
        }
      } else {
        handleStyle(lEntry[0], lEntry[1]);
      }
    }

    lStyle.properties = lProperties;

    return lStyle;
  }

  /**
   * Get the Icon Data of an annotation (should have a VrIconStyle).
   * @param  {Object} annotation The annotation.
   * @return {[]]}            The icon Data (URL and Color) if it exists.
   */
  getIconData(pAnnotation) {
    const lStyle = this.getStyle(pAnnotation);

    let lIconURL = null;
    let lColor = null;

    if (lStyle && lStyle.icon) {
      // get rgb color from style and change it to rgba to apply mask and display color on listItem
      lColor = setColorOpacity(lStyle.icon.color, 1.0);
      lIconURL = lStyle.icon.iconURL;

      if (Array.isArray(lStyle.icon)) {
        lIconURL = lStyle.icon[0].iconURL;
        lColor = lStyle.icon[1].color;
      }
    } else {
      lColor = 'rgba(255, 255, 255, 1.0)';
      lIconURL = `../../Resources/Icons/no_photo.png`;
    }

    return [lIconURL, lColor];
  }
}

/**
 * StyleFactory singleton.
 */
export default new StyleFactory();
