import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { Button, Card, CardActions, CardContent, CardHeader, TextField } from '@mui/material';
import { _t } from 'utils/i18n';
import { ServerSettingsManager, SettingsManager } from 'services';

const styles = () => ({
  textField: {
    marginTop: '1rem'
  }
});

/**
 * Developer UI to edit settings ("showServerSettings" option in app-settings)
 */
class ServerSettingsComponent extends React.Component {
  constructor() {
    super();

    this.state = {
      keycloakUrl: ServerSettingsManager.keycloakUrl,
      keycloakRealm: ServerSettingsManager.keycloakRealm,
      keycloakClientId: ServerSettingsManager.keycloakClientId,
      crimsonUrl: ServerSettingsManager.crimsonUrl,
      hasError: false,

      backgrounds: SettingsManager?.map?.backgrounds,
      overlays: SettingsManager?.map?.overlays
    };
  }

  componentDidMount() {
    document.addEventListener('keypress', this.onKeypress);
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.onKeypress);
  }

  onKeypress = (ev) => {
    if (ev.key === 'Enter') {
      this.connect();
    }
  };

  connect = () => {
    // Validate the keycloak config
    const baseUrl = this.state.keycloakUrl;
    const realm = this.state.keycloakRealm;
    const url = `${baseUrl}/realms/${realm}`;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onload = () => {
      if (xhr.status === 200) {
        this.setState({ hasError: false });

        ServerSettingsManager.keycloakUrl = this.state.keycloakUrl;
        ServerSettingsManager.keycloakRealm = this.state.keycloakRealm;
        ServerSettingsManager.keycloakClientId = this.state.keycloakClientId;
        ServerSettingsManager.crimsonUrl = this.state.crimsonUrl;

        this.props.login();
      } else {
        this.setState({ hasError: true });
      }
    };

    xhr.onerror = () => {
      this.setState({ hasError: true });
    };
    xhr.send();
  };

  submit = () => {
    this.connect();

    // Update customized layer settings
    SettingsManager.map.backgrounds = this.state.backgrounds;
    SettingsManager.map.overlays = this.state.overlays;
  };

  handleKeycloakUrlChange = (event) => {
    this.setState({
      keycloakUrl: event.target.value
    });
  };

  handleKeycloakRealmChange = (event) => {
    this.setState({
      keycloakRealm: event.target.value
    });
  };

  handleKeycloakClientIdChange = (event) => {
    this.setState({
      keycloakClientId: event.target.value
    });
  };

  handleCrimsonUrlChange = (event) => {
    this.setState({
      crimsonUrl: event.target.value
    });
  };

  handleBackgroundValueChange = (bgIndex, key, newValue) => {
    this.setState({
      backgrounds: this.state.backgrounds.map((layer, index) => {
        if (index === bgIndex) {
          layer[key] = newValue;
        }
        return layer;
      })
    });
  };

  handleOverlayValueChange = (olIndex, key, newValue) => {
    this.setState({
      overlays: this.state.overlays.map((layer, index) => {
        if (index === olIndex) {
          layer[key] = newValue;
        }
        return layer;
      })
    });
  };

  render() {
    const { splash } = SettingsManager.project;
    const { classes } = this.props;
    const { backgrounds, overlays } = this.state;
    const required = _t('This field is required');

    const keycloakUrlErrorText = this.state.keycloakUrl ? '' : required;
    const keycloakRealmErrorText = this.state.keycloakRealm ? '' : required;
    const keycloakClientIdErrorText = this.state.keycloakClientId ? '' : required;
    const crimsonUrlErrorText = this.state.crimsonUrl || !window.cordova ? '' : required;

    const hasError = Boolean(
      keycloakUrlErrorText || keycloakRealmErrorText || keycloakClientIdErrorText || crimsonUrlErrorText
    );

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          background: 'radial-gradient(ellipse at center bottom, #d6d6d6 0%, #b2b1b1 100%)'
        }}
      >
        <Card style={{ margin: 'auto', maxWidth: '100%' }}>
          <img src={splash || 'img/crimson_logo.png'} alt="splash" style={{ maxWidth: '100%' }} />
          <CardHeader title={_t('Mobile COP URLs setup')} style={{ paddingBottom: '0' }} />
          <CardContent style={{ paddingTop: '0' }}>
            <TextField
              variant="standard"
              label={_t('Keycloak server URL')}
              value={this.state.keycloakUrl}
              onChange={this.handleKeycloakUrlChange}
              helperText={keycloakUrlErrorText}
              FormHelperTextProps={{
                error: true
              }}
              fullWidth
              className={classes.textField}
              disabled={this.props.readonly} />
            <br />
            <TextField
              variant="standard"
              label={_t('Keycloak realm')}
              value={this.state.keycloakRealm}
              onChange={this.handleKeycloakRealmChange}
              helperText={keycloakRealmErrorText}
              FormHelperTextProps={{
                error: true
              }}
              fullWidth
              className={classes.textField}
              disabled={this.props.readonly} />
            <br />
            <TextField
              variant="standard"
              label={_t('Keycloak client ID')}
              value={this.state.keycloakClientId}
              onChange={this.handleKeycloakClientIdChange}
              helperText={keycloakClientIdErrorText}
              FormHelperTextProps={{
                error: true
              }}
              fullWidth
              className={classes.textField}
              disabled={this.props.readonly} />
            <br />
            <TextField
              variant="standard"
              label={_t('COP server URL')}
              value={this.state.crimsonUrl}
              onChange={this.handleCrimsonUrlChange}
              helperText={crimsonUrlErrorText}
              FormHelperTextProps={{
                error: true
              }}
              fullWidth
              className={classes.textField}
              disabled={this.props.readonly} />
            {this.state.hasError ? (
              <div
                style={{
                  maxWidth: '250px',
                  margin: 'auto',
                  textAlign: 'center',
                  color: 'red'
                }}
              >
                <br />
                {_t('Could not contact keycloak server, please check your configuration')}
              </div>
            ) : null}

            <TextField
              variant="standard"
              label={_t('Keycloak client ID')}
              value={this.state.keycloakClientId}
              onChange={this.handleKeycloakClientIdChange}
              helperText={keycloakClientIdErrorText}
              FormHelperTextProps={{
                error: true
              }}
              fullWidth
              className={classes.textField}
              disabled={this.props.readonly} />
            <br />
            <br />

            {/* Layer settings fields */}
            {backgrounds && backgrounds.length > 0 && (
              <>
                <h3>Background layers setup</h3>
                {backgrounds.map((layer, index) => (
                  <div key={index} style={{ marginBottom: 50 }}>
                    {Object.entries(layer).map(([key, value]) =>
                      // Do not render fields for booleans
                      typeof value !== 'boolean' ? (
                        <TextField
                          variant="standard"
                          key={key}
                          label={key}
                          value={value}
                          fullWidth
                          onChange={(event) => this.handleBackgroundValueChange(index, key, event.target.value)} />
                      ) : null
                    )}
                  </div>
                ))}
              </>
            )}

            {overlays && overlays.length > 0 && (
              <>
                <h3>Overlay layers setup</h3>
                {overlays.map((layer, index) => (
                  <div key={index} style={{ marginBottom: 50 }}>
                    {Object.entries(layer).map(([key, value]) =>
                      // Do not render fields for booleans
                      typeof value !== 'boolean' ? (
                        <TextField
                          variant="standard"
                          key={key}
                          label={key}
                          value={value}
                          fullWidth
                          onChange={(event) => this.handleOverlayValueChange(index, key, event.target.value)} />
                      ) : null
                    )}
                  </div>
                ))}
              </>
            )}
          </CardContent>
          {!this.props.readonly && (
            <CardActions>
              <Button variant="contained" color="primary" fullWidth onClick={this.submit} disabled={hasError}>
                {_t('Connect')}
              </Button>
            </CardActions>
          )}
        </Card>
      </div>
    );
  }
}

ServerSettingsComponent.propTypes = {
  login: PropTypes.func
};

export default withTranslation('common')(withStyles(styles)(ServerSettingsComponent));
