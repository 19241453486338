import React, { useState } from 'react';
import { _t } from 'utils/i18n';
import { ClassicSpinner } from 'react-spinners-kit';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Paper,
  TextField
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { SettingsManager } from 'services';

/**
 * Styles for the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    alignItems: 'center'
  },

  paper: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '50vh'
    }
  },

  textField: {
    width: '100%'
  },
  spinnerRoot: {
    margin: 5
  }
}));

/**
 * A component to display the login form
 *
 */
const Login = ({ loginError, onLogin, eraseError }) => {
  const classes = useStyles();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emptyPassErr, setEmptyPassErr] = useState(false);
  const [emptyUserErr, setEmptyUserErr] = useState(false);

  const theme = useTheme();

  const { logo } = SettingsManager.project;

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleUserChange = (event) => {
    eraseError();
    setLoginLoading(false);
    setUser(event.target.value);
  };

  const handlePasswordChange = (event) => {
    eraseError();
    setLoginLoading(false);
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(!rememberMe);
  };

  const handleLoginClick = (event) => {
    event.preventDefault();
    setEmptyPassErr(false);
    setEmptyUserErr(false);

    if (user === '' || password === '') {
      // Only check one field at a time to show only 1 error
      if (user === '') {
        setEmptyUserErr(true);
      } else if (password === '') {
        setEmptyPassErr(true);
      }
      return;
    }

    setLoginLoading(true);
    eraseError();
    onLogin(user, password, rememberMe);
  };

  React.useEffect(() => {
    if (loginError !== "") {
      setLoginLoading(false);
    }
  }, [loginError]);

  return (
    <div className={classes.container}>
      <Box component={Paper} p={3} m={1} className={classes.paper} elevation={4}>
        <form onSubmit={handleLoginClick}>
          <Grid container direction="column" justifyContent="center" alignItems="flex-start" spacing={4}>
            <Grid item>
              {/* Edit app-settings.json to update the logo */}
              {/* Only show when finished loading to avoid flickering the default logo */}
              {loginLoading === false && (
                <img
                  style={{ maxWidth: '95%' }}
                  alt="logo"
                  // If logo is not defined, use default crimson logo path
                  src={process.env.PUBLIC_URL + '/' + (logo || 'img/crimson_logo.png')}
                />
              )}
            </Grid>
            <Grid className={classes.textField} item>
              <TextField
                variant="standard"
                error={loginError !== "" || emptyUserErr}
                disabled={loginLoading}
                value={user}
                onChange={handleUserChange}
                fullWidth
                label={_t('Username')}
                placeholder={_t('Please enter your identifier')}
                autoComplete="username"
                autoFocus />
            </Grid>
            <Grid className={classes.textField} item>
              <TextField
                variant="standard"
                error={loginError !== "" || emptyPassErr}
                disabled={loginLoading}
                value={password}
                onChange={handlePasswordChange}
                fullWidth
                label={_t('Password')}
                placeholder={_t('Please enter your password')}
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="large">
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }} />
            </Grid>
            {loginError ? (
              <Grid item>
                <FormHelperText error>
                  <b>{loginError}</b>
                </FormHelperText>
              </Grid>
            ) : null}

            {emptyUserErr || emptyPassErr ? (
              <Grid item>
                <FormHelperText error>
                  <b>{_t(emptyUserErr ? 'Veuillez saisir un utilisateur' : 'Veuillez saisir un mot de passe')}</b>
                </FormHelperText>
              </Grid>
            ) : null}

            <Grid item>
              <FormControlLabel
                control={<Checkbox checked={rememberMe} onChange={handleRememberMeChange} color="primary" />}
                label={_t('Remember Me')}
              />
            </Grid>

            <Grid item xs={12} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              {!loginLoading ? (
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  {_t('Submit')}
                </Button>
              ) : (
                <div className={classes.spinnerRoot}>
                  <ClassicSpinner color={theme.palette.primary.main} loading />
                </div>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default Login;
