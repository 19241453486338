class Settings {
  constructor() {
    this._API_ROOT_V5 = '/crimson/';
    this._API_ROOT_V6 = '/api/v1/';
  }

  /**
   * Load the settings from the public folder
   */
  load() {
    // Use XMLHttpRequest, because fetch do not support loading from file:// (cordova)
    const lPromise = new Promise((resolve, reject) => {
      const lFile = `${process.env.PUBLIC_URL}/Resources/app-settings.json`;
      const pRequest = new XMLHttpRequest();
      pRequest.open('GET', lFile);
      pRequest.send(null);
      pRequest.onreadystatechange = () => {
        if (pRequest.readyState === 4) {
          if (pRequest.status === 200) {
            try {
              const lJSONSettings = JSON.parse(pRequest.response);
              Object.assign(this, lJSONSettings);
              resolve();
            } catch (err) {
              reject(`Invalid JSON configuration file => ${err}.`);
            }
          } else {
            reject(`Configuration file ${lFile} not found.`);
          }
        }
      };
    });

    return lPromise;
  }

  /**
   * Get the value of a settings.
   * The value can be retreived from local storage
   *
   * @param {*} pName
   */
  get(pName, pDefault) {
    // First check local storage
    if (window.localStorage.getItem(pName)) {
    }
    if (this.hasOwnProperty(pName)) {
      return this[pName];
    }
    return pDefault;
  }

  /**
   * Return the crimson server routes matching the version set in app settings
   */
  getServerRoutes() {
    if (this?.crimsonServer?.version && this.crimsonServer.version < 6) {
      return {
        session: this._API_ROOT_V5 + 'session',
        asset: 'asset',
        entity: 'entity',
        event: 'event'
      };
      // If crimsonServer.version is not specified it will default to v5
    } else {
      if (this?.crimsonServer?.version === undefined) {
        console.error('Error in app settings: crimsonServer.version is not set. Defaulting to v6.');
      }

      return {
        session: this._API_ROOT_V6 + 'sessions',
        asset: 'assets',
        entity: 'entities',
        event: 'events'
      };
    }
  }

  /**
   * Get the root
   * @returns 
   */
  getApiRoot() {
    if (this?.crimsonServer?.version && this.crimsonServer.version < 6) {
      return this._API_ROOT_V5;
    } else {
      return this._API_ROOT_V6;
    }
  
  }

  getApiRootv5() {
    return this._API_ROOT_V5;
  }

  getApiRootv6() {
    return this._API_ROOT_V6;
  }
}

export default new Settings();
