import { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { StyleFactory, NotificationManager } from 'services';
import { withAnnotations } from '../../CopContext';
import { withMapManager } from '../../MapManagerContext';
import { isIncidentMine } from 'CopContext/utils';

import { _t } from '../../utils/i18n';
import { compose } from 'utils';

import withSnackNotifications from '../../CopContext/withSnackNotifications';

class IncidentElement extends Component {
  componentDidUpdate(prevProps) {
    const { incident, snackNotify } = this.props;
    // We should send an assignment notification when this component recieves an incident
    if (prevProps.ready && !prevProps.incident && incident) {
      const body = incident.Name ? incident.Name : _t(incident.IncidentType);
 
      const iconData = StyleFactory.getIconData(incident);
      const icon = iconData ? process.env.PUBLIC_URL + iconData[0] : '';

      NotificationManager.notify({
				title: _t('New incident assigned'),
				body,
				icon,
				onClick: () => {
					
					 // Note (SBE): We are disabling the animation for this jump because as the map
					 // is immediately unmounted afterwards (route change), it is the only way
					 // to actually center the map on the incident.
					this.props.MapManager.jumpTo(incident, true);
					this.props.history.push(`/incident/${incident.Uuid}`);
				}
			});

      snackNotify(_t('New incident assigned'), { priority: 100 });
    }

    // We should send an update snack notification when the incident changes
    if (prevProps.incident && incident && !isEqual(prevProps.incident, incident)) {
      snackNotify(_t('Assigned incident updated'), { priority: 100 });
    }
  }

  render() {
    return null;
  }
}

export default compose(
  withAnnotations({
    types: 'Incident',
    shouldBeVisible: ({ annotation, team }) => isIncidentMine(annotation, team),
    mapAnnotationsToProps: (annotations) => {
      const matchingAnnotations = Object.values(annotations);
      return {
        incident: matchingAnnotations.length > 0 && matchingAnnotations[0]
      };
    }
  }),
  withMapManager,
  withSnackNotifications
)(IncidentElement);
