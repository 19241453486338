import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'utils/withRouter';
import { GeoLocationManager, KeycloakManager, SettingsManager } from 'services';
import { withCop } from 'CopContext';
import { compose } from 'utils';

class GeoPosCopElement extends React.Component {
  constructor() {
    super();

    this.sendingPosition = false;
    this.trackEntity = null;
    this.watchLocationId = -1;
    this.position = [NaN, NaN, NaN];
    this.positionRefresh = 0;
    this.lastRefreshInterval = GeoLocationManager.backgroundTrackingInterval;
    this.lastGPSUpdateTime = new Date().getTime();
  }

  /**
   * Check if it is worth updating the annotation
   * @param {Array} p
   */
  worthUpdating(p) {
    const px = typeof p[0] === 'string' ? parseFloat(p[0]) : p[0];
    const py = typeof p[1] === 'string' ? parseFloat(p[1]) : p[1];

    if (Number.isNaN(px) || Number.isNaN(py)) {
      return true;
    }

    const vx = p[0] - this.position[0];
    const vy = p[1] - this.position[1];
    const d = vx * vx + vy * vy;
    return Math.sqrt(d) > 1e-5;
  }

  /**
   * Call each time a position is retrieved even if device did not move
   */
  onWatchPosition = (pCoordinates) => {
    const { team } = this.props;

    this.position = [pCoordinates.longitude, pCoordinates.latitude, pCoordinates.accuracy];

    // If user is in a team and geolocation settings are defined
    if (SettingsManager?.geoPos && SettingsManager?.geoPos?.handleTeam && team) {
      // Skip invalid values
      if (Number.isNaN(this.position[0]) || Number.isNaN(this.position[1])) {
        return;
      }

      const intervalSeconds = GeoLocationManager.geoPosRefreshInterval / 1000;

      // If the last GPS commit occured before the defined interval (avoids request spam)
      if (new Date().getTime() / 1000 - this.lastGPSUpdateTime / 1000 >= intervalSeconds) {
        console.info('Info: sending new GPSUpdateDate.');

        const isoDate = new Date().toISOString();

        this.props.updateAnnotation(
          {
            ...team,
            FeatureGeometry: {
              Point: [`${this.position[0]}`, `${this.position[1]}`, '0'],
              Scale: ['1', '1', '1'],
              Rotation: ['0', '90', '0'],
              Offset: ['0', '0', '0'],
              type: 'PointGeometry'
            },
            GpsUpdateDate: isoDate
          },
          {
            successHandler: () => (this.sendingPosition = false),
            errorHandler: () => (this.sendingPosition = false),
            priority: 10,
            immediate: true
          }
        );

        // Save new time of update
        this.lastGPSUpdateTime = isoDate;
      }
    }
  };

  /**
   * Start watching location
   */
  start = () => {
    if (this.watchLocationId < 0) {
      // Start on background if requested by default
      if (SettingsManager.geoPos && SettingsManager.geoPos.backgroundAtStartup) {
        GeoLocationManager.startBackground();
      }

      // Start watching location. Tell GeoLocationManager to use background tracking if activated.
      this.watchLocationId = GeoLocationManager.watchLocation(this.handleNewLocation, {
        background: true,
        onWatchPosition: this.onWatchPosition
      });

      // Force update position (even when background mode is enabled)
      this.positionRefresh = setInterval(this.updateTeamPosition, 5000);
    }
  };

  /**
   * Stop the update of the position
   */
  componentWillUnmount() {
    GeoLocationManager.clearWatch(this.watchLocationId);
    clearInterval(this.positionRefresh);
    this.positionRefresh = 0;
  }

  /**
   * Called when the component is updated
   * Update the team, so update background geolocation configuration.
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    const { team, currentUserTeamInfo, teammates } = this.props;

    let trackEntity;
    if (currentUserTeamInfo.isLeader) {
      trackEntity = team;
    } else if (teammates) {
      trackEntity = teammates.find((t) => t.ActorID === KeycloakManager.getUser().sub);
    }

    if (this.props.ready && trackEntity && this.trackEntity !== trackEntity) {
      // Hack!! configure the background geoloc
      if (window.BackgroundGeolocation) {
        window.BackgroundGeolocation.configure({
          httpHeaders: {
            Authorization: `Bearer ${KeycloakManager.getAccessToken()}`
          },
          postTemplate: {
            lat: '@latitude',
            lon: '@longitude',
            accuracy: '@accuracy',
            team: trackEntity.Uuid,
            exercise: this.props.session.name
          }
        });
      }
      if (window.cordova && window.cordova.plugins.backgroundMode) {
        window.cordova.plugins.backgroundMode.setEnabled(this.props.backgroundModeEnabled);
      }
    }

    if (this.props.ready && !trackEntity) {
      if (window.cordova && window.cordova.plugins.backgroundMode) {
        window.cordova.plugins.backgroundMode.setEnabled(false);
      }
    }

    if (this.props.ready && trackEntity) {
      this.start();

      // Active background mode if requested
      if (window.cordova && window.cordova.plugins.backgroundMode) {
        if (this.props.backgroundModeEnabled !== prevProps.backgroundModeEnabled) {
          window.cordova.plugins.backgroundMode.setEnabled(this.props.backgroundModeEnabled);
        }
      }
    }

    if (trackEntity) {
      GeoLocationManager.setProps(trackEntity.Uuid, this.props.session.name);
    }

    this.trackEntity = trackEntity;
  }

  /**
   * Update team position
   */
  updateTeamPosition = () => {
    // If we change team position form Crimson Station we need to resend the position
    // Do something only if background mode is not enabled
    if (SettingsManager.geoPos && SettingsManager.geoPos.handleTeam && this.props.team) {
      // Skip invalid values
      if (Number.isNaN(this.position[0]) || Number.isNaN(this.position[1])) {
        return;
      }

      // Commit on change
      this.commitTeamPosition();
    }
  };

  /**
   * Update the position of the team
   * Only call when in foreground mode
   */
  commitTeamPosition = () => {
    const { team, currentUserTeamInfo, teammates } = this.props;
    const position = this.position;

    if (this.sendingPosition) {
      return;
    }

    const isoDate = new Date().toISOString();

    if (currentUserTeamInfo.isLeader) {
      // If leader update the position of the team
      if (this.worthUpdating(team.FeatureGeometry.Point)) {
        this.sendingPosition = true;
        this.props.updateAnnotation(
          {
            ...team,
            FeatureGeometry: {
              Point: [`${position[0]}`, `${position[1]}`, '0'],
              Scale: ['1', '1', '1'],
              Rotation: ['0', '90', '0'],
              Offset: ['0', '0', '0'],
              type: 'PointGeometry'
            },
            GpsUpdateDate: isoDate
          },
          {
            successHandler: () => (this.sendingPosition = false),
            errorHandler: () => (this.sendingPosition = false),
            priority: 10,
            immediate: true
          }
        );
      }
    } else {
      // Find the teamate entity for the current user
      const teamate = teammates.find((t) => t.ActorID === KeycloakManager.getUser().sub);
      if (teamate && this.worthUpdating(teamate.FeatureGeometry.Point)) {
        this.sendingPosition = true;

        const isoDate = new Date().toISOString();

        this.props.updateAnnotation(
          {
            ...teamate,
            FeatureGeometry: {
              Point: [`${position[0]}`, `${position[1]}`, '0'],
              Scale: ['1', '1', '1'],
              Rotation: ['0', '90', '0'],
              Offset: ['0', '0', '0'],
              type: 'PointGeometry'
            },
            GpsUpdateDate: isoDate
          },
          {
            successHandler: () => (this.sendingPosition = false),
            errorHandler: () => (this.sendingPosition = false),
            priority: 10,
            immediate: true
          }
        );
      }
    }

    // Save new time of update
    this.lastGPSUpdateTime = isoDate;
  };

  /**
   * Handle a new location from the GeoLocationManager.
   * @param  latitude
   * @param  longitude
   */
  handleNewLocation = (latitude, longitude, accuracy, isBackground) => {
    // Discard invalid values
    if (Number.isNaN(latitude) || Number.isNaN(longitude)) {
      return;
    }

    // Store the new position
    this.position = [longitude, latitude, accuracy];

    // Check if we really need to update the position
    this.updateTeamPosition();
  };

  render() {
    return null;
  }
}

GeoPosCopElement.propTypes = {
  team: PropTypes.object,
  teammates: PropTypes.array,
  ready: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
  currentUserTeamInfo: PropTypes.object.isRequired,
  addAnnotation: PropTypes.func.isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withRouter, withCop)(GeoPosCopElement);
