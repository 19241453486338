import React from 'react';
// import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import Warning from '@mui/icons-material/Warning';
import Close from '@mui/icons-material/Close';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  rightIcon: {
    fontSize: 20,
    color: theme.palette.common.white
  },
  leftIcon: {
    marginRight: '0.5rem',
    fontSize: 20,
    color: theme.palette.common.white
  },
  snackbar: {
    zIndex: 1000,
    bottom: '3.5rem'
  },
  content: {
    flexWrap: 'nowrap'
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  action: {
    paddingLeft: 0
  }
});

const withSnackNotifications = BaseComponent => {
  return withTranslation('common')(
    withStyles(styles)(
      // withRouter(
        class extends React.Component {
          constructor() {
            super();

            this.state = {
              open: false
            };
          }

          handleNotificationRequest = (message, options) => {
            const { priority, duration = 4000, forceBottom = false } = options;
            if (priority >= 50 && (!this.state.open || this.state.priority <= priority)) {
              this.setState({
                open: true,
                priority,
                key: new Date().getTime(),
                message,
                duration,
                forceBottom
              });
            }
          };

          closeSnack = (_, reason) => {
            if (reason !== 'clickaway') {
              this.setState({
                open: false
              });
            }
          };

          render() {
            const { classes, ...rest } = this.props;
            const { open, key, message, duration } = this.state;

            return <>
              <BaseComponent snackNotify={this.handleNotificationRequest} {...rest} />
              <Snackbar
                key={key}
                open={open}
                autoHideDuration={duration}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                onClose={this.closeSnack}
                // This is to offset the bottom navigation in the map / log routes
                // It is likely temporary as the app will be redesigned
                // className={
                //   !forceBottom && (this.props.location.pathname === '/main/map' || this.props.location.pathname === '/main/log')
                //     ? classes.snackbar
                //     : ''
                // }
                className={classes.snackbar}
              >
                <SnackbarContent
                  className={classes.content}
                  classes={{
                    message: classes.message,
                    action: classes.action
                  }}
                  action={
                    <IconButton className={classes.rightIcon} onClick={this.closeSnack} size="large">
                      <Close />
                    </IconButton>
                  }
                  message={
                    <>
                      <Warning className={classes.leftIcon} />
                      {message}
                    </>
                  }
                />
              </Snackbar>
            </>;
          }
        }
      )
    );
  // );
};

export default withSnackNotifications;
