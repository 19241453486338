import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { ClassicSpinner } from 'react-spinners-kit';

const size = 50;

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: size,
    height: size,
    zIndex: 2000
  }
});

const TopLoader = ({ loading }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ClassicSpinner color={theme.palette.primary.main} loading={loading} size={size} />
    </div>
  );
};

TopLoader.propTypes = {
  prop1: PropTypes.bool
};

TopLoader.defaultProps = {
  loading: true
};

export default TopLoader;
