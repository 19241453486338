import React from 'react';
import { SvgIcon } from '@mui/material';

const MeasureIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 100 100">
            <path
                d="M2.063 51.733a4.127 4.127 0 00-1.51 5.637l18.34 31.768a4.127 4.127 0 005.638 1.51l73.406-42.38a4.127 4.127 0 001.51-5.638l-18.34-31.768a4.127 4.127 0 00-5.638-1.51zm7.701 5.084l66.258-38.254 14.214 24.62-4.775 2.757-5.327-9.229-3.574 2.064 5.327 9.228-5.465 3.156-5.328-9.229-3.574 2.064 5.328 9.228-5.459 3.152-5.328-9.229-3.574 2.064 5.328 9.228-5.465 3.156-7.404-12.823-3.574 2.063 7.404 12.823-5.466 3.156-5.328-9.229-3.574 2.064 5.328 9.228-5.459 3.151-5.327-9.228-3.574 2.064 5.327 9.228-5.465 3.155-5.328-9.228-3.574 2.064 5.328 9.228-3.686 2.128z"
                overflow="visible" />
        </SvgIcon>
    );
};

export default MeasureIcon;
