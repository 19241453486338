import { KeycloakManager, SettingsManager } from 'services';
import { _t } from 'utils/i18n';
import { getCurrentPosition } from 'utils/locationUtils';

// Utility function to get the distance in meter between two GPS position
function getDistanceFromLatLonInM(lat1, lon1, lat2, lon2) {
  const R = 6371445; // Radius of the earth in m
  const deg2rad = Math.PI / 180;
  const dLat = (lat2 - lat1) * deg2rad;
  const dLon = (lon2 - lon1) * deg2rad;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * deg2rad) * Math.cos(lat2 * deg2rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in m
  return d;
}

/*
window.BackgroundGeolocation = {
	events: {},
	configure: function() {},
	start: function() {
		setTimeout( this.events["start"], 0);
	},
	on: function(name,func) {
		this.events[name] = func;
	},
	stop: function() {

	},
	checkStatus: function() {

	},
	debugSend: function(position,team,sessionId) {
			const lUrl = 'http://172.19.40.51:3002/crimson/tracking';
			const lContent = JSON.stringify([{
				lat: `${position[1]}`,
				lon: `${position[0]}`,
				team: team.Uuid,
				exercise: sessionId
			}
			]);
			const lData = {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${KeycloakManager.getAccessToken()}`,
					'Content-Type': 'application/json'
				},
				body: lContent
				// mode: 'cors'
			};
			fetch(lUrl, lData);
	}
}*/

const LOG = (msg) => {
  console.log(new Date().toUTCString() + ' ' + msg);
};

/**
 * Manager to get proper location informations
 */
class GeoLocationManager {
  /**
   * Constructor
   */
  constructor() {
    this.lastPosition = null;
    this.watcherId = null;
    this.backgroundActivated = false;
    this.watchers = {};
    this.nbBackgroundUpdates = 0;
    this.nbForegroundUpdates = 0;
    this.backgroundTrackingInterval = 30;
    this.geoPosRefreshInterval = SettingsManager?.geoPos?.refreshInterval || 5000;
  }

  setProps(pTeam, pSession) {
    this.team = pTeam;
    this.session = pSession;
  }

  startBackground(startFunc, props) {
    const BackgroundGeolocation = window.BackgroundGeolocation;
    if (BackgroundGeolocation && !this.backgroundActivated) {
      if (props && props.backgroundTrackingInterval) {
        this.backgroundTrackingInterval = props.backgroundTrackingInterval;
      }

      BackgroundGeolocation.configure({
        locationProvider: BackgroundGeolocation.ACTIVITY_PROVIDER,
        desiredAccuracy: BackgroundGeolocation.HIGH_ACCURACY,
        stationaryRadius: 50,
        distanceFilter: 50,
        notificationTitle: 'CRIMSON ' + _t('Background Tracking'),
        notificationText: _t('Background Tracking Enabled'),
        debug: false,
        interval: this.backgroundTrackingInterval * 1000,
        fastestInterval: this.backgroundTrackingInterval * 500,
        activitiesInterval: this.backgroundTrackingInterval * 1000,
        url: SettingsManager.crimsonServer.cordova + '/tracking',
        httpHeaders: {
          Authorization: 'Bearer ' + KeycloakManager.getAccessToken()
        },
        postTemplate: {
          lat: '@latitude',
          lon: '@longitude',
          accuracy: '@accuracy',
          exercise: this.session.name,
          team: this.team
        }
      });

      BackgroundGeolocation.on('location', (location) => {
        LOG(
          `[INFO] BackgroundGeolocation : ${location.latitude}  ${location.longitude}  ${location.accuracy}  ${location.provider}`
        );

        this.nbBackgroundUpdates++;

        // Call background watcher
        for (const [, watcher] of Object.entries(this.watchers)) {
          if (watcher.background) {
            watcher.func(location.latitude, location.longitude, location.accuracy, true);
          }
        }
      });

      BackgroundGeolocation.on('stationary', function (stationaryLocation) {
        // handle stationary locations here
      });

      BackgroundGeolocation.on('error', (error) => {
        LOG('[ERROR] BackgroundGeolocation error:', error.code, error.message);
        this.backgroundActivated = false;
        startFunc({ ...error, isRunning: false });
      });

      BackgroundGeolocation.on('start', () => {
        LOG('[INFO] BackgroundGeolocation service has been started');
        this.backgroundActivated = true;
        if (startFunc) {
          startFunc({ isRunning: true });
        }
      });

      BackgroundGeolocation.on('authorization', function (status) {
        LOG('[INFO] BackgroundGeolocation authorization status: ' + status);
        if (status !== BackgroundGeolocation.AUTHORIZED) {
          /* // we need to set delay or otherwise alert may not be shown
						setTimeout(function() {
						var showSettings = confirm('App requires location tracking permission. Would you like to open app settings?');
						if (showSetting) {
							return BackgroundGeolocation.showAppSettings();
						}
						}, 1000);*/
        }
      });

      BackgroundGeolocation.on('background', function () {
        LOG('[INFO] App is in background');
      });

      BackgroundGeolocation.on('foreground', function () {
        LOG('[INFO] App is in foreground');
      });

      BackgroundGeolocation.on('abort_requested', function () {
        LOG('[INFO] Server responded with 285 Updates Not Required');

        // Here we can decide whether we want stop the updates or not.
        // If you've configured the server to return 285, then it means the server does not require further update.
        // So the normal thing to do here would be to `BackgroundGeolocation.stop()`.
        // But you might be counting on it to receive location updates in the UI, so you could just reconfigure and set `url` to null.
      });

      BackgroundGeolocation.on('http_authorization', () => {
        LOG('[INFO] App needs to authorize the http requests');
      });

      LOG('[INFO] BackgroundGeolocation started.');
      BackgroundGeolocation.start();
    } else {
      LOG('[INFO] No background geolocation available.');
      if (startFunc) {
        startFunc({ isRunning: false, locationServicesEnabled: false });
      }
    }
  }

  stopBackground() {
    if (window.BackgroundGeolocation) {
      this.backgroundActivated = false;
      window.BackgroundGeolocation.stop();
    }
  }

  getBackgroundStatus(func) {
    const BackgroundGeolocation = window.BackgroundGeolocation;
    if (BackgroundGeolocation) {
      BackgroundGeolocation.checkStatus(function (status) {
        console.log('[INFO] BackgroundGeolocation service is running', status.isRunning);
        console.log('[INFO] BackgroundGeolocation services enabled', status.locationServicesEnabled);
        console.log('[INFO] BackgroundGeolocation auth status: ' + status.authorization);

        func(status);
      });
    } else {
      func({ isRunning: false, locationServicesEnabled: false });
    }
  }

  /**
   * Promise version of getLocationCb
   */
  getLocation = () => {
    if (this.lastPosition && this.watcherId) {
      return new Promise((resolve) => {
        resolve(this.lastPosition);
      });
    } else {
      return getCurrentPosition();
    }
  };

  getLocationCb(func) {
    // No need to query a new pos if we are already watching it
    if (this.lastPosition && this.watcherId) {
      GeoLocationManager.callFunc(func, this.lastPosition);
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          GeoLocationManager.callFunc(func, position);
        },
        (err) => {
          console.error(err);
        },
        {
          timeout: 2000
        }
      );
    }
  }

  clearWatch(pId) {
    delete this.watchers[pId];
    if (Object.entries(this.watchers).length === 0) {
      navigator.geolocation.clearWatch(this.watcherId);
      this.watcherId = null;
    }
  }

  watchLocation(func, props) {
    const options = {
      enableHighAccuracy: true,
      // timeout: 10000,
      maximumAge: 0
    };

    // Register a watcher if we are not currently watching anything
    if (!this.watcherId) {
      this.watcherId = navigator.geolocation.watchPosition(this.onLocation, console.error, options);
    }

    const id = Object.entries(this.watchers).length;
    this.watchers[id] = {
      ...props,
      func
    };

    if (this.lastPosition) {
      GeoLocationManager.callFunc(func, this.lastPosition);
    }

    return id;
  }

  /**
   * On device location change
   */
  onLocation = (pPosition) => {


    let hasMoved = true;

    if (this.lastPosition) {
      const lat1 = this.lastPosition.coords.latitude;
      const lon1 = this.lastPosition.coords.longitude;
      const lat2 = pPosition.coords.latitude;
      const lon2 = pPosition.coords.longitude;
      const distance = getDistanceFromLatLonInM(lat1, lon1, lat2, lon2);

      hasMoved =
        distance > pPosition.coords.accuracy || pPosition.coords.accuracy < this.lastPosition.coords.accuracy - 5;
    }

    if (hasMoved) {
      this.onNewLocation(pPosition);
    } else {
      // Notify that position has updated but not moved significantly
      for (const watcher of Object.values(this.watchers)) {
        if(watcher?.onWatchPosition) {
          watcher?.onWatchPosition(pPosition.coords);
          // Only trigger for first watcher
          break;
        }
      }
    }
  };

  /**
   * On device location changed and moved
   */
  onNewLocation = (position) => {
    this.nbForegroundUpdates++;

    this.lastPosition = position;
    for (const [, watcher] of Object.entries(this.watchers)) {
      if (!this.backgroundActivated || !watcher.background) {
        GeoLocationManager.callFunc(watcher.func, position);
      }
    }
  };

  static callFunc = (func, position) => {
    const { latitude, longitude, accuracy } = position.coords;
    func(latitude, longitude, accuracy);
  };
}

/**
 * GeoLocationManager singleton.
 */
export default new GeoLocationManager();
