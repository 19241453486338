import React from 'react';
import { SvgIcon } from '@mui/material';

const MeasureAreaIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 100 100">
            <path
                d="M35.102 0c-6.258 0-11.385 5.135-11.385 11.393 0 2.952 1.171 5.63 3.035 7.662L17.404 37.92c-.338-.031-.665-.102-1.011-.102C10.135 37.818 5 42.945 5 49.203s5.135 11.393 11.393 11.393c3.96 0 7.46-2.064 9.503-5.16l46.67 6.736c1.213 4.983 5.703 8.734 11.041 8.734C89.865 70.906 95 65.78 95 59.521c0-6.062-4.83-11.038-10.82-11.345l-6.133-19.133c2.624-2.093 4.351-5.276 4.351-8.867 0-6.258-5.134-11.395-11.392-11.395-3.965 0-7.468 2.065-9.512 5.168L46.338 9.81C45.553 4.298 40.818 0 35.102 0zm0 5.771c3.14 0 5.63 2.482 5.63 5.622 0 3.14-2.49 5.623-5.63 5.623-3.14 0-5.614-2.484-5.614-5.623 0-3.14 2.474-5.622 5.614-5.622zm35.904 8.782c3.14 0 5.615 2.483 5.615 5.623s-2.475 5.62-5.615 5.62-5.629-2.48-5.629-5.62 2.49-5.623 5.629-5.623zm-26.158 2.634l14.867 4.07c.555 5.74 5.38 10.284 11.252 10.304l5.998 18.755c-1.55 1.126-2.771 2.638-3.606 4.375l-45.691-6.593c-.277-2.76-1.504-5.248-3.41-7.08l9.111-18.405c.57.09 1.138.174 1.733.174 4.139 0 7.749-2.266 9.746-5.6zM16.393 43.58c3.14 0 5.63 2.483 5.63 5.623a5.591 5.591 0 01-5.63 5.629c-3.14 0-5.622-2.49-5.622-5.629a5.578 5.578 0 015.622-5.623zm67.214 10.313c3.14 0 5.622 2.489 5.622 5.628 0 3.14-2.482 5.608-5.622 5.608-3.14 0-5.63-2.468-5.63-5.608a5.591 5.591 0 015.63-5.628zM5 76v24H95V76h-6.27v17.896h-7.687V81.174h-6.27v12.722h-7.681V81.174h-6.268v12.722h-7.69V76h-6.269v17.896h-7.69V81.174h-6.267v12.722h-7.681V81.174h-6.27v12.722H11.27V76H5z"
                overflow="visible" fillRule="evenodd" />
        </SvgIcon>
    );
};

export default MeasureAreaIcon;
