/**
 * Wrapper around fetch
 * Provides cancellation, improved error handling and JSON parsing
 *
 * @param {Object} params
 * @param {string} params.url
 * @param {"GET" | "POST" | "PUT" | "PATCH" | "DELETE"=} params.method default "GET"
 * @param {Object=} params.headers
 * @param {Object=} params.body
 * @param {AbortSignal=} params.abortSignal
 *
 * @returns {Promise<*>} the JSON data or an empty string
 */
export const fetchData = async ({ url, method = 'GET', headers, body, abortSignal }) => {
  try {
    // Stringify body if content is JSON
    if (
      headers &&
      body &&
      Object.values(headers).some((headerValue) => headerValue.toLowerCase() === 'application/json')
    ) {
      body = JSON.stringify(body);
    }

    const response = await fetch(url, {
      method,
      headers,
      body: body ? body : undefined,
      signal: abortSignal
    });

    if (response.ok) {
      // Only parse the response if it is JSON
      const contentType = response.headers.get('Content-Type');
      if (contentType && response.status !== 204) {
        if (contentType.includes('application/json')) {
          return response.json();
        }
      } else if (
        contentType.includes('text/plain') ||
        contentType.includes('text/xml') ||
        contentType.includes('application/xml')
      ) {
        return response.text();
      } else if (contentType.includes('application/octet-stream')) {
        return response.arrayBuffer();
      }

      return '';
    } else {
      // throw new Error(response.status + response.statusText);
      return Promise.reject(response);
    }
  } catch (err) {
    throw err;
  }
};

/**
 * Wrapper around XHR for easier use
 * Provides a promise, error handling and cancellation
 *
 * XHR is useful to access files with Cordova
 *
 * @param {Object} params
 * @param {string} params.url
 * @param {"arraybuffer" | "blob" | "document" | "json" | "text" } params.responseType default "text"
 * @param {"GET" | "POST" | "PUT" | "PATCH" | "DELETE"=} params.method default "GET"
 * @param {Object=} params.headers
 * @param {Object=} params.body
 * @param {AbortSignal=} params.abortSignal
 *
 * @returns {Promise<*>}
 */
export const makeXMLHTTPRequest = ({ url, responseType = 'text', method = 'GET', headers = {}, body, abortSignal }) => {
  return new Promise((resolve, reject) => {
    class AbortError extends Error {
      name = 'AbortError';
      message = 'Aborted';
    }

    if (abortSignal && abortSignal.aborted) {
      return reject(new AbortError());
    }

    const xhr = new XMLHttpRequest();
    xhr.responseType = responseType;

    const abort = () => {
      xhr.abort();
    };

    xhr.open(method, url);

    Object.keys(headers).forEach((key) => {
      xhr.setRequestHeader(key, headers[key]);
    });

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };

    if (abortSignal) {
      abortSignal.addEventListener('abort', abort);

      xhr.onreadystatechange = () => {
        // On completion
        if (xhr.readyState === 4) {
          abortSignal.removeEventListener('abort', abort);
        }
      };
    }

    xhr.onabort = function () {
      reject(new AbortError());
    };

    xhr.ontimeout = reject;
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(body);
  });
};
