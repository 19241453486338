import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Typography, Container } from '@mui/material';

/**
 * Styles for the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default
  }
}));

/**
 * Display fullscreen loading state
 *
 */
const Loading = () => {
  const classes = useStyles();
  const {t} = useTranslation()

  return (
    <div className={classes.container}>
      <Container style={{ textAlign: 'center' }}>
        <CircularProgress style={{ display: 'block', margin: 'auto', marginBottom: 10 }} />
        <Typography variant="h6" component="h1">{t("Loading")}</Typography>
      </Container>
    </div>
  );
};

export default Loading;
