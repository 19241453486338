import React, {useState} from 'react';
import { Button } from '@mui/material';
import { _t } from 'utils/i18n';
import { withAnnotations } from 'CopContext';
import UpdateConfirmationDialog from 'Plugins/IncidentPlugin/UpdateConfirmationDialog';

// Component used to alert the user if the ongoing exercise has stopped.
// By aknowledging it and clicking the dialog button, the user is taken back to the session page.
// Exercises in "Debrief" are not available on Web/Mobile for now.
const StopSessionIndicator = (props) => {

  const [sessionEnded, setSessionEnded] = useState(false);
  const {entity, onChangeSession} = props;

  if (entity?.broadcastStopExercise && sessionEnded === false){
    setSessionEnded(true)
  } 

    return (
      <UpdateConfirmationDialog
        open={sessionEnded}
        onClose={onChangeSession}
        actions={
          <div>
            <Button color="primary" onClick={onChangeSession}>
              {_t('Close')}
            </Button>
          </div>
        }
      >
        {entity ? entity.broadcastStopExercise : null}
      </UpdateConfirmationDialog>
    );
}


export default withAnnotations({
  types: 'Entity',
    mapAnnotationsToProps: (annotations) => ({
      entity: Object.values(annotations).filter(annotation => annotation.tags.includes("CRIMSON_BROADCAST"))[0]
    })
})(StopSessionIndicator);
