import { Typography, Button, Switch, Toolbar, Menu, MenuItem, useMediaQuery, Checkbox, ListItemText } from '@mui/material';
import { React, useState, useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'utils';
import {
  Groups as GroupsIcon,
  Notifications as NotificationsIcon,
  Summarize as SummarizeIcon,
  Undo as UndoIcon,
  Share as ShareIcon,
  Check as CheckIcon
} from '@mui/icons-material';
import { KeycloakManager } from 'services';
import { useTheme } from '@mui/styles';
import CustomIconButton from './Buttons/CustomIconButton.js';
import { NotificationContext } from 'services/NotificationManager';
import i18n, { _t } from 'utils/i18n.js';
import { withNetworkManager } from 'NetworkManagerContext/NetworkManagerContext.js';
import { withCop } from 'CopContext/CopContext.js';
import UpdateConfirmationDialog from 'Plugins/IncidentPlugin/UpdateConfirmationDialog.js';

function toNowDateTimeString() {
  const date = new Date();
  return {
    time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    date: date.toLocaleDateString(i18n.language, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  };
}

function DateTime() {
  const [dateTime, setDateTime] = useState(toNowDateTimeString);

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(toNowDateTimeString());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ textAlign: 'center', margin: 'auto 0' }}>
      <Typography variant="h5">{dateTime.time}</Typography>
      <Typography variant="subtitle2">{dateTime.date}</Typography>
    </div>
  );
}

function TopBar(props) {
  const { notifications, setNotifications } = useContext(NotificationContext);
  const { notificationVisible, setNotificationVisible, toggleAutoShare, autoShare, setGroupsVisibility, groupsVisibility, deleteCommandQueue, commandQueue, manualShare } = props;
  const theme = useTheme();
  const [groupVisible, setGroupVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  useEffect(() => {
    if (!isDesktop) {
      toggleAutoShare(false)
    }
  }, [isDesktop, toggleAutoShare])

  const handleGroupsClick = (e) => {
    setAnchorEl(e.currentTarget);
    setGroupVisible(!groupVisible);
  };

  const closeConfirmationDialog = () => {
    setConfirmationOpen(false)
  };

  const handleGroupSelection = (group) => {
    if (groupsVisibility.includes(group)) {
      setGroupsVisibility(groupsVisibility.filter((el) => el !== group));
    } else {
      setGroupsVisibility([group,...groupsVisibility]);
    }
    
  }

  return (
    <>
      <Toolbar
        style={{
          gap: '10px',
          padding: '0px 10px 0px 0px',
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText
        }}
      >
        <img width="80px" src="img/crimson_avatar.png" alt="" />
        <DateTime />
        <CustomIconButton
          icon={<GroupsIcon />}
          text={_t('Groups')}
          aria-controls={groupVisible ? 'group-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={groupVisible ? 'true' : undefined}
          onClick={(e) => handleGroupsClick(e)}
        />
        <Menu 
          id="group-menu"
          anchorEl={anchorEl} 
          open={groupVisible} 
          onClose={() => setGroupVisible(false)} 
          sx={{ width: '100%' }}
        >
          {KeycloakManager.getUserGroups().map((group) => (
            <MenuItem 
              sx={{paddingLeft: 1}}
              key={group} 
              value={group} 
              onClick={() => handleGroupSelection(group)}
              selected={groupsVisibility.indexOf(group) > -1}
            >
              <CheckIcon 
                fontSize='small'
                style={{
                  paddingRight: 10,
                  visibility: groupsVisibility.indexOf(group) > -1 ? 'visible' : 'hidden'
                }}
              />
              <ListItemText primary={group} />
            </MenuItem>
          ))}
        </Menu>
        <div style={{ flexGrow: 1 }} />
        {!autoShare && <CustomIconButton disabled={commandQueue.length === 0} icon={<UndoIcon />} text={_t('Undo')} onClick={() => setConfirmationOpen(true)} />}
        {!autoShare && <CustomIconButton disabled={commandQueue.length === 0} icon={<ShareIcon />} text={`${_t('Share')} (${commandQueue.length})`} onClick={() => manualShare()} />}
        <Typography>{_t("Auto-share")}</Typography>
        <Switch 
          checked={autoShare}
          onClick={() => {
            toggleAutoShare(autoShare)
          }}
        >
          Auto-partage
        </Switch>
        <CustomIconButton icon={<SummarizeIcon />} text={_t('Overview')} />
        <CustomIconButton
          icon={<NotificationsIcon />}
          badgeCount={notifications.length}
          onClick={() => setNotificationVisible(!notificationVisible)}
        />
      </Toolbar>
      <UpdateConfirmationDialog
        open={confirmationOpen}
        onClose={(closeConfirmationDialog)}
        actions={
          <div>
            <Button color="primary" onClick={() => {
              deleteCommandQueue();
              closeConfirmationDialog();
            }}>
              {_t('Yes')}
            </Button>
            <Button color="primary" onClick={closeConfirmationDialog}>
              {_t('No')}
            </Button>
          </div> 
        }
      >
        {_t("Unshared modifications")}
      </UpdateConfirmationDialog>
    </>
    

  );
}

export default compose(withCop, withNetworkManager, withTranslation('common'))(TopBar);
