import React from 'react';
import { withStyles } from '@mui/styles';
import {
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Switch,
  TextField,
  Grid,
} from '@mui/material';
import GeoLocationManager from 'services/GeoLocationManager';
import { compose } from 'utils';
import i18n, { _t } from 'utils/i18n';
import { withMapManager } from 'MapManagerContext';
import ViewLayout from 'components/ViewLayout/ViewLayout';
import { withAppConfig } from 'AppConfig';
import { SettingsManager } from 'services';
import { withCop } from 'CopContext';

const styles = theme => ({
  settingsContainer: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText,
    minHeight: '100%',
    height: 'fit-content'
  },
  select: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText,
  },
  secondaryText: {
    color: theme.palette.tertiary.contrastText
  },
  selectContainer: {
    marginLeft: 15,
    color: theme.palette.tertiary.contrastText,
    borderColor: theme.palette.tertiary.contrastText
  },
  icon: {
    fill: theme.palette.tertiary.contrastText,
  },
  selectMenuItem: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText,
    "&:hover, &:hover:focus": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    },
    "&:focus": {
      backgroundColor: "#000"
    },
  },
  radioContainer: {
    marginLeft: 0
  },
  textField: {
    margin: '1em',
    width: '20em'
  },
  textFieldInput: {
    color: theme.palette.tertiary.contrastText,
    borderColor: theme.palette.tertiary.contrastText
  }
});

// Disable DFCI for the moment because we are unable to compute in VGeoWeb
const positionType = ["Decimal", "Sexagesimal", "UTMHemisphere", "UTMLatBand", "MGRS", "GeoRef", "GARS"/*,"DFCI"*/, "SexagesimalMinute"];
const measureUnit = ["Metric", "Imperial", "Nautical"];

class SettingsView extends React.Component {
  constructor() {
    super();

    /**
     *  The identifier for watching location
     */
    this.watchLocationId = -1;

    /**
     * The state for settings
     * @type {Object}
     */
    this.state = {
      geoPosition: '',
      accuracy: '',
      language: i18n.language.slice(0, 2),
      // backgroundLocation: false,
      // backgroundTrackingInterval: GeoLocationManager.backgroundTrackingInterval,
      nbBackgroundLocationUpdates: GeoLocationManager.nbBackgroundUpdates,
      nbForegroundLocationUpdates: GeoLocationManager.nbForegroundUpdates,
      teamLocationActivityInterval: GeoLocationManager.geoPosRefreshInterval / 1000 // Convert from ms to s
    };
  }

  componentDidMount() {
    // this.setState({ backgroundTrackingInterval: GeoLocationManager.backgroundTrackingInterval });

    this.watchLocationId = GeoLocationManager.watchLocation(
      (latitude, longitude, accuracy) => {
        this.setState({
          nbBackgroundLocationUpdates: GeoLocationManager.nbBackgroundUpdates,
          nbForegroundLocationUpdates: GeoLocationManager.nbForegroundUpdates,
          geoPosition: `${longitude.toFixed(6)} ${latitude.toFixed(6)}`,
          accuracy: Math.round(accuracy)
        });
      },
      { background: true }
    );

    GeoLocationManager.getBackgroundStatus((status) => this.setState({ backgroundLocation: status.isRunning }));
  }

  componentWillUnmount() {
    GeoLocationManager.clearWatch(this.watchLocationId);
  }

  /**
   * Change the language
   */
  changeLanguage = (event) => {
    const { value } = event.target;

    i18n.changeLanguage(value === 'fr' ? 'fr-FR' : value);
    this.setState({
      language: value
    });
  };

  changePositionUnit = (event) => {
    this.props.MapManager.setPositionUnit(event?.target?.value);
  };

  changePositionAccuracy = (event) => {
    this.props.MapManager.setPositionAccuracy(event?.target?.value);
  };

  changeMeasureUnit = (event) => {
    this.props.MapManager.setMeasureUnit(event?.target?.value);
  }

  handleTeamLocationActivityInterval = (event) => {
    const { value } = event.target;

    this.setState({ teamLocationActivityInterval: value });

    if (value >= 1 && value < 10e3) {
      // Convert from seconds to ms
      GeoLocationManager.geoPosRefreshInterval = value * 1000;
      console.info('Info: updated GeoLocationManager.geoPosRefreshInterval to', GeoLocationManager.geoPosRefreshInterval);
    }
  };

  // handleBackgroundTrackingIntervalChange = (event) => {
  //   const { value } = event.target;
  //   this.setState({ backgroundTrackingInterval: value });

  //   if (value > 0) {
  //     GeoLocationManager.backgroundTrackingInterval = value;
  //   } else {
  //     GeoLocationManager.backgroundTrackingInterval = 30;
  //   }
  // };

  // changeBackgroundLocation = (event) => {
  //   const { checked } = event.target;
  //   this.setState({ backgroundLocation: checked });
  //   if (checked) {
  //     GeoLocationManager.startBackground((status) => this.setState({ backgroundLocation: status.isRunning }));
  //   } else {
  //     GeoLocationManager.stopBackground();
  //   }
  // };

  render() {
    const { classes, appConfig, online } = this.props;

    return (
      <ViewLayout title={_t('Settings')} className={classes.settingsContainer}>
        <div style={{width: {xs: '100%', lg: '50%'}}}>
        <List>
          <ListSubheader className={classes.settingsContainer}>{_t('Language')}</ListSubheader> 
          <Select 
            name="language"
            className={classes.selectContainer}
            value={this.state.language}
            onChange={this.changeLanguage}
            disabled={!online}
            inputProps={{
              classes: {
                  icon: classes.icon
              },
          }}
          >
          <MenuItem value="fr" >{_t('languages.fr')}</MenuItem>
          <MenuItem value="en" >{_t('languages.en')}</MenuItem>
          {/* Other languages added in the configuration */}
          {SettingsManager.languages?.map((lng) => (
            <MenuItem key={lng} value={lng} >{_t('languages.' + lng)}</MenuItem>
          ))}
          </Select>
          {!online && (
            <ListItem>
              <ListItemText classes={{secondary: classes.secondaryText}} secondary={_t("Unavailable offline")} />
            </ListItem>
          )}
        </List>
        {/* <Divider /> */}
        {/* <List> */}
          {/* <ListSubheader className={classes.settingsContainer}>{_t('Location')}</ListSubheader>
          <ListItem>
            <ListItemText classes={{secondary: classes.secondaryText}} primary={_t('Position')} secondary={this.state.geoPosition} />
          </ListItem>
          <ListItem>
            <ListItemText classes={{secondary: classes.secondaryText}} primary={`${_t('Accuracy')} (m)`} secondary={this.state.accuracy} />
          </ListItem> */}

          {/* On any GPS location change dectected, which is the minimum interval between requests to update team activity date */}
          {/* <TextField
            label={_t('TeamGPSUpdateDate')}
            type="number"
            variant="filled"
            required
            inputProps={{
              min: 1,
              max: 10e3
            }}
            className={classes.textField}
            value={this.state.teamLocationActivityInterval}
            onChange={this.handleTeamLocationActivityInterval}
            margin="normal"
            error={this.state.teamLocationActivityInterval < 1 || this.state.teamLocationActivityInterval > 10e3}
            InputProps={{
              endAdornment: <InputAdornment position="end" disableTypography sx={{color: (theme) => theme.palette.tertiary.contrastText}}>{_t('Seconds')}</InputAdornment>,
              className: classes.textFieldInput
            }}
            InputLabelProps={{
              className: classes.textFieldInput
            }}
          />

          {/* Deprecated */}
          {/* <ListItem>
            <ListItemText primary={_t('Background Tracking')} />
            <ListItemSecondaryAction>
              <Switch
                onChange={this.changeBackgroundLocation}
                checked={this.state.backgroundLocation}
                color="primary"
              />
            </ListItemSecondaryAction>
          </ListItem> */}
          {/* <TextField
            id="standard-name"
            label={`${_t('Tracking Interval')} (s)`}
            type="number"
            variant="outlined"
            className={classes.textField}
            value={this.state.backgroundTrackingInterval}
            onChange={this.handleBackgroundTrackingIntervalChange}
            disabled={this.state.backgroundLocation}
            margin="normal"
          /> */}
          {/* <ListItem>
            <ListItemText
              classes={{secondary: classes.secondaryText}}
              primary={_t('Location Updates')}
              secondary={`${this.state.nbForegroundLocationUpdates} / ${this.state.nbBackgroundLocationUpdates}`}
            />
          </ListItem> */}
        {/* </List> */}
        {/* <Divider /> */}
        {/*
         * Hide "Map" settings for now
         */}
        {/* <List sx={{width: {xs: '100%', lg: '400px'}}}>
          <ListSubheader className={classes.settingsContainer}>{_t('Map')}</ListSubheader>
          <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.select} id="position-unit-select-label">{_t('PositionUnit')}</InputLabel>
                    <Select
                      value={this.props.MapManager.getPositionUnit().toLowerCase()}
                      onChange={this.changePositionUnit}
                      className={classes.select}
                      MenuProps={{classes:  { paper: classes.select}}}
                    >
                      {positionType.map(unit => 
                        <MenuItem key={unit} className={classes.selectMenuItem} value={unit.toLowerCase()}>{_t(unit)}</MenuItem>  
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel className={classes.select} id="position-acc-select-label">{_t('PositionAccuracy')}</InputLabel>
                    <Select
                      value={this.props.MapManager.getPositionAccuracy()}
                      onChange={this.changePositionAccuracy}
                      className={classes.select}
                      MenuProps={{classes:  { paper: classes.select}}}
                    >
                      {[0,1,2,3,4,5,6].map(acc => 
                        <MenuItem key={acc} className={classes.selectMenuItem} value={acc}>{acc}</MenuItem>  
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
          </ListItem>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel className={classes.select} id="measure-unit-select-label">{_t('MeasureUnit')}</InputLabel>
              <Select
                value={this.props.MapManager.getMeasureUnit().toLowerCase()}
                onChange={this.changeMeasureUnit}
                className={classes.select}
                MenuProps={{classes:  { paper: classes.select}}}
              >
                {measureUnit.map(unit => 
                  <MenuItem key={unit} className={classes.selectMenuItem} value={unit.toLowerCase()}>{_t(unit)}</MenuItem>  
                )}
              </Select>
            </FormControl>
          </ListItem>
          {/*<ListItem>
            <ListItemText primary="3D" />
            <ListItemSecondaryAction>
              <Switch
                onChange={this.props.MapManager.toggleUse3D}
                checked={this.props.MapManager.use3D}
                color="primary"
              />
            </ListItemSecondaryAction>
          </ListItem>*/}
        {/* </List> */}
        <Divider />
        {/* <List sx={{width: {xs: '100%', lg: '400px'}}}>
          <ListSubheader className={classes.settingsContainer}>{_t('Background Mode')}</ListSubheader>
          <ListItem>
            <ListItemText primary="Activation" />
            <ListItemSecondaryAction>
              <Switch
                onChange={this.props.onBackgroundModeChanged}
                checked={this.props.backgroundModeEnabled}
                color="primary"
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List> */}
        {appConfig.settingItems}
      </div>
    </ViewLayout>
      
    );
  }
}

export default compose(withStyles(styles), withMapManager, withAppConfig, withCop)(SettingsView);
