import React from 'react';
import { SvgIcon } from '@mui/material';

const MeasureLineIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 100 100">
            <path
                d="M34.266 0C28.07 0 22.982 5.09 22.982 11.285c0 3.494 1.621 6.634 4.145 8.71L18.021 38.81a11.081 11.081 0 00-1.736-.149C10.091 38.662 5 43.751 5 49.945 5 56.14 10.09 61.23 16.285 61.23c5.98 0 10.883-4.755 11.219-10.657a3.147 3.147 0 00.062-.627 3.147 3.147 0 00-.062-.627c-.176-3.093-1.616-5.862-3.799-7.806l9.211-19.033c.444.053.892.087 1.35.087 4.148 0 7.775-2.287 9.722-5.658l16.01 4.23c.84 5.256 5.35 9.337 10.791 9.503l6.299 19.2c-2.81 2.06-4.654 5.375-4.654 9.096 0 6.195 5.088 11.282 11.283 11.282 5.98 0 10.885-4.753 11.22-10.655a3.147 3.147 0 00.063-.627 3.147 3.147 0 00-.063-.626c-.335-5.902-5.24-10.659-11.22-10.659-.24 0-.475.02-.711.035L76.889 29.04c3.102-1.88 5.243-5.212 5.46-9.035a3.147 3.147 0 00.063-.627 3.147 3.147 0 00-.062-.627c-.336-5.902-5.241-10.658-11.221-10.658-4.585 0-8.558 2.792-10.317 6.754L45.51 10.799a3.147 3.147 0 00-.024-.14C45.151 4.756 40.246 0 34.266 0zm0 6.293c2.793 0 4.99 2.199 4.99 4.992s-2.197 4.988-4.99 4.988c-2.794 0-4.99-2.195-4.99-4.988 0-2.793 2.196-4.992 4.99-4.992zm36.863 8.092c2.793 0 4.99 2.199 4.99 4.992s-2.197 4.988-4.99 4.988-4.99-2.195-4.99-4.988c0-2.793 2.197-4.992 4.99-4.992zm-54.844 30.57c2.793 0 4.988 2.197 4.988 4.99s-2.195 4.99-4.988 4.99c-2.793 0-4.992-2.197-4.992-4.99s2.199-4.99 4.992-4.99zm67.432 8.99c2.793 0 4.99 2.2 4.99 4.992 0 2.794-2.197 4.989-4.99 4.989s-4.99-2.195-4.99-4.989c0-2.793 2.197-4.992 4.99-4.992zM5 76v24H95V76h-6.27v17.896h-7.687V81.174h-6.27v12.722h-7.681V81.174h-6.268v12.722h-7.69V76h-6.269v17.896h-7.69V81.174h-6.267v12.722h-7.681V81.174h-6.27v12.722H11.27V76H5z"
                overflow="visible" fillRule="evenodd" />
        </SvgIcon>
    );
};

export default MeasureLineIcon;
