import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { compose } from 'utils';
import { withAppConfig } from 'AppConfig';
import { withAnnotations } from 'CopContext';
import Map from 'components/Map';
import AboutDialog from 'components/AboutDialog';
import DrawerMenu from 'components/DrawerMenu/DrawerMenu';
import Navigation from 'components/Navigation/Navigation';
import OfflineIndicator from 'components/OfflineIndicator/OfflineIndicator';
import StopSessionIndicator from 'components/StopSessionIndicator/StopSessionIndicator';
import TopBar from 'components/TopBar';
import { SettingsManager } from 'services';
import Notifications from 'components/Notifications/Notifications';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%'
  },
  content: {
    height: '100%',

    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.sideNavigation.width,
      height: SettingsManager.topBar ? `calc(100% - ${theme.topBar.height})` : '100%'
    }
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  '@keyframes flash': {
    '0%': {
      opacity: 1
    },
    '60%': {
      opacity: 1
    },
    '100%': {
      opacity: 0
    }
  },
  topbar: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    },
    height: theme.topBar.height,
    width: '100%'
  },
  notificationBar: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  }
});

/**
 * Main view.
 */
class Main extends React.PureComponent {
  state = {
    aboutDialogOpen: false,
    successiveClicks: 0,
    drawerOpen: false,
    navigationVisible: true
  };

  handleSettingsPageDisplayRequest = () => {
    this.setState({
      aboutDialogOpen: false
    });
    this.props.history.push('server-settings');
  };

  componentDidUpdate(_, prevState) {
    // Secret menu ??
    if (prevState.successiveClicks < this.state.successiveClicks) {
      clearTimeout(this.resetClicksTimeout);
      this.resetClicksTimeout = setTimeout(() => {
        this.setState({ successiveClicks: 0 });
      }, 3000);
      if (this.state.successiveClicks === 7) {
        this.setState({ aboutDialogOpen: false });
        this.props.setWidgetStatus({ open: false, callback: null });
        this.handleSettingsPageDisplayRequest();
      }
    }
  }

  backFunction = () => {
    this.props.history.goBack();
  };

  toggleAboutDialog = () => {
    this.setState(
      (state) => ({ aboutDialogOpen: !state.aboutDialogOpen }),
      () => {
        this.props.setWidgetStatus({
          open: this.state.aboutDialogOpen,
          callback: this.state.aboutDialogOpen ? this.externallyCloseAboutDialog : null
        });
      }
    );
  };

  externallyCloseAboutDialog = () => {
    this.setState({ aboutDialogOpen: false });
  };

  handleLogoClick = () => {
    this.setState((state) => ({
      successiveClicks: state.successiveClicks + 1
    }));
  };

  setNavigationVisible = (bool) => {
    this.setState({
      navigationVisible: bool
    });
  };

  /**
   * Render the main component.
   */
  render() {
    const { navigationVisible } = this.state;
    const {
      history,
      /*ready,*/ session,
      onLogout,
      onChangeSession,
      user,
      setWidgetStatus,
      classes,
      online,
      appConfig,
      notificationVisible,
      setNotificationVisible
    } = this.props;

    /**
     * Add routes for plugins
     */
    const pluginRoutes = appConfig.mainNavigationRoutes.map((params) => {
      const Component = params.component;
      return (
        <Route
          path={'/main' + params.path}
          key={params.path}
          render={(props) => (
            <div style={{ height: '100%', display: 'flex' }}>
              <div style={{ width: '100%' }}>
                <Component style={{}} {...props} setWidgetStatus={setWidgetStatus} />
              </div>
              {notificationVisible && (
                <div className={classes.notificationBar} style={{ width: 'fit-content' }}>
                  <Notifications
                    notificationVisible={notificationVisible}
                    setNotificationVisible={setNotificationVisible}
                  />
                </div>
              )}
            </div>
          )}
        />
      );
    });

    return (
      <div className={classes.container}>
        {navigationVisible === true && <Navigation onLogout={onLogout} />}
        {appConfig.managers.map((Component, index) => (
          <Component key={index} history={history} />
        ))}
        {SettingsManager.topBar && (
          <div className={classes.topbar}>
            <TopBar notificationVisible={notificationVisible} setNotificationVisible={setNotificationVisible} />
          </div>
        )}
        <div className={classes.content}>
          {/* Drawer will be hidden in Web View */}
          <div className={classes.drawer}>
            <DrawerMenu
              user={user}
              session={session}
              onLogout={onLogout}
              onChangeSession={onChangeSession}
              toggleAboutDialog={this.toggleAboutDialog}
              setWidgetStatus={setWidgetStatus}
            />
          </div>

          <Switch>
            {/* If page has been refreshed, redirect to last visited page */}
            <Route
              exact
              path="/main"
              render={() => <Redirect to={sessionStorage.getItem('persistentPage') || '/main/MCPTT'} />}
            />
            {/* <Route
              path="/main/map"
              render={() => (
                <div style={{ height: '100%', display: 'flex' }}>
                  <Map
                    user={user}
                    setWidgetStatus={setWidgetStatus}
                    online={online}
                    setNavigationVisible={this.setNavigationVisible}
                  />
                  {notificationVisible && (
                    <div className={classes.notificationBar} style={{ width: 'fit-content' }}>
                      <Notifications
                        notificationVisible={notificationVisible}
                        setNotificationVisible={setNotificationVisible}
                      />
                    </div>
                  )}
                </div>
              )}
            /> */}
            {pluginRoutes}
          </Switch>
        </div>
        <OfflineIndicator online={online} />
        <StopSessionIndicator onChangeSession={onChangeSession} />
        <AboutDialog
          open={this.state.aboutDialogOpen}
          handleClose={this.toggleAboutDialog}
          handleLogoClick={this.handleLogoClick}
        />
      </div>
    );
  }
}

Main.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired
  }).isRequired,
  session: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  onChangeSession: PropTypes.func.isRequired,
  user: PropTypes.node.isRequired
};

export default compose(
  withStyles(styles),
  withRouter,
  withAppConfig,
  withTranslation('common'),

)(Main);
