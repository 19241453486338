import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { SettingsManager } from 'services';

import { _t } from 'utils/i18n';

import appVersion from 'Resources/app-version.json';

const styles = {
  logo: {
    maxWidth: '100%'
  }
};

const AboutDialog = ({ open, handleClose, handleLogoClick, classes }) => {
  const { logo, contact, websiteUrl } = SettingsManager.project;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <div className={classes.logoContainer} onClick={handleLogoClick}>
          <img src={process.env.PUBLIC_URL + '/' + logo} alt="Crimson Logo" className={classes.logo} />
        </div>
        <List>
          <ListItem>
            <ListItemText
              primary={_t('Version')}
              secondary={`${appVersion.version} ${appVersion.release_date && `(${appVersion.release_date})`}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={_t('Contact')}
              secondary={
                <a href={`mailto:${contact}`} style={{ width: '100%', display: 'block' }}>
                  {contact}
                </a>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={_t('Website')}
              secondary={
                <a
                  href={websiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: '100%', display: 'block' }}
                >
                  {websiteUrl}
                </a>
              }
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {_t('Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AboutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withStyles(styles)(AboutDialog);
