/******************************************************************************
 *************************** COMPONENT DESCRIPTION ****************************
 ******************************************************************************/
/**
*
* Session selection window
*
*/


/******************************************************************************
 ******************************* IMPORT SECTION *******************************
 ******************************************************************************/

// React
import React, { Component } from 'react';

//Translate
import { withTranslation } from 'react-i18next';
import i18n, { _t } from '../utils/i18n';

/*import {
  getTokenFromLocalStorage,
  getUserFromLocalStorage,
  useAuth,
} from './AuthProvider';*/

// MUI
import {
  Box,
  Button,
  Card,
  CardActions,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Search as SearchIcon } from '@mui/icons-material';

// Crimson
import { cleanupFileUrl, strIncludesLoosely } from 'utils';
import { SettingsManager } from 'services';
import { withNetworkManager } from 'NetworkManagerContext';
import { KeycloakManager } from 'services';

// Others
import PropTypes from 'prop-types';


/******************************************************************************
 ******************************* STYLES SECTION *******************************
 ******************************************************************************/


const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    height: '100%'
  },

  img: {
    padding: 8,
    paddingTop: 30,
    width: 'calc((50vw + 50vh)/2)',
    maxWidth: '70%',
    margin: 'auto'
  },

  mainPaper: {
    height: '90%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    justifyContent: 'space-between',

    [theme.breakpoints.up('lg')]: {
      maxWidth: '90vh'
    },
    // Small and medium screens case. We use almost all the screen
    [theme.breakpoints.down('lg')]: {
      height: '96%',
    }
  },

  sessionsSelectionBox: {
    flexBasis: 'auto',
    height: 'calc(85% - 160px)',
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',

    [theme.breakpoints.down('md')]: {
      height: 'calc(90% - 130px)',
      width: '95%'
    }
  },

  // session name ellipsis
  sessionNameEllipsis:
  {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  // session name witdh
  sessionNameWidth:
  {
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    }
  },

  // Debrief label
  label: {
    color: theme.palette.grey[600]
  }
});


/******************************************************************************
 ****************************** COMPONENT SECTION *****************************
 ******************************************************************************/

class SessionSelection extends Component {
  /**
   * Default constructor.
   */
  constructor() {
    super();

    let getSelectedsession = null;
    try {
      getSelectedsession = JSON.parse(localStorage.getItem('session'));
    } catch (e) {
      //JSON is not okay
      console.log("last session not found")
    }

    /**
     * State of the session selection component.
     * @type {Object}
     */
    this.state = {
      sessions: [],
      selectedSession: getSelectedsession,
      error: false,
      search: "",
      sessionsFiltered: [],
      loading: true,
      debrief: false
    };
  }

  /**
  * Sort the session list by first modification date
  */
  sortSessions(session1, session2) {
    const sessionDate1 = session1.modificationDate;
    const sessionDate2 = session2.modificationDate;

    if (!sessionDate2) {
      // If at least the second session date is undefined, it is placed after the first one
      return -1;
    }
    else if (!sessionDate1) {
      // If only the first session date is undefined, it is placed after the second
      return 1;
    }

    // Sorting dates in reverse chronological order (newest first)
    if (sessionDate1 < sessionDate2) {
      return 1;
    }
    else if (sessionDate1 > sessionDate2) {
      return -1;
    }

    return 0;
  };

  /**
   * Retrieve the session list
   */
  fetchSessionList() {
    // Retrieve the session list
    this.props.NetworkManager.retrieveSessionList()
      .then((sessionList) => {
        const sessions = sessionList.map((e) => ({ title: e.title, name: e.Name, status: e.status, modificationDate: e.lastModificationDate ? e.lastModificationDate : e.createdTime, lastModifier: e.lastModifier }));

        // Sorting sessions in reverse chronological order (newest first)
        sessions.sort((session1, session2) => {
          return this.sortSessions(session1, session2);
        });

        this.setState({ sessions: sessions, sessionsFiltered: sessions, loading: false, selectSession: sessions[0] });

        // Add session to memory in case of refresh
        // And delete last visited page to go back to the main/map component 
        sessionStorage.setItem('session', JSON.stringify(sessions[0]))
        sessionStorage.removeItem('persistentPage')
        this.props.onSubmit(sessions[0]);
        
      })
      .catch((err) => {
        console.error(err);
        this.setState({ error: true });
      });
  }

  /**
   * Called when the component is mounted
   */
  componentDidMount() {
    if (this.props.user) {
      this.fetchSessionList();
    }
    document.addEventListener('keypress', this.onKeypress);
  }

  /**
   * Retrieves the session list to fill the combobox.
   * Retrieves the username if available (subscribes to an event otherwise) to fill the username value.
   */
  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.fetchSessionList();
    }
  }

  /**
   * Called when the component is unmounted
   */
  componentWillUnmount() {
    document.removeEventListener('keypress', this.onKeypress);
  }

  /**
   * To handle key press
   */
  onKeypress = (ev) => {
    if (ev.key === 'Enter') {
      this.handleTouchTap();
    }
  };

  /**
   * Handle the submit button click (call parent's `onSubmit()` method).
   */
  handleTouchTap = () => {
    if (this.state.selectedSession) {

      // Add session to memory in case of refresh
      // And delete last visited page to go back to the main/map component 
      sessionStorage.setItem('session', JSON.stringify(this.state.selectedSession))
      sessionStorage.removeItem('persistentPage')
      this.props.onSubmit(this.state.selectedSession);


    }
  };

  /**
   * Handle the session combobox value change. Update the localStorage session's value.
   * @param  {string} sessionSelectedName The session name
   */
  selectSession = (sessionSelectedName) => {
    this.state.sessions.map((session) => {
      if (session.name === sessionSelectedName) {
        // Session found in the list. We select it
        this.setState({ selectedSession: session });
        localStorage.setItem('session', JSON.stringify(session));
      }
    });
  }

  /**
   * Provide the session text to display. The session's title by default, or its name if the title doesn't exist
   */
  titleToDisplay(session) {
    let textToReturn = ""
    if (session.title) {
      textToReturn = session.title;
    }
    else if (session.name) {
      // If title not found, display the name
      textToReturn = session.name;
    }
    else {
      textToReturn = _t('Name not found');
    }
    return textToReturn;
  }

  /**
   * Update state when the searchText is modified
   * @param {string} newSearchText The new search text
   */
  onSearchTextChanged(newSearchText) {
    this.setState((state) => {
      const newFilteredSessions = state.sessions.filter((annotation) => strIncludesLoosely(annotation.title ? annotation.title : annotation.name, newSearchText));
      return {
        search: newSearchText,
        selectedSession: (state.selectedSession && !newFilteredSessions.some(item => state.selectedSession.name === item.name)) ? "" : state.selectedSession, // if the old selected session isn't display in new filtered sessions, then we deselect it
        sessionsFiltered: newFilteredSessions
      }
    });
  }

  /**
   * Render the component
   */
  render() {
    const { classes, t } = this.props;
    const { error } = this.state;

    // Display
    return (
      <div className={classes.container} >
        <Card p={1} className={classes.mainPaper} elevation={24}>

          {/* Logo */}
          <img
            src={
              SettingsManager.project.splash
                ? cleanupFileUrl(SettingsManager.project.splash)
                : 'img/crimson_logo.png'
            }
            alt="Application logo"
            className={classes.img}
          />

          {
            this.state.loading ?
              (
                <CircularProgress sx={{ margin: 'auto' }} />
              )
              :
              (
                error ? (
                  <FormHelperText error>
                    <b>{t("SessionSelectionError", { server: window.cordova ? SettingsManager.crimsonServer?.cordova : SettingsManager.crimsonServer?.proxy })}</b>
                  </FormHelperText>
                )
                  :
                  (

                    <Box className={classes.sessionsSelectionBox} >

                      {/* Search bar */}
                      <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                        <TextField
                          variant="outlined"
                          value={this.state.search}
                          onChange={(event) => this.onSearchTextChanged(event.target.value)}
                          placeholder={_t('search')}
                          InputProps={{
                            classes: {
                              underline: classes.underline,
                              root: classes.textFieldRoot
                            },
                            autoComplete: 'off',
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            )
                          }}
                          fullWidth
                        />

                        {/* debrief switch. the function isn't used for now, wee dont need to display stopped exercices actually. Will be reactivated later*/}
                        {/* <FormControlLabel
                        control={<Checkbox checked={this.state.debrief} size="medium" onChange={() => this.setState({debrief: !this.state.debrief})} color="primary" sx={{padding:'0px'}}/>}
                        label={_t('Debrief')}
                        labelPlacement={'top'}
                        classes={{
                          label: classes.label
                        }}
                        sx={{marginLeft:'20px'}}
                      /> */}
                      </Box>

                      {/* Sessions list */}
                      {
                        !this.state.sessionsFiltered.length ? (
                          <p>{_t('No event found')}</p>

                        ) : (
                          <List selected component="nav" aria-label="mailbox folders" sx={{ overflowY: 'auto' }}>
                            {this.state.sessionsFiltered.map((session, index) => (
                              (!this.state.debrief && session.status === 'running') || (this.state.debrief && session.status === 'stopped') ? (
                                <div key={session.name}>
                                  <ListItemButton selected={this.state.selectedSession && this.state.selectedSession.name ? session.name === this.state.selectedSession.name : false} onClick={() => this.selectSession(session.name)}>
                                    <ListItemText
                                      primary={this.titleToDisplay(session)}
                                      secondary={
                                        <>
                                          <span>{new Date(session.modificationDate).toLocaleString(i18n.language) === "Invalid Date" ? "" : _t('last update') + new Date(session.modificationDate).toLocaleString(i18n.language)}</span>
                                          <br />
                                          <span>{session.lastModifier ? _t('by') + session.lastModifier : ""}</span>
                                        </>
                                      }
                                      // The textOverflow ellipsis only works with classes, not with sx and className
                                      classes={{
                                        primary: classes.sessionNameEllipsis,
                                        secondary: classes.test
                                      }}
                                      // The width (%) only works with sx and className, not with classes
                                      className={classes.sessionNameWidth}
                                    />
                                  </ListItemButton>
                                  <Divider sx={{ width: '99%' }} />
                                </div>) : (null)
                            ))}
                          </List>
                        )}

                    </Box>
                  )
              )
          }

          {/* Submit & Back buttons */}
          <CardActions style={SettingsManager.showServerSettings ? { float: 'right' } : {}}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={!SettingsManager.showServerSettings}
              onClick={this.handleTouchTap}
              disabled={error || !this.state.selectedSession || !this.state.selectedSession.name}
            >
              <p className={classes.sessionNameEllipsis} > {(this.state.selectedSession && this.state.selectedSession.name) ? _t('Join') + ': ' + this.titleToDisplay(this.state.selectedSession) : _t('Join')}</p>
            </Button>
            {SettingsManager.showServerSettings && (
              <Button variant="outlined" onClick={this.props.onBack}>
                {_t('Back')}
              </Button>
            )}
          </CardActions>

        </Card>
      </div>
    );
  }
}

SessionSelection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func
};

SessionSelection.defaultProps = {
  onBack: () => { }
};

export default withTranslation('common')(withNetworkManager(withStyles(styles)(SessionSelection)));
