import { SettingsManager } from "services";
import i18n from 'utils/i18n';

/**
 * Singleton to load languages after i18n init
 */
class LanguageSettings {

  constructor() {
    this.loaded = false;
  }

  /**
  * Fetch language common.js
  */
  fetchLanguage = (languageFile) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open('GET', languageFile);
      xhr.send(null);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            try {
              const parsedBody = JSON.parse(xhr.response);
              resolve(parsedBody);
            } catch (err) {
              console.error(`Error: Cannot parse language file ${languageFile}`);
              reject(err);
            }
          } else {
            const err = new Error(`Cannot load ${languageFile}`);
            reject(err);
          }
        }
      };
    });
  };

  /**
  * Load languages files once and add them to i18n
  */
    async load() {
      if (!this.loaded) {
        this.languages ={};
        try {
          await SettingsManager?.languages?.forEach(async (lng) => {
            const language = await this.fetchLanguage(`${process.env.PUBLIC_URL}/locales/${lng}/common.json`);
            i18n.addResourceBundle(lng, 'common', language)
          })
          this.loaded = true;
        } catch (err) {
          console.error(err.message);
          throw err;
        }
      }
    }
  }
  
export default new LanguageSettings();