import { SettingsManager } from 'services';

class ServerSettings {
  init() {
    const keycloakSettings = SettingsManager.keycloak;
    const crimsonSettings = SettingsManager.crimsonServer;

    this._cordova = Boolean(window.cordova);

    // Refresh token interval in seconds
    this.refreshTokenInterval = keycloakSettings.refreshTokenInterval || 10;

    this._keycloakUrl = this._cordova ? keycloakSettings.url.cordova : keycloakSettings.url.default;
    this._keycloakRealm = keycloakSettings.realm;
    this._keycloakClientId = keycloakSettings.clientId;
    this._crimsonUrl = this._cordova ? crimsonSettings.cordova : crimsonSettings.default;

    if (SettingsManager.showServerSettings) {
      if (localStorage.keycloakUrl) {
        this.keycloakUrl = localStorage.keycloakUrl;
      }

      if (localStorage.keycloakRealm) {
        this.keycloakRealm = localStorage.keycloakRealm;
      }

      if (localStorage.keycloakClientId) {
        this.keycloakClientId = localStorage.keycloakClientId;
      }

      if (localStorage.crimsonUrl) {
        this.crimsonUrl = localStorage.crimsonUrl;
      }
    }
  }

  get keycloakUrl() {
    return this._keycloakUrl.replace(/\/$/, '');
  }

  set keycloakUrl(url) {
    this._keycloakUrl = url;
    localStorage.keycloakUrl = url;
  }

  get keycloakRealm() {
    return this._keycloakRealm;
  }

  set keycloakRealm(realm) {
    this._keycloakRealm = realm;
    localStorage.keycloakRealm = realm;
  }

  get keycloakClientId() {
    return this._keycloakClientId;
  }

  set keycloakClientId(clientId) {
    this._keycloakClientId = clientId;
    localStorage.keycloakClientId = clientId;
  }

  get crimsonUrl() {
    return this._crimsonUrl.replace(/\/$/, '');
  }

  set crimsonUrl(url) {
    this._crimsonUrl = url;
    localStorage.crimsonUrl = url;
  }
}

export default new ServerSettings();
