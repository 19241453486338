import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Fab } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import MainDrawer from 'components/MainDrawer';
import { withMapManager } from 'MapManagerContext';
import atomDrawerObject from './DrawerAtom';
import { useAtom } from 'jotai';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    zIndex: 200
  },
  menuButton: {
    width: 60,
    height: 60,
    borderRadius: '0 0 100px 0'
  },
  menuIcon: {
    height: 30,
    width: 30,
    marginRight: 10,
    marginBottom: 10
  }
}));

const DrawerMenu = ({ user, session, MapManager, onChangeSession, onLogout, setWidgetStatus, toggleAboutDialog }) => {
  const classes = useStyles();
  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useAtom(atomDrawerObject);

  return (
    <>
      {/* Only display the Fab when map is ready */}
      {MapManager.mapReady && (
        <div className={classes.root}>
          {/* Only display When map route is active */}
          {history.location.pathname === '/main/map' && (
            <Fab onClick={() => setDrawerOpen(!drawerOpen)} color="primary" className={classes.menuButton} sx={{display: {xs: 'block', lg: 'none'}}}>
              <MenuIcon className={classes.menuIcon} />
            </Fab>
          )}
        </div>
      )}
        <div className={classes.root}>
          <MainDrawer
            user={user}
            session={session}
            open={drawerOpen}
            onOpen={() => setDrawerOpen(true)}
            onClose={() => setDrawerOpen(false)}
            setDrawerOpen={setDrawerOpen}
            setWidgetStatus={setWidgetStatus}
            onChangeSession={onChangeSession}
            onLogout={onLogout}
            toggleAboutDialog={toggleAboutDialog}
          />
        </div>
      
    </>
  );
};

export default withMapManager(DrawerMenu);
