import {React, useState,useCallback} from 'react';
import { makeStyles } from '@mui/styles';
import { Fab, Dialog, } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import GeocodingView from 'components/Geocoding/GeocodingView/GeocodingView';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    zIndex: 200,
    margin: 5,
    top: 0,
    right: 0
  },
  dialogContainer: {
    alignItems: 'normal',
    height: 'fit-content'
  },
  dialogPaper: {
    width: '90%'
  }
});

// On click navigate to overlay route
const GeocodingFab = ({ disabled }) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback( () => {
    setOpen(false);
  }, [setOpen]);
  
  const handleOpen = useCallback( () => {
    setOpen(true);
  }, [setOpen]);

  return (<>
    <Fab
      color="primary"
      size="medium"
      className={classes.root}
      disabled={disabled}
      onClick={handleOpen}
    >
      <SearchIcon />
    </Fab>
    <Dialog classes={{container:classes.dialogContainer,paper:classes.dialogPaper}} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <GeocodingView onClose={handleClose} />
  </Dialog>
  </>);
};

export default GeocodingFab;
