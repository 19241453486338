import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Main from 'components/Main';
import Photo from 'components/Photo';
import Audio from 'components/Audio';
import Login from 'components/Login';
import Loading from 'components/Loading';
import SessionSelection from 'components/SessionSelection';
import ServerSettingsComponent from 'components/ServerSettingsComponent';
import GeoPosCopElement from 'components/GeoPosCopElement';
import TeamSelection from 'components/TeamSelection';
import SettingsView from 'components/SettingsView';
import IncidentElement from 'Plugins/IncidentPlugin/IncidentElement';
import { CopProvider, CopContext } from 'CopContext';
import { MapManagerProvider } from 'MapManagerContext';
import { AppConfigContext } from 'AppConfig';
import { KeycloakManager, SettingsManager } from 'services';
import withSnackNotifications from '../CopContext/withSnackNotifications';
import { compose } from 'utils';
import { withStyles } from '@mui/styles';
import { withNetworkManager } from 'NetworkManagerContext';

/**
 * Component used to manage the initial routing of the application
 */
const InitialRouting = ({ teamRegistration }) => {
  const { ready, team } = React.useContext(CopContext);

  let from = '/';
  let to = '/';

  if (!ready) {
    to = '/loading';
  } // else if (!team && teamRegistration && KeycloakManager.userRoles.has('PR_CanHaveTeam')) {
  //   from = '/loading';
  //   to = '/team';
  // } 
  else {
    from = '/:foo(team|loading)';
    to = '/main';
  }
  return <Redirect from={from} to={to} />;
};

/**
 * Render the app, conditionnally rendering according to login state
 */

const Routes = ({
  user,
  session,
  loginError,
  waiting,
  selfStyledObjects,
  backgroundModeEnabled,
  teamRegistration,
  onLogin,
  clearLoginError,
  handleSessionSelected,
  endTeamRegistration,
  goBack,
  handleBackgroundModeChanged,
  handleLogout,
  hideServerSettings,
  handleChangeSession,
  setWidgetStatus,
  showServerSettings,
  widgetStatus,
  snackNotify,
  classes,
  NetworkManager
}) => {
  const [redirection, setRedirection] = React.useState('');
  const [PluginRoutes, setPluginRoutes] = React.useState(null);

  const [successiveBacks, setSuccessiveBacks] = React.useState(0);
  const [notificationVisible, setNotificationVisible] = React.useState(false);
  const resetBackTimeoutRef = React.useRef(null);

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('common');
  const appConfig = useContext(AppConfigContext);

  /**
   * Add routes for active plugins
   */
  const addPluginRoutes = React.useCallback(() => {
    const newPluginRoutes = appConfig.topLevelRoutes.map((pParams) => {
      const Component = pParams.component;
      return (
        <Route
          path={pParams.path}
          key={pParams.path}
          render={(props) => <Component {...props} setWidgetStatus={setWidgetStatus} />}
        />
      );
    });

    setPluginRoutes(newPluginRoutes);
  }, [setWidgetStatus, appConfig]);

  React.useEffect(() => {
    if (user && session) {
      addPluginRoutes();
      setRedirection('/loading');
    } else if (user) {
      const sortSessions = (session1, session2) => {
        const sessionDate1 = session1.modificationDate;
        const sessionDate2 = session2.modificationDate;
    
        if (!sessionDate2) {
          // If at least the second session date is undefined, it is placed after the first one
          return -1;
        }
        else if (!sessionDate1) {
          // If only the first session date is undefined, it is placed after the second
          return 1;
        }
    
        // Sorting dates in reverse chronological order (newest first)
        if (sessionDate1 < sessionDate2) {
          return 1;
        }
        else if (sessionDate1 > sessionDate2) {
          return -1;
        }
    
        return 0;
      };
      NetworkManager.retrieveSessionList()
        .then((sessionList) => {
          if (sessionList.length === 0) {
            setRedirection(`/session`)
          }
        const sessions = sessionList.map((e) => ({ title: e.title, name: e.Name, status: e.status, modificationDate: e.lastModificationDate ? e.lastModificationDate : e.createdTime, lastModifier: e.lastModifier }));

        // Sorting sessions in reverse chronological order (newest first)
        sessions.sort((session1, session2) => {
          return sortSessions(session1, session2);
        });
        // Add session to memory in case of refresh
        // And delete last visited page to go back to the main/map component 
        sessionStorage.setItem('session', JSON.stringify(sessions[0]))
        sessionStorage.removeItem('persistentPage')
        handleSessionSelected(sessions[0])
        addPluginRoutes();
        setRedirection('/loading')
        
        })
        .catch((err) => {
          console.error(err);
        })
    
    } else if (showServerSettings) {
      setRedirection('/server-settings');
    } else {
      if (SettingsManager?.keycloakLogin || waiting) {
        setRedirection('/loading');
      } else {
        setRedirection('/login');
      }
    }
  }, [addPluginRoutes, session, showServerSettings, user, waiting, NetworkManager]);

  /**
   * Handle "back" button press on phones
   * Moved from "App" because it doesn't have access to tolcation and history
   */
  // const handleBackButtonPress = React.useCallback(() => {
  //   if (widgetStatus.open) {
  //     if (typeof widgetStatus.callback === 'function') {
  //       widgetStatus.callback();
  //     }
  //     setWidgetStatus({ open: false, callback: null });
  //   } else if (location && (!session || location.pathname === '/main/map')) {
  //     setSuccessiveBacks((prevState) => prevState + 1);
  //   } else {
  //     if (history) {
  //       history.push('/main/map');
  //     } else {
  //       console.error('history is undefined.');
  //     }
  //   }
  // }, [history, location, session, setWidgetStatus, widgetStatus]);

  /**
   * On mount, add back button and refresh event listeners
   */
  // React.useEffect(() => {
  //   document.addEventListener('backbutton', handleBackButtonPress);
  //   window.onbeforeunload = () => {
  //     // Kepp the last visited page in memory when refreshing
  //     sessionStorage.setItem('persistentPage', location.pathname);
  //   };
  //   return function cleanup() {
  //     document.removeEventListener('backbutton', handleBackButtonPress);
  //   };
  // }, [handleBackButtonPress]);

  /**
   * Handle successive back button presses timeout
   */
  React.useEffect(() => {
    if (successiveBacks === 1) {
      const message = session ? t('Will exit on next tap') : t('Will go back to login on next tap');
      snackNotify(message, {
        priority: 100,
        duration: 3000,
        forceBottom: !session
      });
      resetBackTimeoutRef.current = setTimeout(() => {
        setSuccessiveBacks(0);
      }, 3000);
    } else if (successiveBacks === 2) {
      if (session) {
        navigator.app.exitApp();
      } else {
        (async function effect() {
          await handleLogout();
        })();
      }
    }

    return function cleanup() {
      if (resetBackTimeoutRef.current) {
        clearTimeout(resetBackTimeoutRef.current);
        resetBackTimeoutRef.current = null;
      }
    };
  }, [handleLogout, session, snackNotify, successiveBacks, t]);

  return (
    <>
      <Route path="/loading" component={Loading} />
      <Route path="/login">
        <Login onLogin={onLogin} loginError={loginError} eraseError={clearLoginError} />
      </Route>
      <Route path="/session">
        <SessionSelection user={user} onSubmit={handleSessionSelected} onBack={goBack} />
      </Route>
      <Route path="/server-settings">
        <ServerSettingsComponent
          login={() => {
            hideServerSettings();
            onLogin();
          }}
        />
      </Route>
      <Redirect to={redirection} />

      {user && session ? (
        <CopProvider session={session}>
          <MapManagerProvider selfStyledObjects={selfStyledObjects}>
            <GeoPosCopElement backgroundModeEnabled={backgroundModeEnabled} />
            <IncidentElement />
            {!sessionStorage.getItem('persistentPage') ? (
              <InitialRouting teamRegistration={teamRegistration} />
            ) : (
              <InitialRouting teamRegistration={false} />
            )}

            <Switch>
              <Route path="/settings">
                <SettingsView
                  backgroundModeEnabled={backgroundModeEnabled}
                  onBackgroundModeChanged={handleBackgroundModeChanged}
                />
              </Route>
              {/* <Route path="/photo" component={Photo} />
              <Route path="/audio" component={Audio} />
              <Route path="/team">
                <TeamSelection
                  user={user}
                  onFinish={() => {
                    endTeamRegistration();
                    sessionStorage.removeItem('persistentPage');
                    history.push('/main');
                  }}
                />
              </Route> */}

              {PluginRoutes}
              <Route path="/main">
                <div style={{ width: '100vw', maxHeight: '100vh', overflow: 'hidden' }}>
                  <Main
                    session={session}
                    user={user.name || user.preferred_username}
                    onLogout={handleLogout}
                    onChangeSession={handleChangeSession}
                    setWidgetStatus={setWidgetStatus}
                    notificationVisible={notificationVisible}
                    setNotificationVisible={setNotificationVisible}
                  />
                </div>
              </Route>
            </Switch>
          </MapManagerProvider>
        </CopProvider>
      ) : null}
    </>
  );
};

// TODO: props
Routes.propTypes = {};

export default compose(withNetworkManager,withSnackNotifications)(Routes);
