import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'utils/withRouter';
import Lightbox from 'react-image-lightbox';
import { SpeedDial, SpeedDialAction, SpeedDialIcon, useMediaQuery } from '@mui/material';
import { Alert } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close as CloseIcon, CameraAlt } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import { MapManagerContext } from "MapManagerContext";
import { withNetworkManager } from 'NetworkManagerContext';
import { withCop } from 'CopContext';
import { withAppConfig } from 'AppConfig';
import { compose } from 'utils';
import { _t } from 'utils/i18n';
import LocationButton from 'components/Map/LocationButton';
import LayerMenuButton from 'components/Map/LayerMenuButton';
import InformationSnack from 'components/Map/InformationSnack';
import TopLoader from 'components/TopLoader/TopLoader';
import GeocodingFab from 'components/Geocoding/GeocodingFab/GeocodingFab';
import 'VirtualGeoWeb/css/vgeo.css';
import { KeycloakManager, SettingsManager } from 'services';
import { withAnnotations } from 'CopContext';
import CompassZoomWidget from './Map/Compass';
import MeasureWidget from './Map/MeasureWidget';
import PositionBarWidget from './Map/PositionBar';

const MissionIndicator = React.lazy(() => import('Plugins/TeamPlugin/MissionIndicator'));
const SupportClosureIndicators = React.lazy(() => import('Plugins/TeamPlugin/SupportClosureIndicators'));
const MapTalkingUserIndicator = React.lazy(() =>
  import('Plugins/PushToTalkPlugin/MapTalkingUserIndicator/MapTalkingUserIndicator')
);

const styles = {
  dialog: {
    maxWidth: 400,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  speedDial: {
    position: 'absolute',
    bottom: 64,
    right: 0,
    zIndex: '100'
  },
  snackbar: {
    zIndex: 1000,
    marginTop: 70,
    marginRight: 55
  },
  speedDialFab: {
    margin: 5
  },
  speedDialTooltip: {
    width: 130
  },
  speedDialActions: {
    paddingBottom: '34px !important' // default is 48
  },
  videoPlayer: {
    // Fill screen
    zIndex: 2000,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: '100vh',
    width: '100%',
    backgroundColor: 'black'
  },
  videoPlayerCloseIcon: {
    color: 'white',
    position: 'absolute',
    zIndex: 2001,
    top: 8,
    right: 8
  }
};

/**
 * Map component
 */
function Map({
  NetworkManager, 
  activeMission, 
  appConfig, 
  classes, 
  currentUserTeamInfo, 
  geoMedias, 
  history, 
  online, 
  removeAnnotation,
  setNavigationVisible, 
  setWidgetStatus, 
  supportClosureStatus,
  user
}) {
  const [addDialogVisible, setAddDialogVisible] = useState(-1);
  const [pickedFeatures, setPickedFeatures] = useState(null);
  const [informationOpen, setInformationOpen] = useState(false);
  //const [menuAnchor, setMenuAnchor] = useState(menuAnchor);
  const [mediaURL, setMediaURL] = useState('');
  const [speedDialOpen, setSpeedDialOpen] = useState(false );
  const [snackbarOpen, setSnackbarOpen] = useState(false );
  const [snackbarMsg, setSnackbarMsg] = useState('Cliquez sur la carte pour ajouter un élément');
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState({
    open: false,
    MIMEType: ''
  });
  const [disableInformationSnack, setDisableInformationSnack] = useState(false);
  const [talkingUserIndicatorOpen, setTalkingUserIndicatorOpen] = useState(false);
  const [disableSpeedial, setDisableSpeedial] = useState(false);
  const [geometry, setGeometry] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);

  const snackbarType = useRef('info');
  const clickX = useRef(0);
  const clickY = useRef(0);
  const toolMode = useRef(false);

  const videoPlayerRef = React.createRef();

  const MapManager = useContext(MapManagerContext);

  const onmousedown = useCallback((e) => {
    clickX.current = e.clientX;
    clickY.current = e.clientY;
  }, []);

    /**
   * Called when features are picked
   */
  const onFeaturePicked = useCallback((features) => {
    setPickedFeatures(features);
    setInformationOpen(true);
    setWidgetStatus({
      open: true,
      callback: externallyCloseInformation
    });
  }, [setWidgetStatus]);

  const pickFeatures = useCallback((x, y) => {
    if (!toolMode.current && Math.abs(clickX.current - x) < 1 && Math.abs(clickY.current - y) < 1) {
      const rect = MapManager.getMap().getTarget().getBoundingClientRect();
      const features = MapManager.getMap().pickFeatures({ x: x - rect.left, y: y - rect.top });
      onFeaturePicked(features);
      return features.length > 0;
    }
    return false;
  }, [MapManager, onFeaturePicked]);
  
  const onmouseup = useCallback((e) => {
    if (e.button === 0) {
      pickFeatures(e.clientX, e.clientY);
    }
  }, [pickFeatures]);

  const ontouchstart = useCallback((e) => {
    if (e.touches.length === 1) {
      clickX.current = e.touches[0].clientX;
      clickY.current = e.touches[0].clientY;
    }
  }, [clickX, clickY]);

  const ontouchend = useCallback((e) => {
    if (e.touches.length === 0 && e.changedTouches.length === 1) {
      const t = e.changedTouches[0];
      if (pickFeatures(t.clientX, t.clientY)) {
        e.preventDefault();
      }
    }
  }, [pickFeatures]);

  const preventContextMenu = useCallback((event) => {
    event.preventDefault();
  }, []);

  const onVGeoLogMessage = useCallback((log) => {
    const prefix = 'VirtualGeoWeb:';

    switch (log.code) {
      case 'Information':
        console.info(prefix, log.message);
        break;
      case 'Low Warning':
        console.warn(prefix, log.message);
        break;
      case 'High Warning':
        console.warn(prefix, log.message);
        break;
      case 'Error':
        console.error(prefix, log.message);
        break;
      default:
        console.log(log);
        break;
    }
  }, []);

  const externallyCloseMedia = useCallback(() => {
    setLightboxOpen(false);
    setMediaURL('');
    setVideoPlayer({
      open: false,
      MIMEType: ''
    });
    setDisableInformationSnack(false);
    setNavigationVisible(true);
  }, [setNavigationVisible]);

  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 27) {
      externallyCloseMedia();
    }
  }, [externallyCloseMedia]);

  const setupEventListeners = useCallback(() => {
    const map = MapManager.getMap();
    const canvas = map.getCanvas();

    // In dev mode handle VGeoWeb event logs
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      map.addEventListener('error', onVGeoLogMessage);
    }

    canvas.addEventListener('mousedown', onmousedown, false);
    canvas.addEventListener('touchstart', ontouchstart, false);
    canvas.addEventListener('mouseup', onmouseup, false);
    canvas.addEventListener('touchend', ontouchend);

    document.addEventListener('contextmenu', preventContextMenu);
    document.addEventListener('keydown', handleKeyDown);
  }, [MapManager, onmousedown, ontouchstart, onmouseup, ontouchend, handleKeyDown, onVGeoLogMessage, preventContextMenu]);

  const removeEventListeners = useCallback(() => {
    if (MapManager.mapReady)
    {
      const map = MapManager.getMap();
      if (map) {
        const canvas = map.getCanvas();
  
        // Cancel subscriptions
        canvas.removeEventListener('mousedown', onmousedown, false);
        canvas.removeEventListener('mouseup', onmouseup, false);
        canvas.removeEventListener('touchstart', ontouchstart, false);
        canvas.removeEventListener('touchend', ontouchend, false);
        map.removeEventListener('error', onVGeoLogMessage);
      }
      document.removeEventListener('contextmenu', preventContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    }
    
  }, [MapManager, onmousedown, onmouseup,ontouchstart, ontouchend, onVGeoLogMessage, preventContextMenu, handleKeyDown]);

  /**
   * Initialize the VirtualGeo map
   */
  useEffect(() => {
    if (!MapManager.getMap() || !MapManager.mapReady) {
      MapManager.createMap(setupEventListeners);
    } else {
      MapManager.getMap().setTarget('map');
      setupEventListeners();
    }

    // MCOP plugin specific logic if enabled
    if (window.cordova && window.MCOP && 'MCOPClient' in window) {
      window.MCOPClient.onFloorControlIdle(() => {
        console.info('EVENT INFO: onFloorControlIdle');
        setTalkingUserIndicatorOpen(false);
      });

      window.MCOPClient.onFloorControlTaken(() => {
        console.info('EVENT INFO: onFloorControlTaken');
        setTalkingUserIndicatorOpen(true);
      });
    }
  }, [MapManager, setupEventListeners]);

  useEffect(() => {
    return () => {
      removeEventListeners();
    };
  }, [removeEventListeners]);

  useEffect(() => {
    return () => {
      if (MapManager.mapReady)
      {
        const map = MapManager.getMap();
        if (map) {
          // Clear the VirtualGeo map data
          map.setTarget(null);
        }
      }
    };
  }, [MapManager]);

  // This is a temporary fix to override geoMedias style
  // Waiting for a bug to be fixed in Vgeo
  useEffect(() => {
    geoMedias.forEach(async (geoMedia, index) => {
      const lStyle = await MapManager.getMap().getStyle(geoMedia.Uuid);
      if (lStyle.icon[0]) {
        lStyle.icon.depthOffset = -10 - index;
      }
      MapManager.getMap().updateStyle(lStyle);
    });
  }, [MapManager, geoMedias]);

  const onFinish = () => {
    setAddDialogVisible(-1);
    setDisableSpeedial(!disableSpeedial);
  };

  const handleInformationClose = () => {
    setInformationOpen(false);
    setWidgetStatus({
      open: false,
      callback: null
    });
  };

  const externallyCloseInformation = () => {
    setInformationOpen(false);
  };

  const openMedia = (blobRef, MIMEType) => {
    const mediaType = MIMEType.substring(0, 5).toLowerCase();
    if (mediaType === 'image') {
      openLightbox(blobRef);
    } else if (mediaType === 'video') {
      openVideoPlayer(blobRef, MIMEType);
    } else {
      if (window.cordova) {
        // Phone behavior
        window.cordova.InAppBrowser.open(blobRef, '_blank', 'location=no');
      } else {
        // Browser behavior
        window.open(blobRef);
      }
      setDisableInformationSnack(false);
    }
  };

  const openLightbox = (blobRef) => {
    setLightboxOpen(true);
    setMediaURL(blobRef);
    setInformationOpen(false);

    setWidgetStatus({
      open: true,
      callback: externallyCloseMedia
    });
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setMediaURL('');
    setDisableInformationSnack(false);

    // Handles back button press
    setWidgetStatus({
      open: false,
      callback: null
    });
  };

  const openVideoPlayer = useCallback((blobRef, MIMEType) => {
    setVideoPlayer({
      open: true,
      MIMEType
    });
    setInformationOpen(false);
    setMediaURL(blobRef);

    setWidgetStatus({
      open: true,
      callback: externallyCloseMedia
    });

    setNavigationVisible(false);
  }, [setNavigationVisible, externallyCloseMedia, setWidgetStatus]);

  const handleOpenSpeedDial = () => setSpeedDialOpen(true);

  const handleCloseSpeedDial = () => setSpeedDialOpen(false);

  const handleOpenSnackbar = () => setSnackbarOpen(true);

  const handleCloseSnackbar = () => setSnackbarOpen(false);

  const addMapElement = (event) => {
    setDisableSpeedial(!disableSpeedial);

    // Might work with simply 'currentTarget' in the future ?...
    const value = parseInt(event.currentTarget.querySelector('button').value);

    handleCloseSpeedDial();
    setWidgetStatus({ open: false, callback: null });

    toolMode.current = true;

    const { geometryType, toolType, message } = appConfig.mapAdders[value];

    setSnackbarMsg( _t(message));

    handleOpenSnackbar();

    document.addEventListener('clickaway', () => handleCloseSnackbar());
    document.addEventListener('contextmenu', () => handleCloseSnackbar());

    const fn = (geometry, additionalData) => {
      toolMode.current = false;
      handleCloseSnackbar();
      setGeometry(geometry);
      setAdditionalData(additionalData);
      setAddDialogVisible(value);

      document.removeEventListener('clickaway', () => handleCloseSnackbar());
      document.removeEventListener('contextmenu', () => handleCloseSnackbar());
    };

    // If the plugin has no geometry type, directly fire callback
    if (!geometryType) {
      fn();
      return;
    }

    if (toolType === 'measure') {
      MapManager.startMeasureTool(geometryType, fn);
    } else {
      MapManager.startDrawTool(geometryType, fn);
    }
  };

  const openAsset = (assetPath) => {
    setDisableInformationSnack(true);

    NetworkManager.getAsset(assetPath)
      .then((pAssetBlob) => {
        const blobPath = window.URL.createObjectURL(pAssetBlob);

        openMedia(blobPath, pAssetBlob.type);
      })
      .catch((e) => {
        console.log('error', e);
      });
    // TODO: revoke object URL
  };

  const handlePhotoReport = () => {
    history.push('/photo');
  };

  /**
   * Render the map container
   */
  

  // Map actions from plugins
  const AddMenuItems = appConfig.mapAdders.map((el, index) => {
    const isTeamPlugin = el.hasOwnProperty('plugin') && el.plugin === 'team';
    const isTeamLeaderAction = isTeamPlugin && el.leaderOnly === true;

    // If this is a team plugin action and the user is not in a team, hide it
    if (!currentUserTeamInfo.isInTeam && isTeamPlugin) {
      return null;

      // If this user is in a team and the action is only visible to leaders but he isn't the leader
    } else if (currentUserTeamInfo.isInTeam && isTeamLeaderAction && !currentUserTeamInfo.isLeader) {
      return null;
    } else {
      // Prevent some actions to be started simultaneously (to prevent indicators overlapping and such)
      const isMissionAction = el.hasOwnProperty('id') && el.id === 'missionAction';
      const isSupportClosureAction = el.hasOwnProperty('id') && el.id === 'supportClosureAction';
      const isMissionActive = activeMission === true;
      const isSupportClosureActive = supportClosureStatus && supportClosureStatus !== 'INACTIVE';

      const isActionDisabled =
        (isMissionAction || isSupportClosureAction) && (isMissionActive || isSupportClosureActive);

      return (
        <SpeedDialAction
          key={el.title}
          tooltipTitle={_t(el.title)}
          tooltipOpen
          onClick={!isActionDisabled ? addMapElement : () => null}
          icon={el.hasOwnProperty('icon') ? <el.icon /> : undefined}
          FabProps={{
            value: index,
            disabled: isActionDisabled
          }}
          classes={{ staticTooltipLabel: classes.speedDialTooltip }}
        />
      );
    }
  });

  // Plugin dialogs
  const AddDialogs = appConfig.mapAdders.map((value, index) => {
    const AddDialog = value.dialog;
    return (
      <AddDialog
        key={value.title}
        open={addDialogVisible === index}
        geometry={geometry}
        additionalData={additionalData}
        onFinish={onFinish}
        forceOpen={
          (geometry1) => {
            setAddDialogVisible(index); 
            setGeometry(geometry1);
          }
        }
      />
    );
  });

  // Plugin Map Components
  const AddMapComponents = appConfig.mapComponents.map((value, index) => {
    const AddMapComponent = value.component;
    return <AddMapComponent key={value.title} disable={value.online ? !online : null} />;
  });

  const displayMeasureTools = useMediaQuery("(min-width : 900px)");

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {MapManager.mapReady ? (
        <>
          {AddMapComponents}

          <GeocodingFab />
          <LayerMenuButton setWidgetStatus={setWidgetStatus} />
          <CompassZoomWidget />
          
          {
            // Only displayed in desktop mode (certainly can be done with a better method less arbitrary)
            displayMeasureTools && <MeasureWidget/>
          }

          {!SettingsManager?.map?.alwaysReadOnly && KeycloakManager.userRoles.has('UR_Cartography_RW') && (
            <SpeedDial
              ariaLabel="Toggle actions"
              className={classes.speedDial}
              classes={{ actions: classes.speedDialActions }}
              icon={<SpeedDialIcon />}
              onClose={handleCloseSpeedDial}
              onOpen={handleOpenSpeedDial}
              open={speedDialOpen}
              ///
              FabProps={{
                size: 'medium',
                className: classes.speedDialFab,
                // Disable when offline or when support closure is active
                disabled: supportClosureStatus === 'CONFIGURATION' || disableSpeedial
              }}
              transitionDuration={0} // disable transition
            >
              <SpeedDialAction
                icon={<CameraAlt />}
                tooltipTitle={_t('Report')}
                tooltipOpen
                classes={{ staticTooltipLabel: classes.speedDialTooltip }}
                onClick={handlePhotoReport}
              />

              {AddMenuItems.map((item) => item)}
            </SpeedDial>
          )}

          <LocationButton />

          <React.Suspense fallback={null}>
            <MissionIndicator />
            <SupportClosureIndicators />
            <MapTalkingUserIndicator open={talkingUserIndicatorOpen} />
          </React.Suspense>
        </>
      ) : null}

      <div
        id="map"
        style={{
          height: '100%',
          width: '100%',
          zIndex: 0,
          overflow: 'hidden'
        }}
      />

      {AddDialogs}

      <Snackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        classes={{ root: classes.snackbar }}
        sx={{ marginLeft: { xs: '0', lg: '80px' } }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarType.current}>
          {snackbarMsg}
        </Alert>
      </Snackbar>

      <InformationSnack
        user={user}
        features={pickedFeatures}
        open={informationOpen}
        handleClose={handleInformationClose}
        openAsset={openAsset}
        // Prevent user interaction when media is loading
        disabled={disableInformationSnack}
        removeAnnotation={removeAnnotation}
      />

      <PositionBarWidget/>

      {/* Only shown when asset is downloading */}
      <TopLoader loading={disableInformationSnack && !lightboxOpen && !videoPlayer.open} />

      {lightboxOpen && (
        <Lightbox
          mainSrc={mediaURL}
          onCloseRequest={closeLightbox}
          reactModalStyle={{
            overlay: {
              zIndex: 1501
            }
          }}
        />
      )}
      
      {videoPlayer.open && (
        <div>
          <video
            controls
            className={classes.videoPlayer}
            ref={videoPlayerRef}
            name="geomedia-video"
            src={mediaURL}
            controlsList="nodownload"
          ></video>
          <IconButton className={classes.videoPlayerCloseIcon} onClick={externallyCloseMedia} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

Map.propTypes = {
  team: PropTypes.object,
  teammates: PropTypes.array,
  currentUserTeamInfo: PropTypes.object.isRequired,
  activeMission: PropTypes.bool.isRequired,
  online: PropTypes.bool.isRequired,
  setWidgetStatus: PropTypes.func.isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  supportClosureStatus: PropTypes.string,
  NetworkManager: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withNetworkManager,
  withCop,
  withAppConfig,
  withStyles(styles),
  withAnnotations({
    mapAnnotationsToProps: (annotations) => ({
      geoMedias: Object.values(annotations).filter((ann) => ann.type === 'GeoMedia')
    })
  })
)(Map);
