import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HashRouter } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { withNetworkManager } from 'NetworkManagerContext';
import Routes from 'routes/Routes';
import { ActorManager, KeycloakManager, NotificationManager, SettingsManager, StyleFactory } from 'services';
import { AppConfig, AppConfigContext } from 'AppConfig';
import PluginUtils from 'Plugins/PluginUtils';
import { NotificationProvider } from 'services/NotificationManager';

const Router = HashRouter;

/**
 * The CRIMSON application component.
 * Initialize the application (plugin, style)
 * Display a page to select a session, then the main page
 *
 */
const App = ({ NetworkManager, muiTheme }) => {
  const [appConfig, setAppConfig] = React.useState(new AppConfig());
  const [session, setSession] = React.useState(SettingsManager?.defaultSession || '');
  const [showServerSettings, setShowServerSettings] = React.useState(SettingsManager.showServerSettings || false);
  const [teamRegistration, setTeamRegistration] = React.useState(SettingsManager.teamRegistration || false);
  // User should be empty is not logged in
  const [user, setUser] = React.useState('');
  const [loginError, setLoginError] = React.useState('');
  const [waiting, setWaiting] = React.useState(true);
  const [widgetStatus, setWidgetStatus] = React.useState({
    open: false,
    callback: null
  });
  const [backgroundModeEnabled, setBackgroundModeEnabled] = React.useState(
    SettingsManager.get('backgroundModeEnabled', false)
  );
  const [pluginsLoaded, setPluginsLoaded] = React.useState(false);
  const [isSessionSet, setIsSessionSet] = React.useState(false);
  // SBE: the StyleFactory was tightly coupled with the MapManager. So I had to move  this logic up here.
  const [selfStyledObjects, setSelfStyledObjects] = React.useState([]);

  const keepAliveIntervalRef = React.useRef(null);

  const { t } = useTranslation('common');

  const registerSelfStyledObject = React.useCallback((type) => {
    setSelfStyledObjects((prevSelfStyledObjects) => [...prevSelfStyledObjects, type]);
  }, []);

  /**
   * Handle logout
   */
  const handleLogout = React.useCallback(async () => {
    ActorManager.unregister();
    await NetworkManager.sendNotAliveReq();

    if (SettingsManager?.keycloakLogin) {
      console.info('KeycloakManager.logout()');

      try {
        await KeycloakManager.logout();
        // FIXME: why was login called here ?
        // login();
      } catch (err) {
        console.error(err);
      } finally {
        setSession(SettingsManager?.defaultSession || '');
        setUser('');
        setWaiting(false);
        runPluginsResetFunction();
      }
    } else {
      console.info('KeycloakManager.directLogout()');
      KeycloakManager.directLogout();
      setSession(SettingsManager?.defaultSession || '');
      setUser('');
      setWaiting(false);
      runPluginsResetFunction();
    }

    // Unregister from push to talk if relevant
    if (window?.MCOP && 'MCOPClient' in window) {
      window.MCOPClient.unRegister();
    }
  }, [NetworkManager]);

  /**
   * Login using keycloak
   */
  const login = React.useCallback(
    (user, password, rememberme) => {
      // Store the fact that we are currently logging in, for later App re-creation.
      localStorage.loginRequired = 'true';
      if (!localStorage.loginAttempt) {
        localStorage.loginAttempt = 0;
      }
      ++localStorage.loginAttempt;

      const lNumAttempts = window.cordova ? 2 : 3;
      if (SettingsManager.showServerSettings && localStorage.loginAttempt > lNumAttempts) {
        localStorage.loginRequired = 'false';
        localStorage.loginAttempt = 0;
        setShowServerSettings(true);
        setLoginError('');
        return;
      }

      const onSuccess = () => {
        // And set it back to false. We want the user to press the "connect" button.
        localStorage.loginRequired = 'false';
        localStorage.loginAttempt = 0;
        setUser(KeycloakManager.getUser());
        setWaiting(false);
        ActorManager.register();
        if (password) {
          localStorage.setItem("password", password);
        }

        //document.addEventListener('resume', () => KeycloakManager.mKeycloakAdapter.updateToken(-1));
        //document.addEventListener('pause', () => KeycloakManager.mKeycloakAdapter.updateToken(-1));
      };

      const onFailure = (e) => {
        localStorage.loginRequired = 'false';
        if (typeof user !== 'undefined') {
          if (e instanceof TypeError) {
            setLoginError(
              t('AuthAccessError', {
                server: window.cordova ? SettingsManager.keycloak?.url?.default : SettingsManager.keycloak?.url?.cordova
              })
            );
          } else {
            setLoginError(t('Invalid username or password'));
          }
        }
        setWaiting(false);
        setUser('');
      };

      const onSessionExpired = () => {
        ActorManager.unregister();
        setSession(SettingsManager?.defaultSession || '');
        setUser('');

        // Unregister from push to talk if relevant
        if (window?.MCOP && 'MCOPClient' in window) {
          window.MCOPClient.unregister();
        }
      };

      if (SettingsManager.keycloakLogin) {
        KeycloakManager.login({ onAuthError: handleLogout }).then(onSuccess).catch(onFailure);
      } else {
        KeycloakManager.directLogin({ user, password, rememberme, onSessionExpired }).then(onSuccess).catch(onFailure);
      }
    },
    [handleLogout, t]
  );

  /**
   * Init MCOP Client on login if Push-to-talk plugin is imported
   */
  const importMCPTTPlugin = async () => {
    // Import the init method only if needed
    await import('Plugins/PushToTalkPlugin/service/pushToTalkService').then((module) => {
      const PushToTalkService = module.default;
      console.info('PushToTalkService.init()');
      PushToTalkService.init()
        .then(() => {
          console.info('Push-to-talk initialized.');
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  /**
   * Initialize "plugins" (optional app features)
   */
  const initializePlugins = React.useCallback(async () => {
    let plugins = [];

    // Use app-settings plugins or default plugins if not specified
    if (SettingsManager?.plugins) {
      plugins = SettingsManager.plugins;
    } else {
      console.warn('Warning: "plugins" not specified in app-settings: using default plugins.');
      plugins = [
        'DevicePlugin',
        'IncidentPlugin',
        'PointPlugin',
        'SectorPlugin',
        'SurfacePlugin',
        'TeamPlugin',
        'LogPlugin'
      ];
    }

    if (process.env.NODE_ENV === 'development') {
      console.info('Importing plugins. Enabled plugins:', plugins);
    }

    try {
      // Imported enabled plugins dynamically
      const pluginModules = await Promise.all(
        plugins.map(async (pluginName) => {
          return await import(`Plugins/${pluginName}/${pluginName}`);
        })
      );

      // Call register plugin function
      PluginUtils.appConfig = new AppConfig();
      pluginModules.forEach((module) => {
        const registerPlugin = module.default;
        registerPlugin(registerSelfStyledObject);
      });
      setAppConfig(PluginUtils.appConfig);

      // Import MCPTT plugin if enabled
      if (plugins && plugins.length > 0 && plugins.includes('PushToTalkPlugin')) {
        await importMCPTTPlugin();
      }
      setPluginsLoaded(true);

    } catch (err) {
      console.error(err);
    }
  }, [registerSelfStyledObject]);

  /**
   * Run init functions from initialized plugins
   */
  const runPluginsInitFunction = () => {
    // Run all plugins initial functions
    PluginUtils.appConfig.initFunctions.forEach((init) => {
      const params = {
        session: session,
        setSession: setSession,
        NetworkManager: NetworkManager
      }
      init(params);
    })
  }

  /**
  * Run reset functions from reset plugins
  */
  const runPluginsResetFunction = () => {
    // Run all plugins initial functions
    PluginUtils.appConfig.resetFunctions.forEach((reset) => {
      const params = {
        session: session,
        setSession: setSession,
        NetworkManager: NetworkManager
      }
      reset(params);
    })
  }

  /*
   * On mount, register symbol rules
   */
  React.useEffect(() => {
    if (!SettingsManager.map && !SettingsManager.map.symbolRules) {
      console.error('Invalid configuration file: no rules are defined for symbols.');
    } else {
      SettingsManager.map.symbolRules = SettingsManager.map.symbolRules.sort();
      for (const rule of SettingsManager.map.symbolRules) {
        StyleFactory.registerRules(rule, registerSelfStyledObject);
      }
    }
  }, [registerSelfStyledObject]);

  /**
   * On mount, initialize NotificationManager and the session stored
   */
  React.useEffect(() => {
    NotificationManager.init();
  }, []);

  /**
   * On mount, initialize NetworkManager
   */
  React.useEffect(() => {
    if (NetworkManager && isSessionSet === false && sessionStorage.getItem('session')) {
      NetworkManager.setSession(JSON.parse(sessionStorage.getItem('session')));
      setSession(JSON.parse(sessionStorage.getItem('session')));
      setIsSessionSet(true);
    } else if (NetworkManager && isSessionSet === false && SettingsManager?.defaultSession) {
      NetworkManager.setSession(SettingsManager?.defaultSession);
      setIsSessionSet(true);
    }
  }, [NetworkManager, isSessionSet]);

  /**
   * On mount, initialize Cordova plugins
   */
  React.useEffect(() => {
    // Setup background mode
    if (window.cordova && window.cordova.plugins.backgroundMode) {
      window.cordova.plugins.backgroundMode.setDefaults({
        title: 'Crimson',
        text: t('BackgroundModeText')
      });
      window.cordova.plugins.backgroundMode.on('activate', function () {
        window.cordova.plugins.backgroundMode.disableWebViewOptimizations();
      });
    }
  }, [t]);

  /**
   * On mount, attempt to login
   */
  React.useEffect(() => {
    // Try login with SSO (without login page)
    if (!SettingsManager.showServerSettings || localStorage.loginRequired === 'true') {
      login();
    }
  }, [login]);

  /**
   * On login, initialize plugins
   */
  React.useEffect(() => {
    if (pluginsLoaded === false && user) {
      (async function effect() {
        await initializePlugins();
        if (session) {
          runPluginsInitFunction();
        }
      })();
    }
  }, [initializePlugins, pluginsLoaded, user]);

  /**
   * On session selected, start polling keep alive request until logout
   */
  React.useEffect(() => {
    if (user && session) {
      // Send very 10 secs
      keepAliveIntervalRef.current = setInterval(NetworkManager.sendKeepAliveReq, 10000);
    }

    return function cleanup() {
      if (keepAliveIntervalRef.current) {
        clearInterval(keepAliveIntervalRef.current);
        keepAliveIntervalRef.current = null;
      }
    };
  }, [NetworkManager.sendKeepAliveReq, session, user]);

  const handleBackgroundModeChanged = () => {
    setBackgroundModeEnabled((prevState) => !prevState);
  };

  /**
   * Handle session selection
   *
   * @param session		The selected session
   * @param user			The current user
   */
  const handleSessionSelected = (session) => {
    NetworkManager.setSession(session);
    setSession(session);
    runPluginsInitFunction();
  };

  const handleChangeSession = () => {
    setSession('');
    setWidgetStatus({
      open: false,
      callback: null
    });
  };

  const goBack = () => {
    setUser('');
  };


  
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <AppConfigContext.Provider value={appConfig}>
          <Router>
            <NotificationProvider>
              <Routes
                user={user}
                session={session}
                selfStyledObjects={selfStyledObjects}
                teamRegistration={teamRegistration}
                onLogin={login}
                loginError={loginError}
                waiting={waiting}
                showServerSettings={showServerSettings}
                backgroundModeEnabled={backgroundModeEnabled}
                clearLoginError={() => {
                  setLoginError('');
                }}
                hideServerSettings={() => {
                  setShowServerSettings(false);
                }}
                handleSessionSelected={handleSessionSelected}
                goBack={goBack}
                endTeamRegistration={() => {
                  setTeamRegistration(false);
                }}
                handleBackgroundModeChanged={handleBackgroundModeChanged}
                handleLogout={handleLogout}
                handleChangeSession={handleChangeSession}
                setWidgetStatus={(pStatus) => {
                  setWidgetStatus(pStatus);
                }}
                widgetStatus={widgetStatus}
              />
            </NotificationProvider>
          </Router>
        </AppConfigContext.Provider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

App.propTypes = {
  NetworkManager: PropTypes.shape({
    sessionUrl: PropTypes.string.isRequired,
    setSession: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
    getAsset: PropTypes.func.isRequired,
    getEntity: PropTypes.func.isRequired,
    addEntity: PropTypes.func.isRequired,
    updateEntity: PropTypes.func.isRequired,
    removeEntity: PropTypes.func.isRequired,
    retrieveSessionList: PropTypes.func.isRequired,
    sendAsset: PropTypes.func.isRequired,
    sendBinaryAsset: PropTypes.func.isRequired,
    getEntities: PropTypes.func.isRequired,
    sendKeepAliveReq: PropTypes.func.isRequired,
    sendNotAliveReq: PropTypes.func.isRequired
  }).isRequired
};

export default withNetworkManager(App);
