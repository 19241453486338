/**
 * Singleton to load themes settings
 */
class ThemeSettings {
  loaded;
  defaultTheme;
  themes;

  constructor() {
    this.loaded = false;
  }

  /**
   * Fetch incident plugin settings.json
   */
  fetchSettings = () => {
    // We use XMLHttpRequest, because fetch do not support loading from file:// (cordova)
    return new Promise((resolve, reject) => {
      const settingsFile = `${process.env.PUBLIC_URL}/Themes.json`;
      const xhr = new XMLHttpRequest();

      xhr.open('GET', settingsFile);
      xhr.send(null);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            try {
              const parsedBody = JSON.parse(xhr.response);
              resolve(parsedBody);
            } catch (err) {
              console.error(`Error: Cannot parse Themes.json`);
              reject(err);
            }
          } else {
            const err = new Error(`Cannot load ${settingsFile}`);
            reject(err);
          }
        }
      };
    });
  };

  /**
   * Load settings file once
   */
  async load() {
    if (!this.loaded) {
      try {
        const { defaultTheme, themes } = await this.fetchSettings();

        this.defaultTheme = defaultTheme;
        this.themes = themes;

        this.loaded = true;
      } catch (err) {
        console.error(err.message);
        throw err;
      }
    }
  }
}

export default new ThemeSettings();