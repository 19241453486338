import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Card,
  CardContent,
  Typography,
  Toolbar,
  AppBar,
  InputAdornment
} from '@mui/material';
import {
  LocationOn as LocationIcon,
  Bookmark as BookmarkIcon,
  BookmarkBorder as BookmarkBorderIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { KeycloakManager, SettingsManager } from 'services';
import { _t } from 'utils/i18n';
import { compose, fetchData, makeXMLHTTPRequest, strIncludesLoosely } from 'utils';
import { withMapManager } from 'MapManagerContext';
import { withNetworkManager } from 'NetworkManagerContext';
import { withAnnotations } from 'CopContext';
import withSnackNotifications from 'CopContext/withSnackNotifications';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    color: theme.palette.primary.contrastText
  },
  underline: {
    '&:before': {
      borderBottomColor: theme.palette.common.white
    },
    '&:after': {
      borderBottomColor: theme.palette.common.white
    }
  },
  card: {
    margin: '1em'
  },
  closeButton: {
    color: theme.palette.primary.contrastText
  }
}));

// Default request nominatim
const geocodingURL = 'https://nominatim.openstreetmap.org/search?format=json&limit=5&addressdetails=1&q=';

const GeocodingView = ({ annotations: viewPoints, removeAnnotation, MapManager, NetworkManager, snackNotify, onClose }) => {
  const [places, setPlaces] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [selectedViewpoint, setSelectedViewpoint] = React.useState(null);
  const [newBookmarks, setNewBookmarks] = React.useState([]);

  const [localBookmarks, setLocalBookmarks] = React.useState([]);
  const [matchingBookmarks, setMatchingBookmarks] = React.useState({
    viewPoints: [],
    local: []
  });

  const classes = useStyles();
  const theme = useTheme();

  /**
   * @param {string} address
   * @param {AbortSignal} abortSignal
   *
   * @returns {Promise<void>}
   */
  const queryLocations = React.useCallback(async (address, abortSignal) => {
    try {
      const results = await fetchData({ url: geocodingURL + address, abortSignal });

      if (results && results.length > 0) {
        const places = results.map((result) => {
          if (result && result.display_name && result.lat && result.lon) {
            return {
              text: result.display_name,
              bound: result.boundingbox
            };
          }
          return false;
        });

        setPlaces(places);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  // Fire geocoding call when search text is updated
  React.useEffect(() => {
    const abortController = new AbortController();

    // If string is not one or multiple spaces
    if (searchText.replace(/ /g, '') !== '') {
      queryLocations(searchText, abortController.signal);
    } else {
      setPlaces([]);
    }

    return function cleanup() {
      abortController.abort();
    };
  }, [queryLocations, searchText]);

  // Look up matching bookmarks when search text is updated
  React.useEffect(() => {
    // If string is not one or multiple spaces
    if (searchText.replace(/ /g, '') !== '') {
      const matchingBookmarks = {
        viewPoints: [],
        local: []
      };

      localBookmarks.forEach((node) => {
        if (strIncludesLoosely(node.getAttribute('name'), searchText)) {
          matchingBookmarks.local.push(node);
        }
      });

      Object.values(viewPoints).forEach((viewPoint) => {
        if (strIncludesLoosely(viewPoint.Name, searchText)) {
          matchingBookmarks.viewPoints.push(viewPoint);
        }
      });

      setMatchingBookmarks(matchingBookmarks);
    } else {
      setMatchingBookmarks({
        viewPoints: [],
        local: []
      });
    }
  }, [localBookmarks, searchText, viewPoints]);

  // Fetch bookmarks from local file
  React.useEffect(() => {
    const abortController = new AbortController();

    if (SettingsManager.hasOwnProperty('map') && SettingsManager.map.hasOwnProperty('bookmarks')) {
      // WORKAROUND: The path resolves differently between the cordova and web versions
      let bookmarks = SettingsManager.map.bookmarks;

      if (window.cordova) {
        bookmarks = bookmarks.replace(/^\/+/g, '');
      }

      makeXMLHTTPRequest({
        url: bookmarks,
        responseType: 'document',
        abortSignal: abortController.signal
      })
        .then((xmlDocument) => {
          const bookmarks = Array.from(xmlDocument.querySelectorAll('bookmark'));
          setLocalBookmarks(bookmarks);
        })
        .catch((err) => console.error(err));
    }

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const goToLocation = (place) => {
    const { bound } = place;
    if (bound && bound.length === 4) {
      try {
        MapManager.jumpToBound({ lat: bound[0], lon: bound[2] }, { lat: bound[1], lon: bound[3] }, false);
        onClose();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const goToViewpoint = (viewPoint) => {
    try {
      const { VrViewPoint } = viewPoint;

      // Handle no altitude specified
      let altitude = 0;
      if (typeof VrViewPoint.Position[2] !== 'undefined') {
        altitude = VrViewPoint.Position[2];
      }

      MapManager.getMap().setCenterAdvanced({
        lat: VrViewPoint.Position[1],
        lon: VrViewPoint.Position[0],
        alt: altitude,
        range: VrViewPoint.Distance,
        heading: VrViewPoint.Orientation ? VrViewPoint.Orientation[0] : null,
        tilt: VrViewPoint.Orientation ? VrViewPoint.Orientation[1] : null,
      });

      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @param {Element} node
   */
  const selectBookmark = (node) => {
    try {
      MapManager.getMap().setCenterAdvanced({
        lat: node.getAttribute('y'),
        lon: node.getAttribute('x'),
        alt: node.getAttribute('z'),
        range: node.getAttribute('dist')
      });

      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const bookmarkLocation = async (index, item) => {
    const boundSize = Math.max(item.bound[1] - item.bound[0], item.bound[3] - item.bound[2]);
    const distance = 111319.5 * boundSize;

    const lon = (parseFloat(item.bound[2]) + parseFloat(item.bound[3])) * 0.5;
    const lat = (parseFloat(item.bound[1]) + parseFloat(item.bound[0])) * 0.5;
    const viewPointEntity = {
      Name: item.text,
      type: 'ViewPoint',
      emitter: KeycloakManager.getUser().preferred_username,
      VrViewPoint: {
        Distance: distance,
        Name: '',
        Orientation: ['0', '0', '0'],
        Position: [lon, lat, 0],
        type: 'VrViewPoint'
      }
    };

    try {
      const { Uuid, path } = await NetworkManager.addEntity(viewPointEntity);

      // Add it to state so we can show a different icon and allow removal while in the geocoding results view
      setNewBookmarks([...newBookmarks, { index, Uuid, path }]);
    } catch (err) {
      snackNotify(err.message, {
        priority: 100,
        duration: 3000
      });
    }
  };

  /**
   * Remove ViewPoint entity from the server
   *
   * @param {string} entityPath
   */
  const deleteBookmark = (entity) => {
    closeBookmarkSettingsMenu();
    removeAnnotation(entity);
  };

  /**
   * Remove a bookmark while still in the geocoding results list
   */
  const removeNewBookmark = (index) => {
    const bookmarkToRemoveIndex = newBookmarks.findIndex((b) => b.index === index);

    deleteBookmark(newBookmarks[bookmarkToRemoveIndex]);

    setNewBookmarks(newBookmarks.filter((b) => b.index !== index));
  };

  const openBookmarkSettingsMenu = (event, viewPoint) => {
    setMenuAnchor(event.currentTarget);
    setSelectedViewpoint(viewPoint);
  };

  const closeBookmarkSettingsMenu = () => {
    setMenuAnchor(null);
    setSelectedViewpoint(null);
  };

  return (
    <div>
      <AppBar position="relative">
      <Toolbar>
          <TextField
            variant="standard"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            placeholder={_t('search')}
            InputProps={{
              classes: {
                underline: classes.underline,
                root: classes.textFieldRoot
              },
              startAdornment: (
                <InputAdornment position="start" color={theme.palette.primary.contrastText}>
                  <SearchIcon sx={{color: theme.palette.primary.contrastText}}/>
                </InputAdornment>
              )
            }}
            fullWidth />
        <IconButton onClick={() => onClose()} className={classes.closeButton} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List aria-label="matching bookmarks list">
        {/* Matching bookmarks list */}
        {(Object.values(viewPoints).length === 0 && searchText === '') && (
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="body1" component="p">
                {_t('No Bookmarks')}
              </Typography>
            </CardContent>
          </Card>
        )}
        {matchingBookmarks.local.length > 0 &&
          matchingBookmarks.local.map((node) => (
            <>
              <ListItem button onClick={() => selectBookmark(node)} key={node.getAttribute('name')}>
                <ListItemIcon>
                  <BookmarkIcon />
                </ListItemIcon>

                <ListItemText primary={node.getAttribute('name')} />
              </ListItem>
              <Divider />
            </>
          ))}

        {matchingBookmarks.viewPoints.length > 0 &&
          matchingBookmarks.viewPoints.map((viewPoint) => (
            <>
              <ListItem button key={viewPoint.Uuid} onClick={() => goToViewpoint(viewPoint)}>
                <ListItemIcon>
                  <BookmarkIcon />
                </ListItemIcon>
                <ListItemText primary={viewPoint.Name} />
              </ListItem>
              <Divider />
            </>
          ))}
        {/* Search results list */}
        {searchText !== '' && places.length > 0 ? (
          places.map((item, index) => (
            <div key={index}>
              <ListItem button onClick={() => goToLocation(item)}>
                <ListItemIcon>
                  <LocationIcon />
                </ListItemIcon>
                <ListItemText primary={item.text} />

                <ListItemSecondaryAction>
                  {newBookmarks.length > 0 && newBookmarks.map((b) => b.index).includes(index) ? (
                    <IconButton
                      aria-label="Remove bookmark"
                      edge="end"
                      onClick={() => removeNewBookmark(index)}
                      size="large">
                      <BookmarkIcon />
                    </IconButton>
                  ) : (
                    <Tooltip title={_t('BookmarkAdd')} placement="left" disableInteractive>
                      <IconButton
                        aria-label="Add to bookmarks"
                        edge="end"
                        onClick={() => bookmarkLocation(index, item)}
                        size="large">
                        <BookmarkBorderIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </div>
          ))
        ) : // If there are matching bookmarks results, do not render the list (maybe refactor this logic)
        matchingBookmarks.local.length || matchingBookmarks.local.length ? null : (
          <>
            {/* Config bookmarks */}
            {localBookmarks.map((node) => (
              <ListItem button onClick={() => selectBookmark(node)} key={node.getAttribute('name')}>
                <ListItemIcon>
                  <BookmarkIcon />
                </ListItemIcon>

                <ListItemText primary={node.getAttribute('name')} />

                {/* Local bookmarks can't be deleted */}
              </ListItem>
            ))}

            {viewPoints && (
              <>
                {/* User bookmarks*/}
                {Object.values(viewPoints).map((viewPoint) => (
                  <ListItem button key={viewPoint.Uuid} onClick={() => goToViewpoint(viewPoint)}>
                    <ListItemIcon>
                      <BookmarkIcon />
                    </ListItemIcon>

                    <ListItemText primary={viewPoint.Name} />

                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="more"
                        aria-haspopup="true"
                        edge="end"
                        onClick={(event) => openBookmarkSettingsMenu(event, viewPoint)}
                        size="large">
                        <MoreVertIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                <Menu anchorEl={menuAnchor} keepMounted open={!!menuAnchor} onClose={closeBookmarkSettingsMenu}>
                  <MenuItem onClick={() => deleteBookmark(selectedViewpoint)}>{_t('Delete')}</MenuItem>
                </Menu>
              </>
            )}
          </>
        )}
      </List>
    </div>
  );
};

GeocodingView.propTypes = {
  annotations: PropTypes.object.isRequired,
  removeAnnotation: PropTypes.func.isRequired,
  MapManager: PropTypes.object.isRequired,
  NetworkManager: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default compose(
  withTranslation('common'),
  withMapManager,
  withNetworkManager,
  withAnnotations({ types: 'ViewPoint' }),
  withSnackNotifications
)(GeocodingView);
