import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'utils/withRouter';
import { useTheme, withStyles } from '@mui/styles';
import { Divider, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Toolbar, Typography, IconButton, Select, MenuItem, Menu, Autocomplete, TextField, Paper } from '@mui/material';
import { Group, Settings, ExitToApp, Info, ViewHeadline, Language } from '@mui/icons-material';
import { AppConfigContext } from 'AppConfig';
import SettingsManager from 'services/SettingsManager';
import { compose } from 'utils';
import i18n, { _t } from 'utils/i18n';
import {  ArrowBack } from '@mui/icons-material';
import { withCop } from 'CopContext';
import traduction from '../locales/en/common.json';

const styles = (theme) => ({
  drawerInnerWeb: {
    width: '16rem',
    [theme.breakpoints.up('md')]: {
      width: '20rem'
    },
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '40px'
  },
  titleButton: {
    color: theme.palette.secondary.contrastText
  },
  selectContainer: {
    color: theme.palette.tertiary.contrastText,
    borderColor: theme.palette.tertiary.contrastText
  }
});

/**
 * Main view.
 */
const MainDrawer = ({
  classes,
  session,
  history,
  onChangeSession,
  onClose,
  onLogout,
  onOpen,
  open,
  user,
  setDrawerOpen,
  setWidgetStatus,
  toggleAboutDialog,
  online
}) => {
  const [ListItems, setListItems] = React.useState(null);
  const appConfig = React.useContext(AppConfigContext);
  const [language, setLanguage] = useState(i18n.language.slice(0, 2))
  const [openLng, setOpenLng] = useState(false)
  const theme = useTheme();

  React.useEffect(() => {
    // Build the item lists for the drawer
    const newListItems = [];

    for (const [lIndex, Component] of appConfig.mainDrawerItems.entries()) {
      newListItems.push(
        <div key={lIndex}>
        <Component
          key={lIndex}
          handleListChange={(pValue) => {
            history.push(pValue);
          }}
          history={history}
          setWidgetStatus={setWidgetStatus}
        />
        <Divider/>
        </div>
      );
    }

    setListItems(newListItems);
  }, [history, setWidgetStatus, appConfig, setDrawerOpen]);

  /**
   * Change the language
   */
  const changeLanguage = (event, value) => {
    const key = Object.keys(traduction.languages).find((key) => traduction.languages[key] === value)
    
    i18n.changeLanguage(key);
    setLanguage(value)
  };

  return (
    <SwipeableDrawer
      disableBackdropTransition
      disableDiscovery
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      classes={{
        paper: classes.drawerInnerWeb
      }}
      sx={{zIndex: 1600}}
    >
      <Toolbar key={'toolbar'} className={classes.toolbar}>
        <div className={classes.title}>
          <Typography variant="subtitle2">{session.title}</Typography>
          <Typography variant="caption">{user}</Typography>
        </div>
        
        <IconButton onClick={() => setDrawerOpen(false)} size="large">
          <ArrowBack sx={{color: theme.palette.primary.contrastText}}/>
        </IconButton>
      </Toolbar>

      {/* List of items for drawer */}
      <List defaultValue={0} disablePadding>
        {ListItems}
      </List>

      {/* Permanent menu items  */}
      <List disablePadding sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

          {/* <ListItem key={'teams'} button component={Link} to="/team" >
            <ListItemIcon className={classes.titleButton}>
              <Group />
            </ListItemIcon>
            <ListItemText primary={_t('Teams')} />
          </ListItem>  */}

        {/* Only display the change session action if no default session in the settings */}
        {/* {!SettingsManager.defaultSession && 
          <ListItem
            key={'sessions'}
            button
            onClick={() => {
              setDrawerOpen(false);
              onChangeSession();
            }}
            disabled={!online}
          >
          <ListItemIcon className={classes.titleButton}>
              <ViewHeadline />
            </ListItemIcon>       
            <ListItemText primary={_t('Sessions')} />
          </ListItem> 
        } */}

        {/* <ListItem key={'settings'} button component={Link} to="/settings">
          <ListItemIcon className={classes.titleButton}>
              <Settings />
            </ListItemIcon>
          <ListItemText primary={_t('Settings')} />
        </ListItem> */}
          
        {/* <ListItem key={'language'} disabled={!online} button >
          <ListItemIcon className={classes.titleButton}>
            <Language />
          </ListItemIcon>
          <ListItemText primary={`${_t('Language')}: ${_t('languages.' + language)}`} />
        </ListItem> */}

        {/* <ListItem
          key={'about'}
          button
          onClick={() => {
            setDrawerOpen(false);
            toggleAboutDialog();
          }}
        >
          <ListItemIcon className={classes.titleButton}>
              <Info />
          </ListItemIcon>
          <ListItemText primary={_t('About')} />
        </ListItem> */}

        <Autocomplete
          id="lock-menu"
          value={`${_t('Language')}: ${language.length === 2 ? _t(`languages.${language}`) : language}`}
          options={[_t("languages.fr"), _t("languages.en"), ...SettingsManager.languages.map(lang => _t(`languages.${lang}`))]}
          onChange={changeLanguage}
          clearIcon={false}
          slotProps={{
            popper: {
              sx: {
                zIndex:  2000
              }
            }
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}>
              {option}
            </li>
          )}
          ListboxProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              maxHeight: '80vh'
            },
          }}
          renderInput={(params) => 
          <TextField 
            {...params}
            sx={{
              border: 'none',
              '& .MuiInputLabel-root': { 
                color: theme.palette.secondary.contrastText,
              },
              '& .MuiInputBase-input': { 
                color: theme.palette.secondary.contrastText,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none', 
                },
                '&:hover fieldset': {
                  border: 'none', 
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                }
              },
              '& .MuiAutocomplete-endAdornment': {
                '& .MuiSvgIcon-root': {
                  color: theme.palette.secondary.contrastText
                }
                
              }
            }}
          />
          }
        />

        <ListItem button key={'logOut'}>
          <ListItemIcon className={classes.titleButton}>
              <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={_t('Log Out')} onClick={onLogout} />
        </ListItem>

      </List>
    </SwipeableDrawer>
  );
};

export default compose(React.memo, withStyles(styles), withRouter, withCop)(MainDrawer);
