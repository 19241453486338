import * as jsonpatch from 'fast-json-patch';

/**
 * @param {Object} params
 * @param {string} params.action
 * @param {Object} params.event
 * @param {Object} params.annotation
 * @param {string} params.user
 * @param {Object=} params.oldValue
 * @param {number=} params.priority
 * @param {boolean=} params.optimistic
 * @param {*=} params.subject
 * @param {Function=} params.successHandler
 * @param {Function=} params.errorHandler
 *
 * @returns {Object}
 */

const handleCommandQueueUpdate = ({
  action,
  annotation,
  priority,
  optimistic,
  successHandler,
  errorHandler,
  conflictHandler
}) => (state) => {
  const { commandQueue, unsyncedAnnotations, autoShare, online } = state;

  const newState = {
    commandQueue: [],
    willRetry: false
  };

  let deleteUnsyncAnnotation = false;

  // If we already have a command with the same key in the queue we want to remove it
  newState.commandQueue = commandQueue.filter(
    (command) => {
      if ((!autoShare || !online) && (command.annotation.Uuid === annotation.Uuid && command.action === 'add' && action !== 'add')) {
        // If the 'add' command is in the queue for the modified annotation
        // Replace its annotation with the modified annotation
        if (action === 'modify') {
          const patch = jsonpatch.compare(command.annotation, annotation);
          command.annotation = jsonpatch.applyPatch(command.annotation, patch).newDocument;
          action = 'add'
        } else if (action === 'delete') {
          deleteUnsyncAnnotation = true
          return false
        }
      }

      return !command.annotation || command.annotation.Uuid !== annotation.Uuid
    }
  ) 

  if (!deleteUnsyncAnnotation) {
    newState.commandQueue.push({
      annotation,
      priority: priority,
      action,
      successHandler,
      errorHandler,
      conflictHandler
    });
  }
  

  // We need to keep track of unsynced annotations separately because actual annotations are overridden every time we receive a new event from the server
  if (optimistic === true) {
   
    newState.unsyncedAnnotations = {
      ...unsyncedAnnotations,
      [annotation.type]: {
        ...unsyncedAnnotations[annotation.type],
        [annotation.Uuid]: {
          annotation: {
            ...annotation,
            synced: false
          },
          priority: priority
        }
      }
    };
  }

  return newState;
};

export default handleCommandQueueUpdate;
