/**
 * Available route URLS for the app
 */

export const ROOT = '/main';

// Export objects to make the values more explicit

export const RELATIVE_ROUTES = {
  COMS: '/coms',
  INCIDENTS: '/incidents',
  LOGS: '/log',
  MAP: '/map',
  CAMERA: '/camera',
  TCHAT: '/tchat',
  INCIDENTSMISSIONS: '/incidentsmissions',
};

export const ABSOLUTE_ROUTES = {
  COMS: ROOT + '/coms',
  INCIDENTS: ROOT + '/incidents',
  LOGS: ROOT + '/log',
  MAP: ROOT + '/map',
  CAMERA: ROOT + '/camera',
  TCHAT: ROOT + '/tchat',
  INCIDENTSMISSIONS: ROOT + '/incidentsmissions',
};
