import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const styles = theme => ({
	paper: {
		margin: '1.5rem'
	},
	dialogIcon: {
		color: theme.palette.primary.main,
		marginRight: '1rem'
	},
	dialogActions: {
		display: 'block',
	}
});

const UpdateConfirmationDialog = ({
	open,
	onClose,
	children,
	actions,
	classes
}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth="sm"
			fullWidth
			classes={{ paper: classes.paper }}
		>
			<DialogContent>{children}</DialogContent>
			<DialogActions className={classes.dialogActions}>{actions}</DialogActions>
		</Dialog>
	);
};

UpdateConfirmationDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	actions: PropTypes.node.isRequired
};

export default withStyles(styles)(UpdateConfirmationDialog);
