import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Map as MapIcon, PowerSettingsNew as PowerSettingsNewIcon } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { BottomNavigation, BottomNavigationAction, Paper, Drawer, List, IconButton, Typography } from '@mui/material';
import { AppConfigContext } from 'AppConfig';
import { _t } from 'utils/i18n';
import { ROOT, ABSOLUTE_ROUTES } from 'constants/routes';
import atomDrawerObject from 'components/DrawerMenu/DrawerAtom';
import { useAtom } from 'jotai';
import { useTheme, withStyles } from '@mui/styles';
import { SettingsManager } from 'services';

const styles = theme => ({
  root: {
    color: theme.palette.secondary.contrastText,
    '&.selected': {
      color: theme.palette.primary.main,
    },
  }
});

/**
 * The navigation component to display the various informations in the main view.
 */
const Navigation = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const appConfig = useContext(AppConfigContext);
  const [drawerOpen, setDrawerOpen] = useAtom(atomDrawerObject);
  const { onLogout, classes } = props;

  /**
   * Change route exeplicitely because react-router's <Link /> was breaking the styles of <BottomNavigation />
   */
  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  /**
   * Build the actions in the navigation bar from the plugins
   */
  const pluginActions = appConfig.mainNavigationRoutes.map( (params) => {
    const IconComponent = params.iconComponent;
    const absRoute = ROOT + params.path;
    return (
      <BottomNavigationAction
        classes={classes}
        value={absRoute}
        label={_t(params.title)}
        icon={ <IconComponent sx={{color: history.location.pathname === absRoute ? theme.palette.primary.main : theme.palette.secondary.contrastText}} active={(history.location.pathname === absRoute).toString()}/> }
        key={params.title}
      />
    )
  });

  /**
   * Build the actions in the navigation bar from the plugins for the WebView
   */
  const pluginSideBarActions = appConfig.mainNavigationRoutes.map( (params) => {
    const IconComponent = params.iconComponent;
    const absRoute = ROOT + params.path;
    return (
      <div key={absRoute} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <IconButton
          sx={{
            opacity: history.location.pathname === absRoute ? 1 : 0.8,
            color: history.location.pathname === absRoute ? theme.palette.primary.main : theme.palette.secondary.contrastText,
            margin: '20px 10px 0 10px'
          }}
          size='large'
          onClick={() => history.push(absRoute)}
          
          label={params.title}
        >
          <IconComponent/>
        </IconButton>
        <Typography variant="body2" sx={{
            textAlign: 'center',
            opacity: history.location.pathname === absRoute ? 1 : 0.8,
            color: history.location.pathname === absRoute ? theme.palette.primary.main : theme.palette.secondary.contrastText,
          }}>
          {_t(params.title)}
        </Typography>
      </div>
      
    )
  });

  return (
    <Paper elevation={1} style={{ borderRadius: 0, flex: '0 0 auto', overflowY: 'auto', zIndex: 1500, alignItems: 'center', justifyContent: 'center', width: {xs: '100%'}}}>
      {/* Bottom Navigation displayed only on phone and tablet */}
      {/* <BottomNavigation showLabels value={history.location.pathname} onChange={handleChange} sx={{display: {lg: "none"}, minWidth: "fit-content", backgroundColor: theme.palette.secondary.main}}>

        {/* <BottomNavigationAction classes={classes} value={ABSOLUTE_ROUTES.MAP} label={_t('Map')} icon={<MapIcon />} /> */}

        {/* {pluginActions} */}

      {/* </BottomNavigation> */}
      {/* Drawer displayed only on Web View */}
      <Drawer variant="permanent" sx={{
          display: {xs: 'none', lg: 'flex'},
          [`& .MuiDrawer-paper`]: {
            display: 'flex',
            backgroundColor: theme.palette.secondary.main,
            justifyContent: 'flex-start',
            ...SettingsManager.topBar && {
              maxHeight: `calc(100% - ${theme.topBar.height})`,
              top: SettingsManager.topBar ? theme.topBar.height : '0px'
            } 
          }
      }}>
        <div key={'menu-burger'} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderBottom: '1px solid #33808080', paddingBottom: '5px'}}>
          <IconButton
            sx={{
              opacity: 1,
              color: theme.palette.secondary.contrastText,
              margin: '10px 10px 0 10px'
            }}
            size='large'
            onClick={() => setDrawerOpen(!drawerOpen)}
            key={'drawer'} 
          >
            <MenuIcon/>
          </IconButton>
        </div>
        <List sx= {{
          display: 'flex',
          flexDirection: 'column',
          width: (theme) => theme.sideNavigation.width,
          overflowY: 'overlay',
          '&::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: theme.palette.secondary.main
          },
          '&::-webkit-scrollbar-thumb': {
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '10px',
          }
        }}>
          {/* <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
            <IconButton
              sx={{
                opacity: history.location.pathname === ABSOLUTE_ROUTES.MAP ? 1 : 0.8,
                color: history.location.pathname === ABSOLUTE_ROUTES.MAP ? theme.palette.primary.main : theme.palette.secondary.contrastText,
                margin: '10px 10px 0 10px'
              }}
              size='large'
              onClick={() => history.push(ABSOLUTE_ROUTES.MAP)}
              key={'map'} 
            >
              <MapIcon/>
            </IconButton>
            <Typography variant="body2" sx={{
                opacity: history.location.pathname === ABSOLUTE_ROUTES.MAP ? 1 : 0.8,
                color: history.location.pathname === ABSOLUTE_ROUTES.MAP ? theme.palette.primary.main : theme.palette.secondary.contrastText,
              }}>
              {_t('Map')}
            </Typography>
          </div> */}
          {pluginSideBarActions}
        </List>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '10px',
          marginTop: 'auto',
          borderTop: '1px solid #33808080'}}>
          <IconButton
            sx={{
              color: theme.palette.secondary.contrastText,
              margin: '10px 10px 0 10px',
              '&:hover': {
                color: theme.palette.primary.main
              }
            }}
            size='large'
            onClick={onLogout}
            key={'logout'} 
          >
            <PowerSettingsNewIcon style={{fontSize: '30px'}}/>
          </IconButton>
        </div>
      </Drawer>
      {/* Drawer displayed only on Mobile View */}
      <Drawer variant="permanent" anchor='top' sx={{
          display: {xs: 'flex', lg: 'none'},
          flexDirection: 'row',
          maxHeight: theme.topBar.height,
          height: '50px',
          [`& .MuiDrawer-paper`]: {
            display: 'flex',
            height: '50px',
            flexDirection: 'row',
            backgroundColor: theme.palette.secondary.main,
            justifyContent: 'space-between',
            ...SettingsManager.topBar && {
              maxHeight: theme.topBar.height,
              top: SettingsManager.topBar ? theme.topBar.height : '0px'
            } 
          }
      }}>
        <div key={'menu-burger'} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0}}>
          <IconButton
            sx={{
              opacity: 1,
              color: theme.palette.secondary.contrastText,
              margin: '0px 10px 0 10px'
            }}
            size='large'
            onClick={() => setDrawerOpen(!drawerOpen)}
            key={'drawer'} 
          >
            <MenuIcon/>
          </IconButton>
        </div>
        {/* <List sx= {{
          display: 'flex',
          flexDirection: 'column',
          width: (theme) => theme.sideNavigation.width,
          overflowY: 'overlay',
          '&::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: theme.palette.secondary.main
          },
          '&::-webkit-scrollbar-thumb': {
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '10px',
          }
        }}> */}
          {/* <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
            <IconButton
              sx={{
                opacity: history.location.pathname === ABSOLUTE_ROUTES.MAP ? 1 : 0.8,
                color: history.location.pathname === ABSOLUTE_ROUTES.MAP ? theme.palette.primary.main : theme.palette.secondary.contrastText,
                margin: '10px 10px 0 10px'
              }}
              size='large'
              onClick={() => history.push(ABSOLUTE_ROUTES.MAP)}
              key={'map'} 
            >
              <MapIcon/>
            </IconButton>
            <Typography variant="body2" sx={{
                opacity: history.location.pathname === ABSOLUTE_ROUTES.MAP ? 1 : 0.8,
                color: history.location.pathname === ABSOLUTE_ROUTES.MAP ? theme.palette.primary.main : theme.palette.secondary.contrastText,
              }}>
              {_t('Map')}
            </Typography>
          </div> */}
          {/* {pluginSideBarActions} */}
        {/* </List> */}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '0px',
          marginTop: '0px'}}>
          <IconButton
            sx={{
              color: theme.palette.secondary.contrastText,
              margin: '0px 10px 0 10px',
              '&:hover': {
                color: theme.palette.primary.main
              }
            }}
            size='large'
            onClick={onLogout}
            key={'logout'} 
          >
            <PowerSettingsNewIcon style={{fontSize: '25px'}}/>
          </IconButton>
        </div>
      </Drawer>
    </Paper>
  );
};

export default withStyles(styles)(Navigation);
