import React from 'react';
import { withRouter } from 'utils/withRouter';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import {ChevronLeft as ChevronLeftIcon} from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import { withNetworkManager } from 'NetworkManagerContext';
import { withCop } from 'CopContext';
import { compose } from 'utils';
import { _t } from 'utils/i18n';
import {KeycloakManager} from 'services';




const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText
  },
  content: {
    flex: '1 1 auto',
    padding: '1em'
  },
  audioInput: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0
  },
  imagePreview: {
    display: 'block',
    maxWidth: '90%',
    maxHeight: '250px',
    margin: 'auto',
    padding: '1em'
  },
  validContainer: {
    [theme.breakpoints.up('lg')] : {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
  },
  messageField: {
    [theme.breakpoints.up('lg')] : {
      width: '50%'
    },
    width: '100%',
    marginTop: '1rem'
  },
  button: {
    [theme.breakpoints.up('lg')] : {
      width: '50%'
    },
    width: '100%',
    marginTop: '1.5rem'
  },
  successMsg: {
    color: green[500]
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main
  },
  toolbarTitle: {
    marginRight: '0.75rem',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center'
  },
  toolbarButton: {
    color: theme.palette.common.white,
    marginRight: '1rem',
    marginLeft: '-1rem'
  }
});

class Audio extends React.Component {
  /**
   * The state for audio report
   * @type {Object}
   */
  state = {
    mediaURL: '',
    /**
     * @type {'AUDIO'}
     */
    mediaType: '',
    mediaMimeType: 'audio/x-m4a',
    dialogOpen: false,
    waiting: false,
    done: false,
    // Comment text
    text: '',
    sendingStatus: ''
  };

  componentDidMount() {
    if (!window.cordova) {
      this.mediaInput.click();
    } else {
      this.cordovaRecordAudio();
    }

  }

  /**
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.willRetry && this.props.willRetry) {
      this.setState({
        done: true,
        waiting: false,
        text: '',
        mediaURL: '',
        sendingStatus: _t('Photo send retry')
      });
    }
  }


  /**
   * Upload a media on the web app
   */
   handleWebUpload = (evt) => {
    if (!window.cordova) {
      if (evt.target && evt.target.files && evt.target.files[0]) {
        const file = evt.target.files[0];
         
        const urlReader = new FileReader();

        urlReader.onload = (e) => {
          this.setState({
            mediaURL: e.target.result,
            mediaMimeType: file.type,
            mediaType: 'AUDIO'
          });
        };

        if (evt.target && evt.target.files && evt.target.files[0]) {
          urlReader.readAsDataURL(evt.target.files[0]);
        }
      }
    }
  };

  /**
   * Record audio from device
   */
   cordovaRecordAudio = () => {
    try {
      if (window.cordova) {
        const captureSuccess = (mediaFiles) => {
          const {full_path:fullPath}=JSON.parse(mediaFiles);
          const path = `file://${fullPath}`;

          // Get native URL to be able to preview the video
          window.resolveLocalFileSystemURL(
            path,
            (fileEntry) => {
              console.log('Audio -> captureSuccess -> fileEntry', fileEntry.toURL());
              this.setState({ mediaURL: fileEntry.toURL(), mediaType: 'AUDIO' });
            },
            () => console.error('Cannot access to ' + path)
          );
        };

        const captureError = (error) => {
            this.props.history.goBack();
        };

        const permissions = window.cordova.plugins.permissions;
        permissions.requestPermission(permissions.READ_EXTERNAL_STORAGE, (status) => {
          if (status.hasPermission) {
            navigator.device.audiorecorder.recordAudio(captureSuccess,captureError, 60);
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleSuccess = (comment, incident) => {
    return (response) => {
      try {

        if (response.status >= 300) {
          this.setState({
            done: true,
            waiting: false,
            sendingStatus: 'Sending failed, please check your connection and try again later.'
          });
        } else {
          // Now create the annotation and send it to COP...
          const lAssetPath = response.headers.get('Location');
          if (!lAssetPath) {
            throw new Error('Internal Server error : response not valid, asset location is not found');
          }

          // Join the comment with the audio to this one
          this.props.addAnnotation({
            Comment: comment,
            CommentedUUID: incident.Uuid,
            AssetPaths: [lAssetPath],
            OwnerUUID: KeycloakManager.getUser().sub,
            emitter: KeycloakManager.getUser().preferred_username,
            type: 'Comment',
            tags: ['Comment']
          });
          

          
          // Time out so the user cna see the success msg
          setTimeout(
            () =>
              // Go back to the incident
              this.props.history.goBack(),
            2000
          );

          this.setState({
            done: true,
            waiting: false,
            text: '',
            mediaURL: '',
            sendingStatus: _t('Media send success')
          });
        }
      } catch (geoLocationPositionError) {
        this.setState({ dialogOpen: false, waiting: false });
      }
    };
  };

  handleError = (error) => {
    console.error(error);

    this.setState({
      done: true,
      waiting: false,
      text: '',
      mediaURL: '',
      sendingStatus: _t('Photo send fail')
    });
  };

  /**
   * Send audio blob
   */
   sendAudioBlob = (blob) => {
    const { text } = this.state;
    const { location, sendAsset } = this.props;
    const incident = location && location.state ? location.state.incident : undefined;

    const reader = new FileReader();

    // Read the file and convert it to an audioBlob before sending it.
    reader.onloadend = (e) => {
      const newBlob = new Blob([reader.result], {type: 'audio/mp3'});
      try {
        sendAsset(newBlob, this.handleSuccess(text, incident), this.handleError, 'blob');
      } catch (err) {
        console.error(err);
      }
    };
    
    reader.readAsArrayBuffer(blob);
    
  };

  /**
   * Handle when user click on send
   */
   handleSend = () => {
    try {
      this.setState({ dialogOpen: true, waiting: true });

      const { mediaURL, mediaType, text } = this.state;
      const { location, sendAsset } = this.props;

      const incident = location && location.state ? location.state.incident : undefined;

      if (!window.cordova && mediaType === 'AUDIO') {
        sendAsset(mediaURL, this.handleSuccess(text, incident), this.handleError);
      } else if (window.cordova && mediaType === 'AUDIO') {
          window.resolveLocalFileSystemURL(mediaURL, (fileEntry) => fileEntry.file(this.sendAudioBlob));
        }
    } catch (err) {
        console.error(err);
      }
  };

  handleClose = () => {
    this.setState({
      dialogOpen: false
    });
  };

  /**
   * Render the component
   */
  render() {
    const { done, mediaMimeType,mediaType, mediaURL } = this.state;
    const { classes } = this.props;

    let lDialogTitle = '';
    let lDialog = '';
    let lDialogActions = null;
    if (this.state.waiting) {
      lDialogTitle = _t('Sending photo report');
      lDialog = <CircularProgress />;
    } else if (done) {
      lDialogActions = (
        <Button variant="contained" color="primary" onClick={this.handleClose}>
          {_t('Close')}
        </Button>
      );
      lDialogTitle = '';
      lDialog = this.state.sendingStatus;
    }
    return (
      <div className={classes.container}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            tooltip={_t('Back')}
            onClick={this.props.history.goBack}
            className={classes.toolbarButton}
            size="large">
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
          <Typography variant="h6" className={classes.toolbarTitle}>
            {_t('Photo Report')}
          </Typography>
        </Toolbar>
        <div id="audio" className={classes.content}>
          {!window.cordova ? (
            <input
              ref={(input) => (this.mediaInput = input)}
              className={classes.audioInput}
              type="file"
              accept="audio/*"
              capture
              onChange={this.handleWebUpload}
            />
          ) : null
          }
          {mediaURL !== '' && (
            <>
              {/* Audio preview */}
              {mediaType === 'AUDIO' ? (
                <audio controls className={classes.imagePreview} controlsList="nodownload noplaybackrate">
                  <source src={mediaURL} type={mediaMimeType} />
                </audio>
              ): null} 
              <div className={classes.validContainer}>
                <TextField
                  variant="standard"
                  label={_t('Please enter your message')}
                  value={this.state.text}
                  onChange={(event) => this.setState({ text: event.target.value })}
                  className={classes.messageField} />
                <Button variant="contained" className={classes.button} fullWidth onClick={this.handleSend}>
                  {_t('Send')}
                </Button>
              </div>
            </>
          )}
        </div>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          disableEscapeKeyDown>
          <DialogTitle>{lDialogTitle}</DialogTitle>
          <DialogContent>{lDialog}</DialogContent>
          <DialogActions>{lDialogActions}</DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(withNetworkManager, withRouter, withStyles(styles), withCop)(Audio);
