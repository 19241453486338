import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

// import { _t } from 'utils/i18n';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.white,
    marginLeft: '70px'
  },
  titleButton: {
    color: theme.palette.common.white,
    position: 'absolute',
    left: '1vw'
  },
  appBarComponent: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    [theme.breakpoints.down('md')]: {
      width: 200
    },
    [theme.breakpoints.up('sm')]: {
      width: 250
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 400
    }
  }
}));

/**
 * Simple layout with title bar and back button
 * @return {React.FC}
 */
const ViewLayout = ({ children, title, appBarComponent, className, hideBackArrow, hideAppBar, onBack }) => {
  const history = useHistory();
  const classes = useStyles();

  const onBackClick = onBack ? onBack : () => history.goBack();

  return (
    <div className={className}>
      {!hideAppBar && (
        <AppBar position="relative">
          <Toolbar>
            {!hideBackArrow && (
              <IconButton onClick={onBackClick} className={classes.titleButton} size="large">
                <ArrowBack />
              </IconButton>
            )}
            {title && (
              <Typography variant="h5" className={classes.title}>
                {title}
              </Typography>
            )}
            {appBarComponent ? <div className={classes.appBarComponent}>{appBarComponent}</div> : null}
          </Toolbar>
        </AppBar>
      )}
      {children}
    </div>
  );
};

export default React.memo(ViewLayout);
