// This must be the first line in src/index.js
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { TextField } from '@mui/material';
import App from './App';
import { SettingsManager, ServerSettingsManager } from 'services';
import { NetworkManagerProvider } from 'NetworkManagerContext';
import i18n from 'utils/i18n';
import './index.css';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { createMuiTheme } from 'utils/createMuiTheme';
import themeSettings from 'utils/themeSettings';
import LanguageSettings from 'utils/LanguageSettings';

function runApp() {
  SettingsManager.load()
    .then(() => {
      ServerSettingsManager.init();
      // Load added languages
      LanguageSettings.load();
      themeSettings.load().then(() => {
        const muiTheme = createMuiTheme(themeSettings.defaultTheme, themeSettings.themes)
        return muiTheme
      }).then((muiTheme) => {
        // Render the main app react component into the app div.
        ReactDOMClient.createRoot(document.getElementById('root')).render(
          <I18nextProvider i18n={i18n}>
            <NetworkManagerProvider>
              <App muiTheme={muiTheme}/>
            </NetworkManagerProvider>
          </I18nextProvider>
        );
      })
    })
    .catch((err) => {
      const msg = `Invalid configuration file : ${err}`;
      ReactDOM.ReactDOM.createRoot(document.getElementById('root')).render(
        <TextField
          error
          fullWidth
          multiline
          InputProps={{
            readOnly: true
          }}
          label="Error"
          defaultValue={msg}
          margin="normal"
          variant="outlined"
        />
      );
      console.error(msg);
    });
}

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    runApp();
  });
} else {
  runApp();
}
