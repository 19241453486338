import React, { useRef } from "react";
import { Fab, Tooltip } from "@mui/material";
import { Add, Remove, Navigation } from "@mui/icons-material";
import { withMapManager } from "MapManagerContext";
import { red } from "@mui/material/colors";

/**
 * Compass and Zoom Widget
 */
const CompassZoomWidget = (props) => {

  // DOM reference to compass Fab element
  const compassRef = useRef(null);

  const map = props.MapManager.getMap();

  // When orientation heading changes, rotate the compass as much using CSS
  const onOrientationChanged = ({ heading }) => {
    if (compassRef.current) {
      compassRef.current.style.transform = `rotate(${heading}deg)`;
    } else {
      console.warn("Warning: can't rotate compass element.");
    }
  };

  // Reset north position of globe
  const resetMapHeading = () => {
    if (map) {
      map.setHeading(0);
    }
  };

  // Called when "+" is clicked
  const onZoomIn = () => {
    if (map) {
      map.setRange(map.getRange() * 0.7, true);
    }
  }

  // Called when "-" is clicked
  const onZoomOut = () => {
    if (map) {
      map.setRange(map.getRange() * 1.3, true);
    }
  }

  // Add map listeners for the orientation
  React.useEffect(() => {
    if (map) {
      map.addEventListener("orientationchanged", onOrientationChanged);
      compassRef.current = document.querySelector(`#compass_element_id`);
    }

    return function cleanup() {
      map?.removeEventListener("orientationchanged", onOrientationChanged);
    };
  }, [map]);

  return (
    <>
      <Tooltip title="Click to reset orientation" placement="left" enterDelay={200}>
        <Fab
          id="compass_element_id"
          aria-label="compass"
          onMouseUp={resetMapHeading}
          onTouchEnd={resetMapHeading}
          size="medium"
          color='secondary'
          style={{
            margin: 5,
            position: 'absolute',
            top: 124,
            right: 0,
            zIndex: 100,
            color: red[600]
          }}
        >
          <Navigation />
        </Fab>
      </Tooltip>
      {/* Zoom widget not rendered on mobile phone */}
      {!window.cordova && (
        <div style={{
          position: 'absolute',
          top: 168,
          right: 0,
          zIndex: 100,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'scale(0.7)',
          marginTop: 5,
          marginRight: 9
        }}>
          <Tooltip title="Zoom in" placement="left" enterDelay={200}>
            <Fab
              id="zoom_in_widget"
              aria-label="zoom in"
              size="small"
              color="secondary"
              style={{borderRadius: "5px 5px 0px 0px", boxShadow: "none"}}
              onClick={() => onZoomIn()}
            >
              <Add />
            </Fab>
          </Tooltip>
          <Tooltip title="Zoom out" placement="left" enterDelay={200}>
            <Fab
              id="zoom_out_widget"
              aria-label="zoom out"
              size="small"
              color="secondary"
              style={{borderRadius: "0px 0px 5px 5px", boxShadow: "none"}}
              onClick={() => onZoomOut()}
            >
              <Remove />
            </Fab>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default withMapManager(CompassZoomWidget);
