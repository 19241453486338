import React from 'react';
import PropTypes from 'prop-types';
import { WifiOff as WifiOffIcon } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { _t } from 'utils/i18n';
import { useTheme } from '@mui/styles';

const OfflineIndicator = ({ online }) => {
  const theme = useTheme();
  if (online === false) {
    return (
      <Alert 
        sx={{
          marginLeft: {xs: 0, lg: theme.sideNavigation.width}
        }}
        icon={<WifiOffIcon fontSize="inherit" />}
        severity="warning"
      >
        {_t('OfflineStatus')}
      </Alert>
    );
  }
  return null;
};

OfflineIndicator.propTypes = {
  online: PropTypes.bool.isRequired
};

export default OfflineIndicator;
