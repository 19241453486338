import React from 'react';
import { withMapManager } from 'MapManagerContext';
import { compose } from 'utils';
import { SpeedDial, SpeedDialAction } from '@mui/material';
import MeasureIcon from 'components/Icons/Measure';
import MeasureAzimuthIcon from 'components/Icons/MeasureAzimuth';
import MeasureAreaIcon from 'components/Icons/MeasureArea';
import MeasureLineIcon from 'components/Icons/MeasureLine';
import MeasurePositionIcon from 'components/Icons/MeasurePositionIcon';
// import MeasureProfilIcon from 'components/Icons/MeasureProfil';
import ClearIcon from '@mui/icons-material/Clear';
import { _t } from 'utils/i18n.js';

const measureTools = [
  {
    tool: "azimut",
    icon: <MeasureAzimuthIcon />,
  },
  {
    tool: "area",
    icon: <MeasureAreaIcon />
  },
  {
    tool: "distance",
    icon: <MeasureLineIcon />
  },
  {
    tool: "position",
    icon: <MeasurePositionIcon />
  },
  // {
  //   tool: "profil",
  //   icon: <MeasureProfilIcon />
  // }
]

function MeasureWidget({MapManager, classes}) {

  const handleStartTools = (tool) => {
    MapManager.getMap().startMeasureTool({type: tool});
  };

  const handleStopTools = () => {
    MapManager.getMap().stopTool();
  };

  return (
    <SpeedDial
      ariaLabel="Toggle measure actions"
      sx={{
        position: 'absolute',
        top: 256,
        right: 0,
        zIndex: 100
      }}
      icon={<MeasureIcon />}
      direction='down'
      FabProps={{
        size: 'medium',
        style: {
          margin: 5
        }
      }}
    >
      {measureTools.map(({tool, icon}) => {
        return <SpeedDialAction
          tooltipTitle={_t("measureTools." + tool)}
          icon={icon}
          onClick={() => handleStartTools(tool)}
          key={tool}
        />
      })}
      <SpeedDialAction
        tooltipTitle={_t("measureTools.stop")}
        icon={<ClearIcon/>}
        onClick={handleStopTools}
        key={'clear measures'}
      />
    </SpeedDial>
  );
}

export default compose(
  withMapManager
)(MeasureWidget);
