import React from 'react';

import { Fab } from '@mui/material';
import { Layers as LayersIcon } from '@mui/icons-material';

import LayerMenu from './LayerMenu';

class LayerMenuButton extends React.Component {
  constructor() {
    super();

    this.state = {
      secondary: false,
      openLayerMenu: false
    };
  }

  openLayerMenu = () => {
    this.setState({ secondary: true, openLayerMenu: true });
    this.props.setWidgetStatus({
      open: true,
      callback: this.externallyCloseLayerMenu
    });
  };
  closeLayerMenu = (event) => {
    this.setState({ openLayerMenu: false, secondary: false });
    this.props.setWidgetStatus({
      open: false,
      callback: null
    });
  };
  externallyCloseLayerMenu = () => {
    this.setState({ openLayerMenu: false, secondary: false });
  };

  render() {
    return (
      <>
        <Fab
          size="medium"
          color={this.state.secondary ? 'secondary' : 'primary'}
          style={{
            margin: 5,
            position: 'absolute',
            top: 62,
            right: 0,
            zIndex: '100'
          }}
          onClick={this.openLayerMenu}
        >
          <LayersIcon style={{ color: '#fff' }} />
        </Fab>
        <LayerMenu open={this.state.openLayerMenu} onRequestClose={this.closeLayerMenu} />
      </>
    );
  }
}

export default LayerMenuButton;
