import React from 'react';
import { Fab } from '@mui/material';
import { LocationSearching as LocationSearchingIcon, MyLocation as MyLocationIcon } from '@mui/icons-material';
import { GeoLocationManager } from 'services';
import { withMapManager } from 'MapManagerContext';

class LocationButton extends React.Component {
  /**
   * Default constructor.
   */
  constructor(props) {
    super(props);

    /**
     * Flag indicating if location button has triggered an animation or not.
     * @type {Boolean}
     */
    this.animating = false;
    this.watchingPosition = false;
    this.watcherId = null;
    this.shouldRegisterWatcher = false;

    this.state = {
      secondary: false
    };

    // We can safely call this now because the component is not created before the map is ready.
    this.onMapReady(props.MapManager);
  }

  componentDidMount() {
    this.animating = false;
    this.watchingPosition = false;
    this.watcherId = null;
    this.shouldRegisterWatcher = false;

    this.onMapReady();
  }

  componentWillUnmount() {
    this.stopWatching();
    const map = this.props.MapManager.getMap();
    if (map) {
      map.removeEventListener('centerchanged', this.onCenterChanged);
      map.removeEventListener('animationstarted', this.onAnimationStarted);
      map.removeEventListener('animationstopped', this.onAnimationStopped);
    }
  }

  onCenterChanged = (pos) => {
    if (!this.shouldRegisterWatcher) {
      this.checkMapAndGPSPosition();
    }
  };

  onAnimationStarted = () => {
    this.animating = true;
  };

  onAnimationStopped = () => {
    this.animating = false;
    if (this.shouldRegisterWatcher) {
      this.registerWatcher();
      this.shouldRegisterWatcher = false;
    }
  };

  /**
   * Function triggered when the map is ready.
   * Add map event listerners.
   */
  onMapReady() {
    const map = this.props.MapManager.getMap();
    map.addEventListener('centerchanged', this.onCenterChanged);
    map.addEventListener('animationstarted', this.onAnimationStarted);
    map.addEventListener('animationstopped', this.onAnimationStopped);
  }

  // FIXME: subscription is not cancelled correctly
  /**
   * Check Map and GPS Position and change secondary state accordingly.
   */
  checkMapAndGPSPosition() {
    GeoLocationManager.getLocationCb((latitude, longitude) => {
      const equals = (a, b) => {
        const newA = Math.floor(a * 10000);
        const newB = Math.floor(b * 10000);

        return newA === newB;
      };

      const map = this.props.MapManager.getMap();

      const pos = map && map.getCenter();

      if (equals(pos.lat, latitude) && equals(pos.lon, longitude)) {
        // Map and GPS Position are the same
        this.enableSecondary();
      } else {
        // Map and GPS Position are different
        this.stopWatching();
      }
    });
  }

  stopWatching = () => {
    if (this.watcherId) {
      GeoLocationManager.clearWatch(this.watcherId);
      this.watchingPosition = false;
      this.watcherId = null;
    }
    this.disableSecondary();
  };

  handleClick = () => {
    if (this.watchingPosition) {
      this.stopWatching();
    } else {
      this.watchingPosition = true;
      this.enableSecondary();

      if (this.animating) {
        // HACK: 	If an animation is in progress, because of VirtualGeo-Web issue #0013328,
        // 			we either need to wait for it to finish, or we can emulate a click to stop
        // 			the camera motion.

        // We will hence register when the animation properly finishes.
        // It seems to working without this, but this in case the event resolution is
        // delayed for some reason, it's better to avoid "missing" it.
        this.shouldRegisterWatcher = true;

        const canvas = document.querySelector('#map canvas');
        canvas.dispatchEvent(
          new MouseEvent('mousedown', {
            clientX: canvas.getBoundingClientRect().left + 42,
            clientY: canvas.getBoundingClientRect().top + 42,
            button: 0,
            buttons: 0
          })
        );

        canvas.dispatchEvent(
          new MouseEvent('mouseup', {
            clientX: canvas.getBoundingClientRect().left + 42,
            clientY: canvas.getBoundingClientRect().top + 42,
            button: 0,
            buttons: 0
          })
        );
      } else {
        this.registerWatcher();
      }
    }
  };

  registerWatcher = () => {
    if (!this.watcherId) {
      this.watcherId = GeoLocationManager.watchLocation((latitude, longitude, _) => {
        this.props.MapManager.jumpToPoint(latitude, longitude, true, 1000);
      });
    }
  };

  /**
   * Enable secondary state.
   */
  enableSecondary() {
    if (!this.state.secondary) {
      this.setState({ secondary: true });
    }
  }

  /**
   * Disable secondary state.
   */
  disableSecondary() {
    if (this.state.secondary) {
      this.setState({ secondary: false });
    }
  }

  render() {
    return (
      <Fab
        color="primary"
        style={{
          margin: 5,
          position: 'absolute',
          bottom: 2,
          right: 0,
          zIndex: '100'
        }}
        size="medium"
        onClick={this.handleClick}
        id="LocationButton"
      >
        {this.state.secondary ? (
          <MyLocationIcon style={{ color: 'white' }} />
        ) : (
          <LocationSearchingIcon style={{ color: 'white' }} />
        )}
      </Fab>
    );
  }
}

export default withMapManager(LocationButton);
