import React, { Component } from 'react';
/**
 * The application configuration : contains all specfic elements that can be added/modified by a plugin.
 */
 class AppConfig {
  /**
   * The top-level routes of the application
   * Contains objects with the path and the component
   */
  topLevelRoutes = [];

  /**
   * Items for the main drawer.
   * Must contains a React Component
   */
  mainDrawerItems = [];

  /**
   * 'Adders' for the map.
   * Params : title, dialog, geometryType, icon (for speed dial component)
   */
  mapAdders = [];

  /**
   * Components displayed directly on the map.
   * Params : title, component, online.
   */
  mapComponents = [];

  /**
   * 'Managers' of cop elements.
   * A manager is a CopElement which is added in the Main component, so always active, and not linked to a route
   */
  managers = [];

  /**
   * Contains additionnal settings items for a plugin
   */
  settingItems = [];

  /**
   * The different main navigation routes for components bring by a plugin
   * The component is accessible in the navigation bar in the main view.
   */
   mainNavigationRoutes = [];
  

  /**
   * The different initializing functions from plugins
   * These functions are called when the app starts
   */
   initFunctions = [];

  /**
   * The different reset functions from plugins
   * These functions are called when the app stops
   */
   resetFunctions = [];
  };


/**
 * React context to store application configuration
 */
 const AppConfigContext = React.createContext(new AppConfig());


 /**
  * HOC component to add AppConfig as prop for a compoment
  * 
  * @param {*} BaseComponent 
  * @returns 
  */
const withAppConfig = (BaseComponent) => {
  return class extends Component {
    render() {
      return (
        <AppConfigContext.Consumer>{(appConfig) => <BaseComponent appConfig={appConfig} {...this.props} />}</AppConfigContext.Consumer>
      );
    }
  };
};

export { AppConfigContext, withAppConfig, AppConfig };