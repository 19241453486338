import SettingsManager from 'services/SettingsManager';
import React, { createContext, useState } from 'react';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  return (
    <NotificationContext.Provider value={{ notifications, setNotifications }}>{children}</NotificationContext.Provider>
  );
};

/**
 * Helper class to simplify notification management
 */
class NotificationManager {
  /**
   * Default constructor
   */
  constructor() {
    this.notificationEnabled = false;
    this.inBackground = false;
  }

  init() {
    if (window.cordova) {
      this.notification = window.cordova.plugins.notification.local;
      this.registeredFunctions = {};
      this.notificationEnabled = true;
      this.notification.on('click', this.onclick, this);
      document.addEventListener('resume', () => {
        this.inBackground = false;
      });
      document.addEventListener('pause', () => {
        this.inBackground = true;
      });
    } else if ('Notification' in window) {
      Notification.requestPermission().then((result) => {
        this.notificationEnabled = result === 'granted';
      });
    }
  }

  /**
   * Called when the user click on the notification
   */
  onclick = (ev) => {
    const { id } = ev;
    const func = this.registeredFunctions[id];

    if (func) {
      func();
      delete this.registeredFunctions[id];
    }
  };

  /**
   * Clear a notification
   * @param {*} id
   */
  clear(id) {
    if (this.notificationEnabled && window.cordova) {
      this.notification.clear(id);
    }
  }

  /**
   * Notify user of new events
   * @param {*} param0
   */
  notify({ title, body, icon, onClick, tag, sticky = false }) {
    if (this.notificationEnabled) {
      if (window.cordova) {
        if (this.inBackground) {
          let finalId = 0;
          for (const { id } of Object.entries(this.registeredFunctions)) {
            if (finalId === id) {
              ++finalId;
            } else {
              break;
            }
          }

          this.notification.schedule({
            id: tag || finalId,
            title,
            icon,
            smallIcon: 'res://app_icon',
            text: body,
            sticky,
            vibrate: true
          });

          this.registeredFunctions[finalId] = onClick;
        }

        if (SettingsManager.notification && SettingsManager.notification.vibrate) {
          navigator.vibrate(SettingsManager.notification.vibrate);
        }
      } else {
        const options = { body, icon, ...(tag ? { tag: String(tag) } : {}) };

        const n = new Notification(title, options);

        n.onclick = () => {
          window.focus();
          if (onClick) {
            onClick();
          }
          n.close();
        };
      }
    }
  }
}

export default new NotificationManager();
