import React, { Component } from 'react';
import { KeycloakManager, SettingsManager } from 'services';
import { buildUrl, fetchData } from 'utils';

const NetworkManagerContext = React.createContext({
  sessionUrl: '',
  setSession: () => {},
  get: () => {},
  head: () => {},
  getAsset: () => {},
  getAssetType: () => {},
  getEntity: () => {},
  addEntity: () => {},
  updateEntity: () => {},
  removeEntity: () => {},
  retrieveSessionList: () => {},
  sendAsset: () => {},
  getEntities: () => {}
});

// For devtools
NetworkManagerContext.displayName = "NetworkManagerContext";

const { Provider, Consumer } = NetworkManagerContext;

/**
 * The network manager is responsible for the communications with the Crimson server.
 */
class NetworkManagerProvider extends Component {
  constructor() {
    super();

    this.serverRoutes = SettingsManager.getServerRoutes();

    this.state = {
      sessionUrl: this.serverRoutes.session,
      setSession: this.setSession,
      get: this.get,
      head: this.head,
      getAsset: this.getAsset,
      getAssetType: this.getAssetType,
      getEntity: this.getEntity,
      addEntity: this.addEntity,
      updateEntity: this.updateEntity,
      removeEntity: this.removeEntity,
      retrieveSessionList: this.retrieveSessionList,
      sendAsset: this.sendAsset,
      sendBinaryAsset: this.sendBinaryAsset,
      getEntities: this.getEntities,
      sendKeepAliveReq: this.sendKeepAliveReq,
      sendNotAliveReq: this.sendNotAliveReq
    };
  }

  /**
   * Set the current session.
   *
   * @param {String} session.name The identifier of the session.
   */
  setSession = (session) => {
    this.setState({
      sessionUrl: `${this.serverRoutes.session}/${session.name}`
    });
  };

  /**
   * Get request. Appends the keycloak access token.
   *
   * @param  {String}  pUrl     The url to fetch.
   * @param  {Headers} pHeaders The headers of the request (defaults to empty headers).
   * @return {Promise<Response>}          The fetch promise.
   */
  get = (pUrl, pHeaders = {}) => {
    const lUrl = buildUrl(pUrl);
    const lData = {
      method: 'GET',
      headers: {
        ...pHeaders,
        Authorization: `Bearer ${KeycloakManager.getAccessToken()}`
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    return fetch(lUrl, lData);
  };

  /**
   * Head request. Appends the keycloak access token.
   *
   * @param  {String}  pUrl     The url to fetch.
   * @param  {Headers} pHeaders The headers of the request (defaults to empty headers).
   * @return {Promise<Response>}          The fetch promise.
   */
  head = (pUrl, pHeaders = {}) => {
    const lUrl = buildUrl(pUrl);
    const lData = {
      method: 'HEAD',
      headers: {
        ...pHeaders,
        Authorization: `Bearer ${KeycloakManager.getAccessToken()}`
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    return fetch(lUrl, lData);
  };

  /**
   * Post request. Appends the keycloak access token.
   *
   * @param {String} pUrl The url to fetch.
   * @param {Object} body The body of the request.
   * @param {Headers} pHeaders The headers of the request (defaults to empty headers).
   *
   * @return {Promise<Response>}  The fetch promise.
   */
  post = (pUrl, body, pHeaders = {}) => {
    const lUrl = buildUrl(pUrl);
    const lData = {
      method: 'POST',
      headers: {
        ...pHeaders,
        Authorization: `Bearer ${KeycloakManager.getAccessToken()}`
        // 'Content-Type': 'application/x-www-form-urlencoded'
      },
      body
      // mode: 'cors'
    };
    return fetch(lUrl, lData);
  };

  /**
   * Put request. Appends the keycloak access token.
   *
   * @param {string} pUrl The url to fetch.
   * @param {Object} body The body of the request.
   * @param {Headers} headers The headers of the request (defaults to empty headers).
   *
   * @return {Promise<Response>} The fetch promise.
   */
  put = (pUrl, pBody, pHeaders = {}) => {
    const lUrl = buildUrl(pUrl);
    const lData = {
      method: 'PUT',
      headers: {
        ...pHeaders,
        Authorization: `Bearer ${KeycloakManager.getAccessToken()}`
        // 'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: pBody
    };
    return fetch(lUrl, lData);
  };

  /**
   * Delete request. Appends the keycloak access token.
   *
   * @param  {String} pUrl
   * @param  {Headers} headers
   *
   * @return {Promise<Response>}
   */
  delete = (pUrl, headers = {}) => {
    const lUrl = buildUrl(pUrl);

    const lData = {
      method: 'DELETE',
      headers: {
        ...headers,
        Authorization: `Bearer ${KeycloakManager.getAccessToken()}`
      }
    };

    return fetch(lUrl, lData);
  };

  getAsset = async (pPath) => {
    // With Crimson server v6 we need to prefix api root to retrieve assets from annotation.AssetPath
    const serverVersion = SettingsManager?.crimsonServer?.version || 6;
    if (serverVersion >= 6) {
      pPath = SettingsManager.getApiRootv6().slice(0, -1) + pPath;
    }

    try {
      const lResponse = await this.get(pPath);

      if(lResponse.ok) {
        const lAsset = await lResponse.blob();
        return lAsset;
      } else{
        Promise.reject(lResponse);
      }

    } catch (e) {
      console.log(`Could not retrieve asset: ${e}`);
      return null;
    }
  };


  /**
   * Retrieve the last event existing on a session.
   *
   * @return {Promise<*>}            A promise containing the last event.
   */
  retrieveLastEvent = async () => {
    let url = `${this.state.sessionUrl}/${this.serverRoutes.event}/-1`;

    if (SettingsManager.crimsonServer && SettingsManager.crimsonServer.displayPeriod) {
      const dateOffset = 60 * 60 * 1000 * SettingsManager.crimsonServer.displayPeriod;
      const filteredDate = new Date();
      filteredDate.setTime(filteredDate.getTime() - dateOffset);
      url += `?filteredDate=${filteredDate.toISOString()}`;
    }

    const response = await this.get(url, {
      'Cache-Control': 'no-cache'
    });
    if (response.ok) {
      const event = await response.json();
      return event;
    }

    throw new Error(`Invalid response: ${response.status}`);
  };

  /**
   * Retrieve entities given their type or tags
   *
   * @return {Promise<*>} A promise containing the entities data.
   */
  getEntities = async (options) => {
    try {
      let url = `${this.state.sessionUrl}/${this.serverRoutes.entity}`;
      if (SettingsManager.crimsonServer && SettingsManager.crimsonServer.displayPeriod) {
        const dateOffset = 60 * 60 * 1000 * SettingsManager.crimsonServer.displayPeriod;
        const filteredDate = new Date();
        filteredDate.setTime(filteredDate.getTime() - dateOffset);
        url += `?filteredDate=${filteredDate.toISOString()}`;
        if (options && options.diffDate) {
          url += `&mode=Diff&diffDate=${options.diffDate.toISOString()}`;
        }
      }
      const lResponse = await this.get(url, {
        'Cache-Control': 'no-cache'
      });

      if(lResponse.ok) {
        let lEntities = await lResponse.json();
        // On first request, filter entities with groupsVisibility state
        if (options && options.groupsVisibility && !options.diffDate) {
          lEntities = lEntities.filter((entity) => 
              !entity.groupsVisibility ||
              entity.groupsVisibility.length === 0 || 
              entity.groupsVisibility.some(element => {
                return options.groupsVisibility.includes(element)
              })
          )
        }
        return [lEntities, Date.parse(lResponse.headers.get("latestentitydate"))]
      } else {
        Promise.reject(lResponse);
      }

    } catch (e) {
      console.error(`Could not retrieve entities: ${e}`);
      return null;
    }
  };

  /**
   * @param {Object} entity - the entity data
   * @param {Array<string>=} groupsVisibility
   *
   * @returns {Promise<Object>}
   */
  addEntity = async (entity, groupsVisibility = []) => {
    const url = `${this.state.sessionUrl}/${this.serverRoutes.entity}`;

    return await this.post(
      url, JSON.stringify([{
        action:"add",
        "entity": {
          groupsVisibility,
          ...entity
        }
      }]),
      {'Content-Type': 'application/json'}
    );
  };

   /**
   * @param {Object} entity - the entity data
   * @param {Array<string>=} groupsVisibility
   *
   * @returns {Promise<Object>}
   */
   updateEntity = async (entity, priority = 50) => {
    const url = `${this.state.sessionUrl}/${this.serverRoutes.entity}`;

    return await this.post(
      url, JSON.stringify([{
        action:"modify",
        "entity": entity
      }]),
      {'Content-Type': 'application/json', 'Priority': priority }
    );
  };

  /**
   * Retrieve an entity given its path.
   *
   * @param  {string}  path The full path of the entity (`/crimson/session/{sessionId}/entity/{pPath}`).
   *
   * @return {Promise<*>}       A promise containing the entity data.
   */
  getEntity = async (path) => {
    try {
      const url = SettingsManager.getApiRoot() + path;
      const response = await this.get(url, {
        'Cache-Control': 'no-cache'
      });

      if(response.ok) {
        const lEntity = await response.json();
        return lEntity;
      } else {
        Promise.reject(response);
      }

    } catch (e) {
      console.error(`Could not retrieve entity: ${e}`);
      return null;
    }
  };

  removeEntity = async (pPath) => {
    try {
      const lResponse = await this.delete(pPath);
      return lResponse
    } catch (e) {
      console.error(`Could not retrieve entity: ${e}`);
    }
  };

  /**
   * Retrieve the session list on the server.
   *
   * @return {Promise<*>} A promise containing the list of sessions.
   */
  retrieveSessionList = async () => {
    const response = await this.get(this.serverRoutes.session, {});

    if(response.ok) {
      const data = await response.json();
      return data;
    } else {
      Promise.reject(response);
    }
  };

  /**
   * Send an asset to the server.
   *
   * @param  {Object}  pData The data of the asset.
   *
   * @return {Promise} A promise containing the asset commit result.
   */
  sendAsset = (pData) => {
    const lUrl = `${this.state.sessionUrl}/${this.serverRoutes.asset}`;
    const lContent = pData.substring(pData.indexOf(',') + 1, pData.length);
    const lMimetype = pData.substring(pData.indexOf(':') + 1, pData.indexOf(';'));
    const lHeaders = {
      'Content-Encoding': 'base64',
      'Content-Type': 'application/octet-stream',
      Mimetype: lMimetype,
      'Access-Control-Expose-Headers': '*'
    };
    return this.post(lUrl, lContent, lHeaders);
  };

  /**
   * Send a raw asset to the server
   *
   * Used for audio and video assets
   *
   * @param {Blob} blob
   * @param {string=} MIMEType
   */
  sendBinaryAsset = (blob) => {
    // mimick phone UUID
    const dateWithoutHyphens = new Date().toISOString().replace(/[^0-9]/g, '');
    let mediaUUID = ''
    if (blob.type.includes('audio')) {
      mediaUUID = `AUD_${dateWithoutHyphens.substring(0, 8)}_${dateWithoutHyphens.substring(8, 15)}.mp3`
    } else if (blob.type.includes('video')) {
      mediaUUID = `VID_${dateWithoutHyphens.substring(0, 8)}_${dateWithoutHyphens.substring(8, 15)}.mp4`
    }

    const lUrl = `${this.state.sessionUrl}/${this.serverRoutes.asset}`;

    const lHeaders = {
      'Content-Type': 'application/octet-stream',
      Mimetype: blob.type ? (blob.type.includes('audio') ? 'audio/mp3' : blob.type) : 'audio/mp3',
      'Access-Control-Expose-Headers': '*',
      FileName: mediaUUID
    };

    return this.post(lUrl, blob, lHeaders);
  };

  /**
   * Send keep alive request to server
   */
  sendKeepAliveReq = async () => {
    let deviceUuid;
    let contentType;
    let reqBody;

    // If on phone use device uuid, else use browser agent
    if (window.cordova) {
      deviceUuid = window.device.uuid;
    } else {
      deviceUuid = navigator.userAgent;
    }

    /**
     * If V6 server, use JSON body
     * if V5 server, use plain text
     */
    const serverVersion = SettingsManager?.crimsonServer?.version || 6;
    if (serverVersion > 5) {
      contentType = 'application/json';
      reqBody = { macAddr: deviceUuid };
    } else {
      contentType = 'text/plain';
      reqBody = deviceUuid;
    }

    try {
      await fetchData({
        url: buildUrl(`${this.state.sessionUrl}/users`),
        method: 'PUT',
        body: reqBody,
        headers: {
          'Content-Type': contentType,
          Authorization: `Bearer ${KeycloakManager.getAccessToken()}`
        }
      });
    } catch(err) {
      console.error(err);
    }
  };

  /**
   * Communicate to the server that the app is about to go offline
   */
  sendNotAliveReq = async () => {
    let deviceUuid;
    let reqBody;
    const reqHeaders = {
      Authorization: `Bearer ${KeycloakManager.getAccessToken()}`
    };

    // If on phone use device uuid, else use browser agent
    if (window.cordova) {
      deviceUuid = window.device.uuid;
    } else {
      deviceUuid = navigator.userAgent;
    }

    /**
     * If V6 server, send json body and encoding
     * If v5 server, send custom header and no body
     */
    const serverVersion = SettingsManager?.crimsonServer?.version || 6;
    if (serverVersion > 5) {
      reqBody = { macAddr: deviceUuid };

      reqHeaders['Content-Type'] = 'application/json';
    } else {
      reqHeaders.macAddr = deviceUuid;
    }

    try {
      await fetchData({
        url: buildUrl(`${this.state.sessionUrl}/users`),
        method: 'DELETE',
        body: reqBody,
        headers: reqHeaders
      });
    } catch(err) {
      console.error(err);
    }
  };

  getAssetType = async (pPath) => {

    // With Crimson server v6 we need to prefix api root to retrieve assets from annotation.AssetPath
    const serverVersion = SettingsManager?.crimsonServer?.version || 6;
    if (serverVersion >= 6) {
      pPath = SettingsManager.getApiRootv6().slice(0, -1) + pPath;
    }

    try {
      const lResponse = await this.head(pPath);
      const lAssetInfo = await lResponse.blob();

      return lAssetInfo.type;
    } catch (e) {
      console.log(`Could not retrieve asset info: ${e}`);
      return null;
    }
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const withNetworkManager = (BaseComponent) => {
  return class extends Component {
    render() {
      return (
        <Consumer>{(NetworkManager) => <BaseComponent NetworkManager={NetworkManager} {...this.props} />}</Consumer>
      );
    }
  };
};

export { NetworkManagerProvider, withNetworkManager };
