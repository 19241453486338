import React, { useCallback, useContext } from "react";
import { MapManagerContext } from "MapManagerContext";
import { Typography } from "@mui/material";

/**
 * Compass and Zoom Widget
 */
const PositionBarWidget = () => {

  const MapManager = useContext(MapManagerContext);
  const map = MapManager.getMap();

  const [positionLabel, setPositionLabel] = React.useState("");

  const onMouseMove = useCallback((evt) => {
    if (map) {
      const position = map.pickPosition(evt);
      let postionFormatted = "-- | --";
      let alt = "--";
      let unit = "m";
      // position.formatted setted if the position is valid
      if (position?.formatted) {
        // Retrieve it
        postionFormatted = position.formatted;
        // UTF8 problem ? Replace this bad caracter with the correct one for the degres
        postionFormatted = postionFormatted.replace(/\uFFFD/g, '°')
        // Display the altitude if any and round it
        if (position?.alt && Math.round(position.alt) !== 0 ) {
          alt = Math.round(position.alt);
        }
      }
      switch(MapManager.measureUnit)
      {
        case "imperial": unit = "ft"; break;
        case "nautical": unit = "NM"; break;
        default: unit = "m"; break;
      }
      setPositionLabel(`${postionFormatted} | Elev ${alt} ${unit}`);
    }
  }, [map, MapManager.measureUnit]);

  // Add map listeners for the orientation
  React.useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);

    return function cleanup() {
      window?.removeEventListener("mousemove", onMouseMove);
    };
  }, [map, onMouseMove]);

  return (
    <>
      <div 
        style={{
          margin: 0,
          position: 'absolute',
          textAlign: 'center',
          bottom: 0,
          zIndex: 100,
          left: "50%",
          transform: "translate(-50%, 0)",
          background: "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 20%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0) 100%)",
          width: "33%"
        }}> 
          <Typography 
            variant="caption"
            style={{
              color: "#000",
              padding: "0 0 20%"
            }}
          >
            {positionLabel}
          </Typography>
      </div>
    </>
  );
};

export default PositionBarWidget;
