import { _t } from './i18n';
import { ServerSettingsManager } from 'services';

/**
 * Extract the version of an annotation, given its path:
 * (`/crimson/session/{sessionId}/event/{annotationId}/{annotationVersion}`).
 * @param  {string} pAnnotation The annotation path.
 * @return {number}             The annotation version.
 */
export const getAnnotationVersion = (pAnnotation) => {
  const lPath = pAnnotation.path;
  const lVersion = lPath.substr(lPath.lastIndexOf('/') + 1);
  return parseInt(lVersion, 10);
};

/**
 * Return a displayable text for a given annotation type
 */
export const getDisplayTextForAnnotationType = (pType) => {
  switch (pType) {
    case 'Incident':
      return _t('Incident');
    case 'GeoObject':
      return _t('Annotation');
    case 'Sector':
      return _t('Sector');
    case 'TableRow':
      return _t('Log entry');
    default:
      // TODO...
      return _t('Item');
  }
};

/**
 * Convert an array of string representing numbers (`["0.6235", "0.9804", "0.4353"]`)
 * to a color (`rgb(159, 250, 111)`).
 * Note that the eventual alpha component will be ignored.
 * @param  {[type]} pArray The array of string representing the color.
 * @return {[type]}        The color.
 */
export const getAsColor = (pArray) => {
  let lTempArray;
  if (typeof pArray === typeof 'String') {
    // in the Rules.xml the color value is a basic string.
    lTempArray = pArray.split(' ');
  } else {
    lTempArray = pArray;
  }

  const lArray = lTempArray.slice(0, 3).map((pValue) => Math.floor(parseFloat(pValue) * 255));
  const opacity = lTempArray.length === 4 ? parseFloat(lTempArray[3]) : 1.0;
  const lColor = `rgba(${lArray}, ${opacity})`;
  return lColor;
};

export const extractRGBFromColor = (pColor) => {
  const lMatch = /rgba?\( *([0-9]+) *, *([0-9]+) *, *([0-9]+),? *[0-9]*.?[0-9]*\)/.exec(pColor);
  const lResult = {
    red: lMatch ? lMatch[1] : 255,
    green: lMatch ? lMatch[2] : 255,
    blue: lMatch ? lMatch[3] : 255
  };

  return lResult;
};

export const setColorOpacity = (pColor, pOpacity) => {
  const lRGB = extractRGBFromColor(pColor);
  const lColor = `rgba(${lRGB.red}, ${lRGB.green}, ${lRGB.blue}, ${pOpacity})`;
  return lColor;
};

export const getAsColorWithAlpha = (pArray) => {
  const lArray = pArray.slice(0, 4).map((pValue) => Math.floor(parseFloat(pValue) * 255));
  lArray[3] /= 255; // Alpha between 0 and 1
  const lColor = `rgba(${lArray})`;
  return lColor;
};

// /**
//  * Replace special characters with a non special value.
//  * Accentuated characters are replaced by their non accentuated version.
//  * @param  {string} pValue The string with some special characters.
//  * @return {string}        The string transformed.
//  */
// export const dropSpecialChars = (pValue) => {
//   // NOTE(CMA): This is probably not very efficient, but as long as we do not use
//   // 			  it often, it should not be a bottleneck.
//   // TODO(CMA): Add characters as needed.
//   const lResult = pValue.replace(/é|è/, 'e').replace(/á|à/, 'a').replace(/ú|ù/, 'u');

//   return lResult;
// };

// /**
//  * Compare two strings for sorting purposes.
//  * @param  {string} pStr1 The first string to be compared.
//  * @param  {string} pStr2 The second string to be compared.
//  * @return {number}       The result of the comparison:
//  *                        `-1` if `pStr1 < pStr2`, `1` if `pStr1 > pStr2`, `0` otherwise.
//  */
// export const strcmp = (pStr1, pStr2) => {
//   const lResult = pStr1 < pStr2 ? -1 : +(pStr1 > pStr2);
//   return lResult;
// };

export const cleanupFileUrl = (pUrl) => {
  let lUrl = decodeURI(pUrl).replace('file:///', '');

  // Hack to avoid crash with Chrome 58, in case of invalid Url, set it to empty
  if (lUrl.length > 2 && lUrl[1] === ':' && lUrl[2] === '/') {
    lUrl = '';
  }

  // make it absolute
  if (window.cordova) {
    if (lUrl[0] === '/') {
      lUrl = process.env.PUBLIC_URL + lUrl;
    }
  } else {
    if (lUrl[0] === '.') {
      lUrl = lUrl.slice(1);
    } else if (lUrl[0] !== '/') {
      lUrl = `/${lUrl}`;
    }
    lUrl = "../../" + lUrl;
  }

  return lUrl;
};

export const getDate = (pInputDate) => {
  const re = new RegExp('([0-9]*)-([0-9]*)-([0-9]*) ([0-9]*):([0-9]*):([0-9]*).([0-9]*)');
  const data = re.exec(pInputDate.replace('T', ' '));

  const Y = parseInt(data[1]);
  const M = parseInt(data[2]) - 1; // We get a month between 01 and 12
  const d = parseInt(data[3]);
  const h = parseInt(data[4]);
  const m = parseInt(data[5]);
  const s = parseInt(data[6]);
  const ms = parseInt(data[7]) / 1000; // We get a value in microseconds

  const lResult = new Date(Date.UTC(Y, M, d, h, m, s, ms));
  return lResult;
};

/**
 * Compare entities using their CreationDate attribute.
 * @param {*} pEntity1 	The first entity.
 * @param {*} pEntity2 	The second entity.
 * @return {*}				1 -1 or 0
 */
export const compareCreationDates = (pEntity1, pEntity2) => {
  return compareStringDates(pEntity1.CreationDate, pEntity2.CreationDate);
};

/**
 * Compare entities using their UpdateDate attribute.
 * @param {*} pEntity1 	The first entity.
 * @param {*} pEntity2 	The second entity.
 * @return {*}				1 -1 or 0
 */
export const compareUpdateDates = (pEntity1, pEntity2) => {
  return compareStringDates(pEntity1.UpdateDate, pEntity2.UpdateDate);
};

/**
 * Compare string dates.
 * @param {*} pDate1 	The first string date.
 * @param {*} pDate2 	The second string date.
 * @return {*}				1 -1 or 0
 */
export const compareStringDates = (pDate1, pDate2) => {
  const lD1 = new Date(pDate1);
  const lD2 = new Date(pDate2);
  if (lD1 < lD2) {
    return 1;
  } else if (lD1 > lD2) {
    return -1;
  }
  return 0;
};

/**
 * Sorting Chats using their Time stamp.
 * @param {*} pTchat1 	The first chat.
 * @param {*} pTchat2 	The second chat.
 * @return {*}				1 -1 or 0
 */
export const compareTchat = (pTchat1, pTchat2) => {
  const lD1 = new Date(pTchat1.Time);
  const lD2 = new Date(pTchat2.Time);
  if (lD1 <= lD2) {
    return 1;
  } else if (lD1 > lD2) {
    return -1;
  }
  return 0;
};

export const buildIconStyle = (icon, color, size) => {
  const style = {
    width: `${size}px`,
    height: `${size}px`,
    backgroundSize: `${size}px ${size}px`,
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'multiply',
    maskSize: `${size}px ${size}px`,
    WebkitMaskSize: `${size}px ${size}px`,
    maskType: 'alpha',
    WebkitMaskType: 'alpha',
    backgroundImage: `url("${icon}"`,
    maskImage: `url("${icon}")`,
    WebkitMaskImage: `url("${icon}")`,
    backgroundColor: `${color}`
  };

  return style;
};

export const dedupeEntities = (entityUniqueField = 'Uuid') => {
  return (entities) =>
    entities.filter(
      (entity, index, arr) => index === arr.findIndex((item) => item[entityUniqueField] === entity[entityUniqueField])
    );
};

export const buildUrl = (pUrl) => {
  const lUrl = ServerSettingsManager.crimsonUrl + pUrl;
  return lUrl;
};

// return the contrasting color (black or white)
export const getContrast = (hexcolor) => {
    
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor.split('').map(function (hex) {
      return hex + hex;
    }).join('');
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0,2),16);
  const g = parseInt(hexcolor.substr(2,2),16);
  const b = parseInt(hexcolor.substr(4,2),16);

  // Get YIQ ratio
  const totalRGB = ((r * 299) + (g * 587) + (b * 114))
  const yiq = totalRGB === 0 ? 0 :  totalRGB / 1000;

  // Check contrast
  return (yiq >= 128) ? 'black' : 'white';
}

// Function to sort an array alphabetically
export const sortByName = (nameA, nameB) => {
  if (nameA.toLowerCase() > nameB.toLowerCase()){
    return 1;
  }
  else{
    return -1;
  }
}

// Function to sort an array alphabetically with breakage
export const sortByNameBreakage = (nameA, nameB) => {
  if (nameA > nameB){
    return 1;
  }
  else{
    return -1;
  }
}

/**
 * Equivalent of crimson station IdI18nHelper::translate method to parse translation strings from the server
 *
 * @param {string} pInputString - String to translate
 * @param {string} pCurrentLanguage - Two letter prefix for the language we want
 * @returns {string} translatedString
 */
export const i18nTranslate = (pInputString, pCurrentLanguage) => {
  pCurrentLanguage = pCurrentLanguage.toLowerCase();
  const currLanguagePrefix = pCurrentLanguage.substring(0,2) + '=';

  const fallbackLanguage = 'en';

  // Split and remove trailing whitespace of each segment
  const lStrings = pInputString.split(';').map((str) => str.trim());

  let lValue = lStrings[0];

  // Search for current language
  if (lStrings.length > 1) {
    for (const str of lStrings) {
      if (str.length > 0 && str.startsWith(currLanguagePrefix)) {
        return str.replaceAll(currLanguagePrefix, '');
      }
    }

    // Translation not found, try fallback language
    if (pCurrentLanguage !== fallbackLanguage) {
      const fallbackLanguagePrefix = fallbackLanguage + '=';

      for (const str of lStrings) {
        if (str.length > 0 && str.startsWith(fallbackLanguagePrefix)) {
          return str.replaceAll(fallbackLanguagePrefix, '');
        }
      }
    }

    // Not yet found try to get first one
    const lEqualPos = lValue.indexOf('=');

    if (lEqualPos >= 0) {
      lValue = lValue.slice(lEqualPos + 1);
    }
  }

  return lValue;
};
