import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Load directly the resources for french and english into the JS, to avoid some additional loading.
// if other translations are available, they are loaded using the backend (from public/locales/).
import frResources from 'locales/fr-FR/common.json';
import enResources from 'locales/en/common.json';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
  // Allow to load ressources before AND after init
  partialBundledLanguages: true,

  resources: {
    fr: {
      common: frResources
    },
    en: {
      common: enResources
    }
  },

  fallbackLng: 'en',

  returnEmptyString: false,

  react: {
    useSuspense: true
  },

  // Default Namespace
  ns: ['common'],
  defaultNS: 'common',
});

export default i18n;
export const _t = (s, values) => i18n.t(s, values);
