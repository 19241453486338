import React from 'react';
import Button from '@mui/material/Button';
import { useTheme, withStyles } from '@mui/styles';
import Badge from '@mui/material/Badge';

const styles = (theme) => ({
  customBadge: {
    backgroundColor: theme.palette.criticality.veryHigh,
    color: theme.palette.secondary.contrastText
  }
});

function CustomIconButton({ icon, text = '', badgeCount = 0, isToogleButton = false, isToogled = false, ...props }) {
  const theme = useTheme();
  const { classes } = props;
  const iconButtonSides = 60;

  return (
    <Button
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText,
        width: iconButtonSides,
        height: iconButtonSides,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden'
      }}
    >
      <Badge classes={{ badge: classes.customBadge }} badgeContent={badgeCount} color="secondary">
        {icon}
      </Badge>
      {text.length > 0 ? (
        <div style={{ fontSize: '10px', textWrap: 'nowrap', textOverflow: 'ellipsis' }}>
          {text}
        </div>
      ) : null}
    </Button>
  );
}

export default withStyles(styles)(CustomIconButton);
