import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { withTranslation } from 'react-i18next';
import i18n, { _t } from '../../utils/i18n';
import { withMapManager } from '../../MapManagerContext';
import { compareStringDates, getDate } from 'utils';
import { compose } from 'utils';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { ActorManager } from 'services';

const styles = (theme) => ({
  listItem: {
    margin: '3px 0px 3px 0px',
    overflow: 'hidden',
    border: '1px solid',
    borderColor: theme.palette.tertiary.contrastText,
    padding: '6px 6px 6px 6px'
  },
  root: {
    width: '100%',
    maxWidth: 360,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: '0px 0px 0px 0px',
    overflow: 'hidden',
    borderColor: theme.palette.tertiary.contrastText,
    padding: '0px 0px 0px 0px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  date: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.tertiary.contrastText,
    fontSize: 12
  },
  priority: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.tertiary.contrastText,
    fontSize: 12,
    textAlign: 'center',
    padding: '1px 3px 1px 3px'
  },
  body: {
    display: 'flex',
    whiteSpace: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 12,
    padding: '6px 0px 6px 6px'
  },
  icon: {
    width: 32,
    height: 32
  },
  subBody: {
    whiteSpace: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'flex-start',
    fontSize: 12,
    padding: '0px 0px 0px 6px'
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.tertiary.contrastText,
    fontSize: 12
  },
  descriptionArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.tertiary.contrastText,
    fontSize: 12
  },
  divider: {
    color: theme.palette.tertiary.contrastText
  },
  emitter: {
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.tertiary.contrastText,
    fontSize: 12,
    textAlign: 'center',
    padding: '1px 3px 1px 3px'
  }
});

function _NotificationList(props) {
  const theme = useTheme();
  const { notifications, onNotificationItemClicked, classes } = props;
  const sortedNotifications = [...notifications].sort((notification1, notification2) => {
    return compareStringDates(notification1.date, notification2.date);
  });

  function getImportanceText(priority) {
    return priority >= 100 ? _t('Critical') : priority >= 75 ? _t('High') : priority >= 50 ? _t('Normal') : _t('Low');
  }

  function getImportanceColor(priority) {
    return priority >= 100
      ? theme.palette.criticality.veryHigh
      : priority >= 75
      ? theme.palette.criticality.high
      : priority >= 50
      ? theme.palette.criticality.normal
      : theme.palette.criticality.low;
  }

  function getActorColor(actorName) {
    return ActorManager.getCurrentActorName() === actorName ? theme.palette.primary.light : theme.palette.primary.main;
  }

  return (
    <Fragment>
      {sortedNotifications.map((notification) => {
        return (
          <ListItem
            className={classnames(classes.listItem)}
            key={notification.uuid}
            value={notification.uuid}
            onClick={() => {
              onNotificationItemClicked(notification);
            }}
          >
            <div className={classnames(classes.root)}>
              <div className={classnames(classes.header)}>
                <div className={classnames(classes.date)}>
                  {getDate(notification.date).toLocaleString(i18n.language)}
                </div>
                <div
                  style={{ background: getImportanceColor(notification.importance) }}
                  className={classnames(classes.priority)}
                >
                  {getImportanceText(notification.importance)}
                </div>
              </div>

              <div className={classnames(classes.body)}>
                <div>
                  <img className={classnames(classes.icon)} src={notification.icon} alt="" />
                </div>
                <div className={classnames(classes.subBody)}>
                  <div className={classnames(classes.title)}>{notification.title}</div>
                  <Divider className={classnames(classes.divider)} />
                  <div className={classnames(classes.descriptionArea)}>
                    <div className={classnames(classes.description)}>{notification.description}</div>
                    <div
                      style={{ background: getActorColor(notification.emitter) }}
                      className={classnames(classes.emitter)}
                    >
                      {notification.emitter}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ListItem>
        );
      })}
    </Fragment>
  );
}

export const NotificationList = withStyles(styles)(_NotificationList);

function NotificationListItem(props) {
  const { notifications, setNotifications } = props;

  const onNotificationItemClicked = (notification) => {
    const newNotifications = notifications.filter((item) => item.uuid !== notification.uuid);

    setNotifications(newNotifications);

    if (notification.entity !== null) {
      props.MapManager.jumpTo(notification.entity, false);
    }
  };

  return (
    <Fragment>
      <List component="div">
        <NotificationList
          notifications={notifications}
          setNotifications={setNotifications}
          onNotificationItemClicked={onNotificationItemClicked}
        />
      </List>
    </Fragment>
  );
}

NotificationListItem.propTypes = {
  value: PropTypes.string
};

NotificationListItem.defaultProps = {
  value: ''
};

export default compose(withTranslation('common'), withMapManager)(NotificationListItem);
