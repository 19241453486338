import React from 'react';
import { SvgIcon } from '@mui/material';

const MeasureAzimuthIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 100 100">
            <path
                d="M25.23 4.05c-6.89 0-12.55 5.66-12.55 12.55 0 6.096 4.434 11.224 10.228 12.326v6.902h5V28.85c5.385-1.196 9.486-5.903 9.803-11.563.036-.697.006-1.01 0-1.379-.37-6.566-5.828-11.857-12.48-11.857zm0 7c3.107 0 5.551 2.443 5.551 5.55 0 3.106-2.444 5.552-5.55 5.552-3.107 0-5.551-2.446-5.551-5.552 0-3.107 2.444-5.55 5.55-5.55zm3.428 24.565v5a39.02 39.02 0 0124.854 8.946l-5.79 5.85 16.806 1.503-1.103-17.49-6.39 6.33c-7.957-6.85-17.787-10.14-28.377-10.139zm-5.75 5.213v10h5v-10zm56.74.524c-4.293 0-7.671 3.376-7.671 7.67 0 .8.117 1.57.335 2.29l-4.988 3.868 3.063 3.95 4.879-3.78a7.692 7.692 0 004.382 1.347c4.294 0 7.672-3.382 7.672-7.676 0-4.293-3.378-7.67-7.672-7.67zm-56.74 14.476v10h5v-10zm40.465 2.416l-7.902 6.129 3.062 3.951 7.903-6.129zM51.52 67.436l-7.903 6.128 3.063 3.952 7.902-6.13zm-28.612 3.392v10h5v-10zm16.758 5.8l-7.904 6.128 3.064 3.951 7.903-6.127zM27.811 85.82l-.01.008h-4.893V95.95l4.031-3.127 3.936-3.05-2.967-3.827v-.117h-.092z"
                overflow="visible" />
        </SvgIcon>
    );
};

export default MeasureAzimuthIcon;
