import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Radio, Switch, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withTranslation } from 'react-i18next';
import { _t } from 'utils/i18n';
import { compose } from 'utils';
import { withMapManager } from 'MapManagerContext';
import { SettingsManager } from 'services';

const useStyles = makeStyles((theme) => ({
  centeredTitle: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'black'
  },
  controlContainer: {
    width: '100%',
    margin: 0
  },
  dialogPaperScrollPaper: {
    maxHeight: '70vh'
  },
  dialogContentScroll : {
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5F5F5'
    },
    '&::-webkit-scrollbar-thumb': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '10px',
    }
  },
  label: {
    flex: 1
  }
}));

/**
 * Dialog that allows to see and toggle the available layers
 *  @returns {React.FC}
 */
const LayerMenu = ({ onRequestClose, open, MapManager }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [visibleOverlays, setVisibleOverlays] = React.useState([]);
  const [visibleBackgrounds, setVisibleBackgrounds] = React.useState([]);
  const overlayCategories = SettingsManager.map.overlayCategories ? SettingsManager.map.overlayCategories : null;
  const backgroundIds= new Set(SettingsManager.map.backgrounds.map( (layer) => layer.id));

  /**
   * Callback called when a layer is added
   * @param {*} layer
   */
  const onLayerAdded = (layer) => {
    // Hack
    if (layer.id === "GEOLINKS")
      return;
      
    // A new layer is set to true when no visibility is found
    const newLayer = {
      ...layer,
      visible: layer.hasOwnProperty('visible') ? layer.visible : true,
    };

    // Add backgrounds to list if not already found
    if (backgroundIds.has(layer.id)){
      setVisibleBackgrounds((prevVal) => {
        if (!prevVal.find((layer) => layer.id === newLayer.id)) {
          return [...prevVal, newLayer];
        } else {
          return prevVal;
        }
      })
    } else {
      // Add overlays to list if not already found
      setVisibleOverlays((prevVal) => {
        if (!prevVal.find((layer) => layer.id === newLayer.id)) {
          return [...prevVal, newLayer];
        } else {
          return prevVal;
        }
      });
    }

  
    
  };

  React.useEffect(() => {
    const map = MapManager.getMap();

    // Add existing layers
    map.getLayers().forEach((layer) => onLayerAdded(layer));

    map.addEventListener('layeradded', onLayerAdded);

    return function cleanup() {
      map.removeEventListener('layeradded', onLayerAdded);
    };
  }, [MapManager]);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  /**
   * @param {React.SyntheticEvent<HTMLInputElement>} event
   * @param {object} layer
   * @param {number} index
   */
  const handleOverlayToggle = (event, layer) => {
    const newOverlay = { ...layer };
    const index = visibleOverlays.findIndex((element) => element.id === layer.id);
    newOverlay.visible = event.target.checked;

    MapManager.getMap().setLayerVisible(newOverlay);

    const newVisibleOverlays = [...visibleOverlays];
    newVisibleOverlays[index] = newOverlay;
    setVisibleOverlays(newVisibleOverlays);
  };

  const handleBackgroundChange = (event,layer,index) => {
    const newVisibleBackgrounds = [...visibleBackgrounds];
    const newBackground = { ...layer };

    visibleBackgrounds.forEach((newVisibleBackgrounds) => {
        newVisibleBackgrounds.visible = false ;
        MapManager.getMap().setLayerVisible(newVisibleBackgrounds);
      }
    );

    newBackground.visible = event.target.checked;
    MapManager.getMap().setLayerVisible(newBackground);

    newVisibleBackgrounds[index] = newBackground;

    setVisibleBackgrounds(newVisibleBackgrounds);
  }

  const displayOverlays = (overlays) => (
    overlays.map((layer) => (
      <FormControlLabel
        className={classes.controlContainer}
        classes={{
          label: classes.label
        }}
        key={layer.id}
        control={
          <Switch
            color="primary"
            checked={layer.visible}
            onChange={(event) => handleOverlayToggle(event, layer)}
          />
        }
        label={layer.title}
        labelPlacement="start"
      />
    ))
  )

  return (
    <Dialog open={isOpen} onClose={onRequestClose} classes={{ paperScrollPaper: classes.dialogPaperScrollPaper }}>
      <DialogTitle>{_t('Layers')}</DialogTitle>
      <DialogContent className={classes.dialogContentScroll}>
        <DialogContentText className={classes.centeredTitle}>{_t("Backgrounds")}</DialogContentText>
        {visibleBackgrounds && visibleBackgrounds.length ? (
          visibleBackgrounds.map((layer, index) => (
            <FormControlLabel
              className={classes.controlContainer}
              classes={{
                label: classes.label
              }}
              key={layer.id}
              control={
                <Radio
                  checked={layer.visible}
                  color="primary"
                  onChange={(event) => handleBackgroundChange(event,layer,index)}
                />
              }
              label={layer.title}
              labelPlacement="start"
            />
          ))
        ) : (
          <i>{_t('LayersLoadingError')}</i>
        )}
        <DialogContentText className={classes.centeredTitle}>{_t("Overlays")}</DialogContentText>
        {visibleOverlays && visibleOverlays.length ? (
          // If there are overlay categories in the settings file
          // Filter and display the layers in accordion for each category
          overlayCategories ? (
            overlayCategories.map((cat) => (
              <Accordion sx={{marginTop: '10px'}} key={cat}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{cat}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {displayOverlays(visibleOverlays.filter((overlay) => overlay.id.includes(cat)))}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            displayOverlays(visibleOverlays)
            )
        ) : (
          <i>{_t('LayersLoadingError')}</i>
        )}
      </DialogContent>
    </Dialog>
  );
};

LayerMenu.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  MapManager: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired
};

export default React.memo(compose(withTranslation('common'), withMapManager)(LayerMenu));
