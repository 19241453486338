import NotificationListItem from 'components/Notifications/NotificationList';
import { withTranslation } from 'react-i18next';
import { _t } from '../../utils/i18n';
import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { NotificationContext } from 'services/NotificationManager';

function Notifications(props) {
  const { notifications, setNotifications } = useContext(NotificationContext);
  const { notificationVisible, setNotificationVisible } = props;

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setNotificationVisible(open);
  };

  return (
    <Drawer
      anchor="right"
      open={notificationVisible}
      onClose={toggleDrawer(false)}
      variant="persistent"
      sx={{
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', // Updated property
        [`& .MuiDrawer-paper`]: {
          width: 300,
          boxSizing: 'border-box',
          marginTop: (theme) => theme.topBar.height,
          backgroundColor: (theme) => `${theme.palette.tertiary.main}`,
          border: (theme) => `6px solid ${theme.palette.secondary.main}`
        }
      }}
    >
      <Box
        sx={{
          width: '100%',
          flexGrow: 1
        }}
        role="presentation"
      >
        <NotificationListItem notifications={notifications} setNotifications={setNotifications} />
      </Box>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{
            margin: '1em',
            position: 'fixed',
            bottom: 0
          }}
          variant="contained"
          onClick={() => {
            setNotifications([]);
          }}
        >
          {_t('Clean notifications')}
        </Button>
      </div>
    </Drawer>
  );
}

export default withTranslation('common')(Notifications);
