import React from 'react';
import { _t } from 'utils/i18n';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@mui/styles';
import { Card, CardHeader, CardContent, Button, Avatar, Typography } from '@mui/material';
import { withAnnotations } from 'CopContext';
import ViewLayout from './ViewLayout/ViewLayout';
import StyleFactory from 'services/StyleFactory';
import { KeycloakManager, ActorManager } from 'services';

/**
 * Styles for the component
 */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    padding: '1em',
    backgroundColor: theme.palette.tertiary.main
  },

  card: {
    margin: '1em',
  },

  action: {
    marginTop: 0,
    alignSelf: 'auto'
  },

  cardContent: {
    padding: '0px 16px'
  },

  title: {
    flexGrow: 1
  },

  view: {
    height: '100%',
    backgroundColor: theme.palette.tertiary.main
  }
}));

/**
 * Return the text for a mission
 * @param {*} param0
 */
const missionText = (team) => {
  if (!team.Mission || !team.Mission.Category || team.Mission.EndDate) {
    return '';
  } else {
    return _t('MissionRunning', { mission: team.Mission });
  }
};

/**
 * Return the text to display members of the team
 * @param {*} param0
 */
const membersText = (team, allTeammates) => {
  if (!team.Leader && (!team.TeammatesUuids || team.TeammatesUuids.length === 0)) {
    return null;
  } else {
    const leader = ActorManager.getActor(team.Leader);
    const teammates = team.TeammatesUuids?.reduce((prevValue, tmId, index) => {
      const tm = allTeammates[tmId];
      const actor = tm ? ActorManager.getActor(tm.ActorID) : null;
      if (actor) {
        return prevValue ? prevValue + ', ' + actor.username : actor.username;
      } else {
        return prevValue;
      }
    }, '');
    return (
      <>
        {leader && (
          <Typography variant="body2" color="textSecondary" component="p">
            {_t('Leader') + ' : ' + leader.username}
          </Typography>
        )}
        {teammates && (
          <Typography variant="body2" color="textSecondary" component="p">
            {_t('Teammates') + ' : ' + teammates}
          </Typography>
        )}
      </>
    );
  }
};

/**
 * A component to select its team for a user.
 * Automatically add the user to the team
 *
 * @param {*} param0
 */
const TeamSelection = ({
  user,
  team,
  teammates,
  onFinish,
  annotations,
  addAnnotation,
  updateAnnotation,
  removeAnnotation
}) => {
  // Invert the incident owners
  const ownerIncident = {};
  for (const [key, value] of Object.entries(annotations)) {
    if (value.type === 'Incident') {
      if (value.Owners) {
        value.Owners.forEach((owner) => {
          ownerIncident[owner.Uuid] = [key];
        });
      }
    }
  }
  const teams = Object.values(annotations).filter((a) => a.type === 'Team');
  const classes = useStyles();

  // Put my team at first position
  if (team) {
    const teamIndex = teams.findIndex((t) => team.Uuid === t.Uuid);
    if (teamIndex >= 0) {
      teams.splice(teamIndex, 1);
      teams.unshift(team);
    }
  }

  /**
   * Join a team
   * @param {*} newTeam
   * @param {*} teammate
   */
  const joinTeam = (newTeam, teammate) => {
    // if no leader, the user becomes the leader
    if (!newTeam.Leader) {
      newTeam.Leader = user.sub;
      updateAnnotation(newTeam, {
        conflictHandler: (t) => joinTeam(t, teammate)
      });
    } else {
      // Create a "teammate"
      if (!teammate) {
        teammate = {
          ActorID: user.sub,
          groupsVisibility: newTeam.groupsVisibility,
          TeamType: newTeam.TeamType,
          Name: user.preferred_username,
          Uuid: uuidv4(),
          FeatureGeometry: { type: 'PointGeometry', Point: ['nan', 'nan', 'nan'] },
          tags: ['Teammate', 'Maps'],
          type: 'Teammate'
        };
        addAnnotation(teammate);
      }

      // Update the team with new teammate
      if (!newTeam.TeammatesUuids) {
        newTeam.TeammatesUuids = [];
      }
      newTeam.TeammatesUuids.push(teammate.Uuid);
      updateAnnotation(newTeam, {
        conflictHandler: (t) => joinTeam(t, teammate)
      });
    }
  };

  /**
   * Leave a team
   * @param {*} team  The team to leave
   */
  const leaveTeam = (team) => {
    let teammateToRemove;

    // Check if user is the leader
    if (team.Leader === user.sub) {
      team.Leader = null;

      // Check if there is a teammate to be promoted to Leader
      if (teammates && teammates.length > 0) {
        team.Leader = teammates[0].ActorID;
        teammateToRemove = teammates[0];
        const i = team.TeammatesUuids.indexOf(teammateToRemove.Uuid);
        team.TeammatesUuids.splice(i, 1);
      }
    } else {
      // Find teammate and remove it from the team and teammates
      const teammateIndex = teammates.findIndex((t) => t.ActorID === user.sub);
      teammateToRemove = teammates[teammateIndex];
      const i = team.TeammatesUuids.indexOf(teammateToRemove.Uuid);
      team.TeammatesUuids.splice(i, 1);
    }

    updateAnnotation(team, {
      successHandler: () => {
        if (teammateToRemove) {
          removeAnnotation(teammateToRemove);
        }
      },
      conflictHandler: (t) => leaveTeam(t)
    });
  };
  /**
   * Called when the user click on a team.
   * Register the user into the team or unregister from the team.
   *
   * @param {*} team
   */
  const onTeamClicked = (newTeam) => {
    // Check if the user  has clicked on its team
    if (team && team.Uuid === newTeam.Uuid) {
      leaveTeam(team);
    } else {
      joinTeam(newTeam);
      onFinish();
    }
  };

  /**
   * Define the button action to use
   *
   * @param {*} team
   * @param {*} myTeam
   */
  const actionButton = (team, myTeam) => {
    if (KeycloakManager.userRoles.has('PR_CanHaveTeam') && (!myTeam || (team && myTeam.Uuid === team.Uuid))) {
      return (
        <Button size="small" color="primary" onClick={() => onTeamClicked(team)}>
          {myTeam ? _t('Leave') : _t('Join')}
        </Button>
      );
    } else {
      return null;
    }
  };

  /**
   * Build the content for a team.
   *
   * @param {*} team
   * @param {*} className
   */
  const cardContent = (team) => {
    const t1 = membersText(team, annotations);
    const t2 = missionText(team);
    let t3;
    if (ownerIncident[team.Uuid]) {
      t3 = _t('Incident assigned') + ' : ' + annotations[ownerIncident[team.Uuid]].Name;
    }
    if (t1 || t2 || t3) {
      return (
        <CardContent className={classes.cardContent}>
          {t1}
          <Typography variant="body2" color="textSecondary" component="p">
            {t2}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t3}
          </Typography>
        </CardContent>
      );
    } else {
      return null;
    }
  };

  return (
    <ViewLayout title={_t('Teams')} className={classes.view} onBack={onFinish}>
      {teams.length === 0 && (
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="body2" component="p">
              {_t('No team available')}
            </Typography>
          </CardContent>
        </Card>
      )}
      {teams.map((t) => {
        let icon = StyleFactory.getIconData(t);
        if (icon) {
          icon = icon[0];
        }
        return (
          <Card key={t.Uuid} className={classes.card}>
            <CardHeader
              classes={{ action: classes.action }}
              avatar={<Avatar variant="square" src={icon} />}
              title={t.Name}
              subheader={team && t.Uuid === team.Uuid ? _t('My Team') : ''}
              action={actionButton(t, team)}
            ></CardHeader>
            {cardContent(t)}
          </Card>
        );
      })}
    </ViewLayout>
  );
};

export default withAnnotations({ types: ['Team', 'Incident', 'Teammate'] })(TeamSelection);
